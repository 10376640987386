import React, {useEffect, useState} from 'react';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {ValmetMultiSelect, errorNotification} from '@valmet-iop/ui-common';
import {TFunction} from 'i18next';
import {apiGetRequest} from '../../../services/api/apiService';
import {useTranslation} from 'react-i18next';
import {ApiRoleRestrictedScopeOutputDto} from '../../../types/api/Lookup/LookupUserRoleRestrictedScopesForRoleAssignment';

const emptyOptions: Option[] = [];

const useLookupData = (
  organizationId: string | undefined,
  roleId: string | undefined,
  t: TFunction,
  onLoadComplete: (roleId: string) => void,
) => {
  const [data, setData] = useState<{
    isLoaded: boolean;
    scopeOptions: Option[];
  }>({
    isLoaded: false,
    scopeOptions: emptyOptions,
  });

  useEffect(() => {
    if (!!organizationId && !!roleId) {
      const getRoleRestrictedScopes = async (
        organizationId: string,
        roleId: string,
      ) => {
        try {
          const result = await apiGetRequest<ApiRoleRestrictedScopeOutputDto[]>(
            `api/lookup/user/roleAssignment/${roleId}/scopes?targetOrganizationId=${organizationId}`,
          );
          return result;
        } catch {
          errorNotification(t('users:update.errors.lookupUserScopesFailed'));
          return [];
        }
      };

      setData({
        isLoaded: false,
        scopeOptions: [],
      });
      getRoleRestrictedScopes(organizationId, roleId).then(res => {
        setData({
          isLoaded: true,
          scopeOptions:
            res.map(o => ({
              text: o.name,
              value: o.scopeId,
            })) ?? [],
        });
        onLoadComplete(roleId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, roleId, t]);
  return data;
};

const ScopeValmetMultiSelect = (props: {
  index: number;
  multiSelectMenuContainerEl: Element | null;
  roleId: string | undefined;
  userOrganizationId: string | undefined;
  scopes: readonly Option[];
  errorMessageTranslationKey: string | undefined;
  onChange: (selectedOptions: Option[]) => void;
  onLoadComplete: (roleId: string) => void;
}) => {
  const {t} = useTranslation(['translation', 'users']);
  const {
    index: i,
    roleId: selectedRoleId,
    userOrganizationId,
    scopes: selectedScopes,
    onChange: onScopeSelectionChange,
    errorMessageTranslationKey,
    multiSelectMenuContainerEl,
    onLoadComplete,
  } = props;

  const {scopeOptions} = useLookupData(
    userOrganizationId,
    selectedRoleId,
    t,
    onLoadComplete,
  );

  /**
   * MultiSelect requires reference-equal values.
   * Since the selected values prop objects are different from
   * the objects returned by useLookupData, we need to map
   * the selected values to objects from lookup data.
   * This way the objects in "values" are the same
   * objects as in "options" and everything works correctly.
   */
  const referenceEqualValues: Option[] = [];
  for (const referenceEqualValue of selectedScopes.map(ss =>
    scopeOptions.find(so => so.value === ss.value),
  )) {
    if (referenceEqualValue) {
      referenceEqualValues.push(referenceEqualValue);
    }
  }

  return (
    <ValmetMultiSelect
      id={`role-scopes-${i}`}
      errorMessageTranslationKey={errorMessageTranslationKey}
      onChange={onScopeSelectionChange}
      options={scopeOptions}
      values={referenceEqualValues}
      width="full"
      isDisabled={selectedRoleId === undefined}
      menuContainerEl={multiSelectMenuContainerEl}
      disableInputWrapperMargin
    />
  );
};

export default ScopeValmetMultiSelect;
