import {normalize} from 'normalizr';
import {AnyAction, Dispatch} from 'redux';

export default () => (next: Dispatch<AnyAction>) => (action: any) => {
  const normalizePayload = action.meta && action.meta.normalize;

  if (action.type.endsWith('_DONE') && normalizePayload !== undefined) {
    const normalizedData = normalize(
      action.payload.result,
      normalizePayload.schema,
    );

    action.payload.result.ids = normalizedData.result;
    action.payload.result.byId =
      normalizedData.entities[normalizePayload.entityName];
  }

  return next(action);
};
