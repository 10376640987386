import {Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {StyledSpacing, Filter} from '@valmet-iop/ui-common';

const StyleguideFilter = () => {
  const [text, setText] = useState('');

  const onTextChange = (filterText: string) => {
    setText(filterText);
  };

  return (
    <>
      <Typography variant="body1">
        The Filter component is commonly used to filter table data, though it
        can be used for other things as well. For now it only supports a text
        filter and has a clear button to remove the filter.
      </Typography>
      <StyledSpacing />
      <Filter filterText={text} onFilterTextChange={onTextChange} />
    </>
  );
};

export default StyleguideFilter;
