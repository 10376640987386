import {ValmetIcon, StyledSpacing} from '@valmet-iop/ui-common';
import {Typography} from '@material-ui/core';
import React from 'react';

const StyleguideIcons = () => {
  return (
    <>
      <Typography variant="body1">
        Use the ValmetIcon component for Valmet&apos;s icons. The icon prop has
        full auto-complete for all possible icons. Use the size prop to adjust
        size.
      </Typography>
      <StyledSpacing />
      <ValmetIcon icon="delete" />
      <StyledSpacing />
      <ValmetIcon icon="edit" />
      <StyledSpacing />
      <ValmetIcon icon="edit" size="medium" />
      <StyledSpacing />
      <ValmetIcon icon="edit" size="large" />
    </>
  );
};

export default StyleguideIcons;
