import React, {useState, useMemo, useContext, useCallback} from 'react';
import {useTheme} from '@material-ui/core';
import {useSearchDebounce, useErrorNotification} from '../../Hooks/Hooks';
import {
  ApiOrganizationSearchOutputDto,
  ApiOrganizationSortColumn,
  ApiPaginatedOrganizationQueryDto,
} from '../../../types/api/Organizations/SearchOrganizationsByNameApi';
import {ApiGetOrganizationByIdOutputDto} from '../../../types/api/Organizations/GetOrganizationByIdApi';
import {mapSortDirectionToOrderByType} from '../../../types/typeMapping';
import {
  TablePageLayout,
  Column,
  Sort,
  RowAction,
  ConfirmDialog,
  errorNotification,
  successNotification,
  TimestampInfo,
} from '@valmet-iop/ui-common';
import {Row} from '@valmet-iop/ui-common/dist/components/ValmetTable';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import CreateSidebar from '../Organizations/CreateSidebar';
import UpdateSidebar from './UpdateSidebar';
import {AppContext} from '../../Layout/index';
import {
  apiDeleteRequest,
  apiPostRequest,
} from '../../../services/api/apiService';
import {ApiOrganizationUpdateOutputDto} from '../../../types/api/Organizations/UpdateOrganizationApi';
import {useQuery} from 'react-query';
import {
  ApiPagedResponse,
  ApiProblemDetails,
  ApiServiceRole,
} from '../../../types/api';

const PageSizes = [10, 20, 30];

type RowItem = Row & {
  id: string;
  name: string;
  types: string[];
  admins: string[];
  creationInfo: TimestampInfo;
};

const deleteOrganization = (organizationId: string) => {
  return apiDeleteRequest(`api/organizations/${organizationId}`);
};

const Columns: readonly (Column & {
  sortColumn?: ApiOrganizationSortColumn;
})[] = [
  {
    translationKey: 'organizations:columns.name',
    dataKey: 'name',
    isSortable: true,
    sortColumn: 'Name',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'organizations:columns.types',
    dataKey: 'types',
    dataType: 'stringArray',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'organizations:columns.admins',
    dataKey: 'admins',
    dataType: 'stringArray',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'organizations:columns.created',
    dataKey: 'creationInfo',
    dataType: 'timestampInfo',
    isSortable: true,
    sortColumn: 'CreatedAt',
    defaultWidthPixels: 180,
  },
];

const List = () => {
  const {refreshUserInfo, user} = useContext(AppContext);
  const theme = useTheme();
  const {t} = useTranslation(['translation', 'organizations']);

  const initialSort: Sort = {
    columnIndex: 0,
    direction: 'ascending',
  };
  const [searchParams, setSearchParams] = useState<
    ApiPaginatedOrganizationQueryDto
  >({
    orderByType: 'Ascending',
    pageNumber: 1,
    pageSize: 10,
    searchWord: '',
    sortColumnName: 'Name',
  });

  const {data: result, isError, isLoading, refetch: executeSearch} = useQuery(
    ['organizations', searchParams],
    ({queryKey}) =>
      apiPostRequest<
        ApiPaginatedOrganizationQueryDto,
        ApiPagedResponse<ApiOrganizationSearchOutputDto>
      >(
        'api/organizations/search',
        queryKey[1] as ApiPaginatedOrganizationQueryDto,
      ),
  );

  useErrorNotification(isError, 'organizations:errors.searchFailed');

  const [searchQuery, setSearchQuery] = useState('');
  useSearchDebounce(searchQuery, query =>
    setSearchParams({...searchParams, searchWord: query, pageNumber: 1}),
  );

  const [isCreatePanelOpen, setCreatePanelOpen] = useState(false);
  const [isUpdatePanelOpen, setUpdatePanelOpen] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
  const [deleteState, setDeleteState] = useState({
    id: '',
    name: '',
    dialogVisible: false,
  });

  const data: readonly RowItem[] = useMemo(
    () =>
      result?.data.map((o: ApiOrganizationSearchOutputDto) => ({
        id: o.organizationId,
        name: o.name,
        types: o.types.map(a => a),
        admins: o.administrators.map(
          a => `${a.displayName}, ${a.organizationName}`,
        ),
        creationInfo: {
          timestamp: moment(o.createdAt),
          userName: o.createdBy || t('unknownUser'),
        },
      })) ?? [],
    [result, t],
  );

  const actions: RowAction<RowItem>[] = [
    {
      icon: 'edit',
      translationKey: 'organizations:actions.edit',
      onClick: rowId => {
        setSelectedOrganizationId(rowId);
        setUpdatePanelOpen(true);
      },
    },
    {
      icon: 'delete',
      translationKey: 'organizations:actions.delete',
      color: theme.palette.error.main,
      onClick: (_, row) =>
        setDeleteState({
          ...row,
          dialogVisible: true,
        }),
    },
  ];

  const isEditableByUser = (
    rowOrganizationId: string,
    administratedOrganizationIds: string[] | undefined,
    serviceRole: ApiServiceRole | null | undefined,
  ): boolean => {
    if (serviceRole === 'ServiceAdmin') {
      return true;
    }
    if (
      administratedOrganizationIds?.some(id => id === rowOrganizationId) &&
      serviceRole === 'DeliveryProjectLeadEngineer'
    ) {
      return true;
    }
    return false;
  };

  const getActionsForRow = useCallback(
    (row: RowItem, globalActions: readonly RowAction<RowItem>[]) => {
      // All row actions available
      if (
        user?.administratedOrganizations.some(
          o => o.organizationId === row.id,
        ) &&
        user?.serviceRole === 'ServiceAdmin'
      ) {
        return globalActions;
      }
      // Only Edit available
      if (
        isEditableByUser(
          row.id,
          user?.administratedOrganizations.map(o => o.organizationId),
          user?.serviceRole,
        )
      ) {
        return [globalActions[0]];
      }
      // No actions available for the user
      return [];
    },
    [user],
  );

  const onFilterTextChange = (filterText: string) => {
    setSearchQuery(filterText);
  };

  const onPageChange = (page: number) => {
    setSearchParams({
      ...searchParams,
      pageNumber: page + 1,
    });
  };
  const onPageSizeChange = (pageSize: number) => {
    setSearchParams({
      ...searchParams,
      pageSize,
      pageNumber: 1,
    });
  };

  const onSortChange = ({columnIndex, direction}: Sort) => {
    // eslint-disable-next-line security/detect-object-injection
    const sortColumnName = Columns[columnIndex].sortColumn;
    if (sortColumnName === undefined) {
      return;
    }

    setSearchParams({
      ...searchParams,
      orderByType: mapSortDirectionToOrderByType(direction),
      pageNumber: 1,
      sortColumnName,
    });
  };
  const onCreateClick = () => {
    setCreatePanelOpen(true);
  };
  const onCreatePanelClose = () => {
    setCreatePanelOpen(false);
  };
  const onCreate = (model: ApiGetOrganizationByIdOutputDto) => {
    setCreatePanelOpen(false);
    successNotification(
      t('organizations:create.success', {organizationName: model.name}),
    );
    executeSearch();
    refreshUserInfo();
  };
  const onUpdate = (result: ApiOrganizationUpdateOutputDto) => {
    setUpdatePanelOpen(false);
    successNotification(
      t('organizations:update.success', {organizationName: result.name}),
    );
    executeSearch();
    refreshUserInfo();
  };

  const onDeleteConfirmResult = (confirmed: boolean) => {
    const executeDelete = async (
      organizationId: string,
      organizationName: string,
    ) => {
      try {
        await deleteOrganization(organizationId);
        successNotification(
          t('organizations:delete.success', {organizationName}),
        );
        executeSearch();
        refreshUserInfo();
      } catch (e) {
        if ((e as ApiProblemDetails)?.status === 403) {
          errorNotification(t('organizations:delete.errorForbidden'));
        } else {
          errorNotification(t('organizations:delete.errorUnknown'));
        }
      }
    };

    if (confirmed) {
      executeDelete(deleteState.id, deleteState.name);
    }

    setDeleteState({...deleteState, dialogVisible: false});
  };

  return (
    <>
      <TablePageLayout
        titleTranslationKey="organizations:title"
        isCreateButtonHidden={user?.serviceRole !== 'ServiceAdmin'}
        createButtonTextTranslationKey="organizations:buttons.create"
        filter={{
          filterText: searchQuery,
          onFilterTextChange,
        }}
        pagination={{
          page: searchParams.pageNumber - 1,
          pageSize: searchParams.pageSize,
          pageSizes: PageSizes,
          totalResults: result?.resultCount ?? 0,
          onPageChange,
          onPageSizeChange,
        }}
        table={{
          columns: Columns,
          actions,
          data,
          initialSort,
          actionsMenuNoItemsTooltip: t(
            'table.tooltips.rowActionsNoPermissions',
          ),
          onSortChange,
          getActionsForRow,
          isLoading: isLoading,
          noResultsMessageTranslationKey: t('organizations:list.noResults'),
        }}
        onCreateClick={onCreateClick}
      />
      <CreateSidebar
        isOpen={isCreatePanelOpen}
        onClose={onCreatePanelClose}
        onCreate={onCreate}
      />
      <UpdateSidebar
        isOpen={isUpdatePanelOpen}
        organizationId={selectedOrganizationId}
        onClose={() => setUpdatePanelOpen(false)}
        onUpdate={onUpdate}
      />
      <ConfirmDialog
        headerText={t('organizations:delete.header')}
        isOpen={deleteState.dialogVisible}
        onResult={onDeleteConfirmResult}
        width="small"
        bodyText={t('organizations:delete.bodyFormat', {
          organizationName: deleteState.name,
        })}
      />
    </>
  );
};

export default List;
