import {ApiOrderByType} from './api';
import {TableSortDirection} from '@valmet-iop/ui-common';

export const mapSortDirectionToOrderByType = (
  direction: TableSortDirection,
): ApiOrderByType => {
  switch (direction) {
    case 'ascending':
      return 'Ascending';
    case 'descending':
      return 'Descending';
  }
};
