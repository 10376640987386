import React, {useState, useMemo} from 'react';
import {useTheme} from '@material-ui/core';
import {useErrorNotification, useSearchDebounce} from '../../Hooks/Hooks';
import {
  ApiPagedResponse,
  ApiPaginatedPermissionGroupQueryDto,
  ApiPermissionGroupOutputDto,
  ApiPermissionGroupSearchResultDto,
  ApiPermissionGroupSortColumn,
} from '../../../types/api';
import {mapSortDirectionToOrderByType} from '../../../types/typeMapping';
import {
  TablePageLayout,
  Column,
  Sort,
  RowAction,
  ConfirmDialog,
  errorNotification,
  successNotification,
  TimestampInfo,
} from '@valmet-iop/ui-common';
import {Row} from '@valmet-iop/ui-common/dist/components/ValmetTable';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import CreateSidebar from '../PermissionGroups/CreateSidebar';
import UpdateSidebar from '../PermissionGroups/UpdateSidebar';
import {
  apiDeleteRequest,
  apiPostRequest,
} from '../../../services/api/apiService';
import {useQuery} from 'react-query';

const PageSizes = [10, 20, 30];

const Columns: readonly (Column & {
  sortColumn?: ApiPermissionGroupSortColumn;
})[] = [
  {
    translationKey: 'permissionGroups:columns.groupName',
    dataKey: 'groupName',
    isSortable: true,
    sortColumn: 'GroupName',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'permissionGroups:columns.description',
    dataKey: 'description',
    isSortable: true,
    sortColumn: 'Description',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'permissionGroups:columns.rolesWithGroup',
    dataKey: 'rolesWithGroup',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'permissionGroups:columns.permissions',
    dataKey: 'permissions',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'permissionGroups:columns.created',
    dataKey: 'creationInfo',
    dataType: 'timestampInfo',
    isSortable: true,
    sortColumn: 'CreatedAt',
    defaultWidthPixels: 180,
  },
];

type RowItem = Row & {
  id: string;
  groupName: string;
  description: string;
  rolesWithGroup: string;
  permissions: string;
  creationInfo: TimestampInfo;
};

const deletePermissionGroup = (permissionGroupId: string) => {
  return apiDeleteRequest(`api/permissionGroups/${permissionGroupId}`);
};

const List = () => {
  const theme = useTheme();
  const {t} = useTranslation(['translation', 'permissionGroups']);

  const initialSort: Sort = {
    columnIndex: 0,
    direction: 'ascending',
  };
  const [searchParams, setSearchParams] = useState<
    ApiPaginatedPermissionGroupQueryDto
  >({
    orderByType: 'Ascending',
    pageNumber: 1,
    pageSize: 10,
    searchWord: '',
    sortColumnName: 'GroupName',
  });

  const {data: result, isError, isLoading, refetch: executeSearch} = useQuery(
    ['permissionGroups', searchParams],
    ({queryKey}) =>
      apiPostRequest<
        ApiPaginatedPermissionGroupQueryDto,
        ApiPagedResponse<ApiPermissionGroupSearchResultDto>
      >(
        'api/permissiongroups/search',
        queryKey[1] as ApiPaginatedPermissionGroupQueryDto,
      ),
  );

  useErrorNotification(isError, 'permissionGroups:errors.searchFailed');

  const [searchQuery, setSearchQuery] = useState('');
  useSearchDebounce(searchQuery, query =>
    setSearchParams({...searchParams, searchWord: query, pageNumber: 1}),
  );

  const [isCreatePanelOpen, setCreatePanelOpen] = useState(false);
  const [isUpdatePanelOpen, setUpdatePanelOpen] = useState(false);
  const [selectedPermissionGroupId, setSelectedPermissionGroupId] = useState(
    '',
  );
  const [deleteState, setDeleteState] = useState({
    id: '',
    groupName: '',
    dialogVisible: false,
  });

  const data: readonly RowItem[] = useMemo(
    () =>
      result?.data.map((o: ApiPermissionGroupSearchResultDto) => ({
        id: o.permissionGroupId,
        groupName: o.groupName,
        description: o.description,
        rolesWithGroup: o.rolesWithGroup,
        permissions: o.permissions,
        creationInfo: {
          timestamp: moment(o.createdAt),
          userName: o.createdBy || t('unknownUser'),
        },
      })) ?? [],
    [result, t],
  );

  const actions: RowAction<RowItem>[] = [
    {
      icon: 'edit',
      translationKey: 'permissionGroups:actions.edit',
      onClick: rowId => {
        setSelectedPermissionGroupId(rowId);
        setUpdatePanelOpen(true);
      },
    },
    {
      icon: 'delete',
      translationKey: 'permissionGroups:actions.delete',
      color: theme.palette.error.main,
      onClick: (_, row) => setDeleteState({...row, dialogVisible: true}),
    },
  ];

  const onFilterTextChange = (filterText: string) => {
    setSearchQuery(filterText);
  };

  const onPageChange = (page: number) => {
    setSearchParams({
      ...searchParams,
      pageNumber: page + 1,
    });
  };
  const onPageSizeChange = (pageSize: number) => {
    setSearchParams({
      ...searchParams,
      pageSize,
      pageNumber: 1,
    });
  };

  const onSortChange = ({columnIndex, direction}: Sort) => {
    // eslint-disable-next-line security/detect-object-injection
    const sortColumnName = Columns[columnIndex].sortColumn;
    if (sortColumnName === undefined) {
      return;
    }

    setSearchParams({
      ...searchParams,
      orderByType: mapSortDirectionToOrderByType(direction),
      pageNumber: 1,
      sortColumnName,
    });
  };

  const onDeleteConfirmResult = (confirmed: boolean) => {
    const executeDelete = async (
      permissionGroupId: string,
      permissionGroupName: string,
    ) => {
      try {
        await deletePermissionGroup(permissionGroupId);
        successNotification(
          t('permissionGroups:delete.success', {
            groupName: permissionGroupName,
          }),
        );
        executeSearch();
      } catch {
        errorNotification(
          t('permissionGroups:delete.error', {groupName: permissionGroupName}),
        );
      }
    };

    if (confirmed) {
      executeDelete(deleteState.id, deleteState.groupName);
    }

    setDeleteState({...deleteState, dialogVisible: false});
  };

  return (
    <>
      <TablePageLayout
        titleTranslationKey="permissionGroups:title"
        createButtonTextTranslationKey="permissionGroups:buttons.create"
        filter={{
          filterText: searchQuery,
          onFilterTextChange,
        }}
        pagination={{
          page: searchParams.pageNumber - 1,
          pageSize: searchParams.pageSize,
          pageSizes: PageSizes,
          totalResults: result?.resultCount ?? 0,
          onPageChange,
          onPageSizeChange,
        }}
        table={{
          columns: Columns,
          actions,
          data,
          initialSort,
          actionsMenuNoItemsTooltip: t(
            'table.tooltips.rowActionsNoPermissions',
          ),
          onSortChange,
          isLoading: isLoading,
          noResultsMessageTranslationKey: t('permissionGroups:list.noResults'),
        }}
        onCreateClick={() => setCreatePanelOpen(true)}
      />
      <CreateSidebar
        isOpen={isCreatePanelOpen}
        onClose={() => setCreatePanelOpen(false)}
        onCreate={(model: ApiPermissionGroupOutputDto) => {
          setCreatePanelOpen(false);
          successNotification(
            t('permissionGroups:create.success', {groupName: model.groupName}),
          );
          executeSearch();
        }}
      />
      <UpdateSidebar
        isOpen={isUpdatePanelOpen}
        permissionGroupId={selectedPermissionGroupId}
        onClose={() => setUpdatePanelOpen(false)}
        onUpdate={(result: ApiPermissionGroupOutputDto) => {
          setUpdatePanelOpen(false);
          successNotification(
            t('permissionGroups:update.success', {groupName: result.groupName}),
          );
          executeSearch();
        }}
      />
      <ConfirmDialog
        headerText={t('permissionGroups:delete.header')}
        isOpen={deleteState.dialogVisible}
        onResult={onDeleteConfirmResult}
        width="small"
        bodyText={t('permissionGroups:delete.bodyFormat', {
          groupName: deleteState.groupName,
        })}
      />
    </>
  );
};

export default List;
