import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getUserIsAuthenticated} from '../../redux/reducers';
import {shallowEqual, useSelector} from 'react-redux';
import {IState} from '../../types/types';

/*
 * Used inside <Router>
 */
const PageNotFound: React.FC = () => {
  const {t} = useTranslation();

  const isAuthenticated = useSelector<IState, boolean>(
    getUserIsAuthenticated,
    shallowEqual,
  );

  if (!isAuthenticated) {
    window.sessionStorage.setItem('local-url', window.location.pathname);
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            {t('pageNotFound.header')}
          </Typography>
          <Typography gutterBottom>{t('pageNotFound.body')}</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default PageNotFound;
