import React, {useState, useMemo, useCallback, useContext} from 'react';
import {useErrorNotification, useSearchDebounce} from '../../Hooks/Hooks';
import {
  ApiPaginatedScopeQueryDto,
  ApiScopeSearchResultDto,
  ApiScopeSortColumn,
} from '../../../types/api/Scopes/SearchScopesApi';
import {ApiUpdateScopeOrganizationsOutputDto} from '../../../types/api/Scopes/UpdateScopeApi';
import {mapSortDirectionToOrderByType} from '../../../types/typeMapping';
import {
  TablePageLayout,
  Column,
  Sort,
  RowAction,
  TimestampInfo,
  successNotification,
} from '@valmet-iop/ui-common';
import {Row} from '@valmet-iop/ui-common/dist/components/ValmetTable';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {concat} from 'ramda';
import {AppContext} from '../../Layout';
import UpdateSidebar from './UpdateSidebar';
import {useQuery} from 'react-query';
import {ApiPagedResponse} from '../../../types/api';
import {apiPostRequest} from '../../../services/api/apiService';

const PageSizes = [10, 20, 30];

type RowItem = Row & {
  id: string;
  ownerOrganizationId: string;
  scope: string;
  owner: string;
  organizationsWithAccess: string[];
  creationInfo: TimestampInfo;
};

const Columns: readonly (Column & {sortColumn?: ApiScopeSortColumn})[] = [
  {
    translationKey: 'scopes:columns.scope',
    dataKey: 'scope',
    isSortable: true,
    sortColumn: 'HierarchicalName',
    defaultWidthPixels: 450,
  },
  {
    translationKey: 'scopes:columns.owner',
    dataKey: 'owner',
    isSortable: true,
    sortColumn: 'Owner',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'scopes:columns.organizationsWithAccess',
    dataKey: 'organizationsWithAccess',
    dataType: 'stringArray',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'scopes:columns.created',
    dataKey: 'creationInfo',
    dataType: 'timestampInfo',
    isSortable: true,
    sortColumn: 'CreatedAt',
    defaultWidthPixels: 180,
  },
];

const List = () => {
  const {t} = useTranslation(['translation', 'scopes']);
  const appContext = useContext(AppContext);
  const {user} = appContext;

  const initialSort: Sort = {
    columnIndex: 0,
    direction: 'ascending',
  };
  const [searchParams, setSearchParams] = useState<ApiPaginatedScopeQueryDto>({
    orderByType: 'Ascending',
    pageNumber: 1,
    pageSize: 10,
    searchWord: '',
    sortColumnName: 'HierarchicalName',
  });

  const {data: result, isError, isLoading, refetch: executeSearch} = useQuery(
    ['scopes', searchParams],
    ({queryKey}) =>
      apiPostRequest<
        ApiPaginatedScopeQueryDto,
        ApiPagedResponse<ApiScopeSearchResultDto>
      >('api/scopes/search', queryKey[1] as ApiPaginatedScopeQueryDto),
  );

  useErrorNotification(isError, 'scopes:errors.searchFailed');

  const [searchQuery, setSearchQuery] = useState('');
  useSearchDebounce(searchQuery, query =>
    setSearchParams({...searchParams, searchWord: query, pageNumber: 1}),
  );

  const createScopeTree = (scope: string, parentScopes: string[]): string => {
    const separator = ' / ';
    if (parentScopes.length > 0) {
      const parents = parentScopes.join(separator);
      return concat(parents, `${separator}${scope}`);
    }
    return scope;
  };

  const [isUpdatePanelOpen, setUpdatePanelOpen] = useState(false);
  const [selectedScopeId, setSelectedScopeId] = useState('');

  const data: readonly RowItem[] = useMemo(
    () =>
      result?.data.map((o: ApiScopeSearchResultDto) => ({
        id: o.scopeId,
        ownerOrganizationId: o.ownerOrganization.organizationId,
        scope: createScopeTree(o.name, o.parentScopeNames),
        owner: o.ownerOrganization.organizationName,
        organizationsWithAccess: o.organizationsWithAccess.map(
          org => org.organizationName,
        ),
        creationInfo: {
          timestamp: moment(o.createdAt),
          userName: o.createdBy || t('unknownUser'),
        },
      })) ?? [],
    [result, t],
  );

  const actions: RowAction<RowItem>[] = [
    {
      icon: 'edit',
      translationKey: 'scopes:actions.edit',
      onClick: rowId => {
        setSelectedScopeId(rowId);
        setUpdatePanelOpen(true);
      },
    },
  ];

  const getActionsForRow = useCallback(
    (row: RowItem, globalActions: readonly RowAction<RowItem>[]) => {
      if (row === undefined || user === null) {
        return [];
      }

      if (
        user.organizationRole === 'OrganizationAdmin' &&
        row.ownerOrganizationId === user.organizationId
      ) {
        return globalActions;
      }

      if (
        user.serviceRole === 'ServiceAdmin' &&
        user.administratedOrganizations.some(
          ao => ao.organizationId === row.ownerOrganizationId,
        )
      ) {
        return globalActions;
      }

      if (
        user.serviceRole === 'DeliveryProjectLeadEngineer' &&
        user.administratedOrganizations.some(
          ao => ao.organizationId === row.ownerOrganizationId,
        )
      ) {
        return globalActions;
      }

      return [];
    },
    [user],
  );

  const onFilterTextChange = (filterText: string) => {
    setSearchQuery(filterText);
  };

  const onPageChange = (page: number) => {
    setSearchParams({
      ...searchParams,
      pageNumber: page + 1,
    });
  };
  const onPageSizeChange = (pageSize: number) => {
    setSearchParams({
      ...searchParams,
      pageSize,
      pageNumber: 1,
    });
  };

  const onSortChange = ({columnIndex, direction}: Sort) => {
    // eslint-disable-next-line security/detect-object-injection
    const sortColumnName = Columns[columnIndex].sortColumn;
    if (sortColumnName === undefined) {
      return;
    }

    setSearchParams({
      ...searchParams,
      orderByType: mapSortDirectionToOrderByType(direction),
      pageNumber: 1,
      sortColumnName,
    });
  };

  return (
    <>
      <TablePageLayout
        titleTranslationKey="scopes:title"
        isCreateButtonHidden
        createButtonTextTranslationKey="scopes:buttons.create"
        loadingErrorTranslationKey={
          isError ? 'scopes:errors.searchFailed' : undefined
        }
        filter={{
          filterText: searchQuery,
          onFilterTextChange,
        }}
        pagination={{
          page: searchParams.pageNumber - 1,
          pageSize: searchParams.pageSize,
          pageSizes: PageSizes,
          totalResults: result?.resultCount ?? 0,
          onPageChange,
          onPageSizeChange,
        }}
        table={{
          columns: Columns,
          actions,
          data,
          initialSort,
          actionsMenuNoItemsTooltip: t(
            'table.tooltips.rowActionsNoPermissions',
          ),
          onSortChange,
          getActionsForRow,
          isLoading: isLoading,
          noResultsMessageTranslationKey: t('scopes:list.noResults'),
        }}
        onCreateClick={() => {}}
      />
      <UpdateSidebar
        isOpen={isUpdatePanelOpen}
        scopeId={selectedScopeId}
        user={user}
        onClose={() => setUpdatePanelOpen(false)}
        onUpdate={(model: ApiUpdateScopeOrganizationsOutputDto) => {
          setUpdatePanelOpen(false);
          successNotification(
            t('scopes:update.success', {scopeName: model.name}),
          );
          executeSearch();
        }}
      />
    </>
  );
};
export default List;
