import React, {useState, useEffect, useContext} from 'react';
import {ApiProblemDetails, ApiRoleOutputDto} from '../../../types/api';
import {ApiServiceAdminOutputDto} from '../../../types/api/Lookup/LookupAllServiceAdminsApi';
import {ApiGetAllOrganizationsOutputDto} from '../../../types/api/Organizations/GetAllOrganizationsApi';
import {ApiOrganizationInputDto} from '../../../types/api/Organizations/CreateOrganizationApi';
import {
  ContextPanel,
  ContextPanelContentSection,
  FormikValmetTextInput,
  FormCancelConfirmDialog,
  FormikValmetComplexMultiSelect,
  errorNotification,
  FormikValmetMultiSelect,
  LoadingScreen,
} from '@valmet-iop/ui-common';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {apiGetRequest, apiPostRequest} from '../../../services/api/apiService';
import * as Yup from 'yup';
import {AppContext} from '../../Layout';
import {ApiGetOrganizationByIdOutputDto} from '../../../types/api/Organizations/GetOrganizationByIdApi';
import {ApiOrganizationTypeOutputDto} from '../../../types/api/Lookup/LookupAllOrganizationTypesApi';
import AllowedInviteEmailDomainEditor from './AllowedInviteEmailDomainEditor';

const createOrganization = async (model: ApiOrganizationInputDto) => {
  const result = await apiPostRequest<
    ApiOrganizationInputDto,
    ApiGetOrganizationByIdOutputDto
  >('api/organizations', model);
  return result;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('organizations:create.errors.nameRequired')
    .max(128, 'organizations:create.errors.nameMaxLength'),
  allowedInviteEmailDomains: Yup.array()
    .of(
      Yup.object().shape({
        domain: Yup.string()
          .required('organizations:allowedInviteEmailDomains.errorRequired')
          .matches(
            /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
            'organizations:allowedInviteEmailDomains.errorInvalidDomain',
          ),
      }),
    )
    .test(
      'no-duplicates',
      'organizations:allowedInviteEmailDomains.errorNoDuplicates',
      values => {
        // User should not be able to add multiple rows with same values
        if (!values) {
          return true;
        }

        const seenDomains = new Set<string>();
        for (const value of values) {
          if (!value || !value.domain) {
            continue;
          }

          const domain = value.domain.trim().toLowerCase();

          if (seenDomains.has(domain)) {
            return false;
          }

          seenDomains.add(domain);
        }

        return true;
      },
    ),
});

const emptyTypeOptions: {
  text: string;
  value: string;
}[] = [];
const emptyRoleOptions: {
  text: string;
  value: string;
}[] = [];
const emptyAdministratorOptions: {
  text: string;
  value: string;
}[] = [];
const emptyPartnerOrganizationsOptions: {
  text: string;
  value: string;
}[] = [];

const DefaultValues: {
  name: string;
  types: {text: string; value: string}[];
  roles: {text: string; value: string}[];
  administrators: {text: string; value: string}[];
  partnerOrganizations: {text: string; value: string}[];
  allowedInviteEmailDomains: {
    domain: string;
    immutableRowId: string;
  }[];
} = {
  name: '',
  types: emptyTypeOptions,
  roles: emptyRoleOptions,
  administrators: emptyAdministratorOptions,
  partnerOrganizations: emptyPartnerOrganizationsOptions,
  allowedInviteEmailDomains: [],
};
const CreateSidebar = (props: {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (model: ApiGetOrganizationByIdOutputDto) => void;
}) => {
  const {t} = useTranslation(['translation', 'organizations']);
  const appContext = useContext(AppContext);
  const [isCancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
    false,
  );
  const isOpen = props.isOpen;
  const [typeOptions, setTypeOptions] = useState(emptyTypeOptions);

  const [roleOptions, setRoleOptions] = useState(emptyRoleOptions);
  const [administratorOptions, setAdministratorOptions] = useState(
    emptyAdministratorOptions,
  );
  const [
    partnerOrganizationsOptions,
    setPartnerOrganizationsOptions,
  ] = useState(emptyPartnerOrganizationsOptions);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const getTypes = async () => {
        try {
          const result = await apiGetRequest<ApiOrganizationTypeOutputDto[]>(
            'api/lookup/organization/types',
          );
          setTypeOptions(
            result
              .filter(x => x.typeId !== appContext.adminOrganizationTypeId)
              .map(dto => ({
                text: dto.name,
                value: dto.typeId,
              })),
          );
        } catch {
          errorNotification(t('organizations:create.errors.lookupTypesFailed'));
        }
      };

      const getRoles = async () => {
        try {
          const result = await apiGetRequest<ApiRoleOutputDto[]>('api/roles');
          setRoleOptions(
            result.map(dto => ({
              text: dto.name,
              value: dto.roleId,
            })),
          );
        } catch {
          errorNotification(t('organizations:create.errors.lookupRolesFailed'));
        }
      };

      const getAdministrators = async () => {
        try {
          const result = await apiGetRequest<ApiServiceAdminOutputDto[]>(
            'api/lookup/administrators',
          );
          setAdministratorOptions(
            result.map(dto => ({
              text: dto.displayName,
              value: dto.id,
            })),
          );
        } catch {
          errorNotification(
            t('organizations:create.errors.lookupAdminsFailed'),
          );
        }
      };

      const getPartnerOrganizations = async () => {
        try {
          const result = await apiGetRequest<ApiGetAllOrganizationsOutputDto[]>(
            'api/organizations',
          );
          setPartnerOrganizationsOptions(
            result.map(dto => ({
              text: dto.name,
              value: dto.organizationId,
            })),
          );
        } catch {
          errorNotification(
            t('organizations:create.errors.lookupPartnerOrganizationsFailed'),
          );
        }
      };

      setIsLoading(true);

      if (appContext.user !== null) {
        const user = appContext.user;
        DefaultValues.administrators = [
          {
            text: user.displayName,
            value: user.userId,
          },
        ];
        Promise.all([
          getTypes(),
          getRoles(),
          getPartnerOrganizations(),
          getAdministrators(),
        ]).finally(() => setIsLoading(false));
      } else {
        Promise.all([
          getTypes(),
          getRoles(),
          getPartnerOrganizations(),
        ]).finally(() => setIsLoading(false));
      }
    }
  }, [isOpen, appContext.user, t, appContext.adminOrganizationTypeId]);

  return (
    <Formik
      initialValues={DefaultValues}
      onSubmit={async (values, {setSubmitting, setFieldError, resetForm}) => {
        if (values.types.length === 0) {
          setFieldError('types', 'organizations:create.errors.typesRequired');
          return;
        }

        setSubmitting(true);
        try {
          const result = await createOrganization({
            name: values.name,
            types: values.types.map(g => g.value),
            roles: values.roles.map(g => g.value),
            admins: values.administrators.map(g => g.value),
            partnerOrganizations: values.partnerOrganizations.map(g => g.value),
            allowedInviteEmailDomains: values.allowedInviteEmailDomains.map(
              d => d.domain,
            ),
          });
          props.onCreate(result);
          resetForm();
        } catch (e) {
          errorNotification(t('organizations:create.errors.createFailed'));
          if ((e as ApiProblemDetails)?.status === 409) {
            setFieldError('name', 'organizations:create.errors.conflict');
          }
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({isSubmitting, values, errors, dirty, handleSubmit, resetForm}) => {
        const onCloseClick = () => {
          if (dirty && !isSubmitting) {
            setCancelConfirmDialogOpen(true);
            return;
          }

          resetForm();
          props.onClose();
        };
        const onCancelConfirmDialogResult = (confirmed: boolean) => {
          setCancelConfirmDialogOpen(false);
          if (confirmed) {
            resetForm();
            props.onClose();
          }
        };

        return (
          <>
            <ContextPanel
              open={props.isOpen}
              size="small"
              titleTranslationKey="organizations:create.title"
              bottomActions={[
                {
                  translationKey: 'organizations:create.buttons.save',
                  style: 'primary',
                  isDisabled:
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    values === DefaultValues,
                  onClick: handleSubmit,
                },
                {
                  translationKey: 'organizations:create.buttons.cancel',
                  style: 'ghost',
                  isDisabled: isSubmitting,
                  onClick: onCloseClick,
                },
              ]}
              onCloseClick={onCloseClick}
            >
              {isSubmitting || isLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  <ContextPanelContentSection headerTranslationKey="organizations:create.general.header">
                    <FormikValmetTextInput
                      formProperty="name"
                      id="name"
                      labelTranslationKey="organizations:create.general.name"
                      isRequired
                    />
                    <FormikValmetMultiSelect
                      formProperty="types"
                      id="types"
                      labelTranslationKey="organizations:create.general.types"
                      options={typeOptions}
                      isRequired
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="organizations:create.roles.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="roles"
                      selectPlaceholderTranslationKey="organizations:create.roles.addNewPlaceholder"
                      options={roleOptions}
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="organizations:create.administrators.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="administrators"
                      selectPlaceholderTranslationKey="organizations:create.administrators.addNewPlaceholder"
                      options={administratorOptions}
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="organizations:create.partnerOrganizations.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="partnerOrganizations"
                      selectPlaceholderTranslationKey="organizations:create.partnerOrganizations.addNewPlaceholder"
                      options={partnerOrganizationsOptions}
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="organizations:allowedInviteEmailDomains.header">
                    <AllowedInviteEmailDomainEditor formProperty="allowedInviteEmailDomains" />
                  </ContextPanelContentSection>
                </>
              )}
            </ContextPanel>
            <FormCancelConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              onResult={onCancelConfirmDialogResult}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default CreateSidebar;
