import {Typography} from '@material-ui/core';
import React from 'react';

const StyleguideTypography = () => {
  return (
    <>
      <Typography variant="h1">Heading 1</Typography>
      <Typography variant="h2">Heading 2</Typography>
      <Typography variant="h3">Heading 3</Typography>
      <Typography variant="body1">Body text 1</Typography>
      <Typography variant="body2">Body text 2</Typography>
      <Typography variant="caption">Help text</Typography>
    </>
  );
};

export default StyleguideTypography;
