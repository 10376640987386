import React from 'react';
import {
  FieldArray,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from 'formik';
import {useTranslation} from 'react-i18next';
import {ValmetSelect, ValmetIcon, TextButton} from '@valmet-iop/ui-common';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {makeStyles, Theme} from '@material-ui/core';
import RoleValmetMultiSelect from '../Scopes/RoleValmetMultiSelect';
import {FormData, OrganizationWithRole} from './FormDataTypes';

const useScopeOrganizationEditorStyles = makeStyles<Theme, {}>(() => ({
  root: {},
  rows: {},
  addButton: {},
}));

const ScopeOrganizationEditor = (props: {
  scopeId: string;
  organizationOptions: Option[];
  multiSelectMenuContainerEl: Element | null;
  loadAllComplete: () => void;
}) => {
  const classes = useScopeOrganizationEditorStyles({});
  const {values, errors, touched, setFieldTouched} = useFormikContext<
    FormData
  >();
  const {t} = useTranslation(['translation', 'scopes']);
  const organizations = values.organizationsWithAccess;
  const organizationErrors = errors.organizationsWithAccess ?? [];
  const organizationTouched = touched.organizationsWithAccess ?? [];
  const unselectedOrganizationOptions = props.organizationOptions.filter(
    option => !organizations.some(o => o.organization === option),
  );

  const getOrganizationOptions = (currentData: OrganizationWithRole) => {
    if (currentData.organization === null) {
      return unselectedOrganizationOptions;
    }

    return unselectedOrganizationOptions.concat(currentData.organization);
  };

  let loadingOrganizations = organizations.map(o => o.organization?.value);
  const handleOrganizationLoadComplete = (organizationId: string) => {
    loadingOrganizations = loadingOrganizations.filter(
      org => org !== organizationId,
    );
    if (loadingOrganizations.length === 0) {
      props.loadAllComplete();
    }
  };

  return (
    <FieldArray name="organizationsWithAccess">
      {({remove, push, replace}) => (
        <div className={classes.root}>
          <div className={classes.rows}>
            {organizations.map((o, i) => {
              // eslint-disable-next-line security/detect-object-injection
              const rowErrors = organizationErrors[i];
              // eslint-disable-next-line security/detect-object-injection
              const rowTouched = organizationTouched[i];
              return (
                <Editor
                  key={i}
                  index={i}
                  organizationOptions={getOrganizationOptions(o)}
                  remove={remove}
                  replace={replace}
                  value={o}
                  scopeId={props.scopeId}
                  setOrganizationTouched={(index, shouldValidate) =>
                    setFieldTouched(
                      `organizationsWithAccess.${index}.organization`,
                      true,
                      shouldValidate,
                    )
                  }
                  setRolesTouched={(index, shouldValidate) =>
                    setFieldTouched(
                      `organizationsWithAccess.${index}.roles`,
                      true,
                      shouldValidate,
                    )
                  }
                  errors={rowErrors}
                  touched={rowTouched}
                  multiSelectMenuContainerEl={props.multiSelectMenuContainerEl}
                  loadComplete={handleOrganizationLoadComplete}
                />
              );
            })}
          </div>

          <TextButton
            className={classes.addButton}
            onClick={() => {
              push({organization: null, roles: []});
            }}
            icon="add"
            text={t('scopes:update.organizations.addButton')}
          />
        </div>
      )}
    </FieldArray>
  );
};

export default ScopeOrganizationEditor;

const useEditorStyles = makeStyles<Theme, {}>(theme => ({
  row: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '48% 48% auto',
    '& + &': {
      marginTop: theme.spacing(2),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  organizationSelect: {
    marginRight: theme.spacing(0.5),
  },
  roleSelect: {
    marginRight: theme.spacing(0.5),
  },
  deleteIcon: {
    marginTop: '30px', // Align to center of dropdown
  },
}));

interface EditorProps {
  errors: FormikErrors<OrganizationWithRole> | undefined;
  index: number;
  multiSelectMenuContainerEl: Element | null;
  organizationOptions: Option[];
  scopeId: string;
  touched: FormikTouched<OrganizationWithRole> | undefined;
  value: OrganizationWithRole;
  remove: (index: number) => void;
  replace: (index: number, value: OrganizationWithRole) => void;
  setOrganizationTouched: (index: number, shouldValidate: boolean) => void;
  setRolesTouched: (index: number, shouldValidate: boolean) => void;
  loadComplete: (organizationId: string) => void;
}

const Editor = ({
  errors,
  index,
  multiSelectMenuContainerEl,
  organizationOptions,
  scopeId,
  touched,
  value,
  remove,
  replace,
  setOrganizationTouched,
  setRolesTouched,
  loadComplete,
}: EditorProps) => {
  const classes = useEditorStyles({});

  const handleOrganizationChange = (option: Option | null) => {
    setOrganizationTouched(index, false);
    replace(index, {organization: option, roles: []});
  };
  const handleOrganizationRolesChange = (options: Option[]) => {
    setRolesTouched(index, false);
    replace(index, {...value, roles: options});
  };

  // TS thinks the error type will be an array (error per role)
  // but this is not the case, it is a string
  const rolesError = (errors?.roles as unknown) as string | undefined;

  return (
    <div className={classes.row}>
      <div className={classes.organizationSelect}>
        <ValmetSelect
          id={`organization-${index}`}
          labelTranslationKey="scopes:update.organizations.organization"
          onChange={handleOrganizationChange}
          options={organizationOptions}
          placeholderTranslationKey="scopes:update.organizations.selectOrganization"
          errorMessageTranslationKey={
            touched?.organization ? errors?.organization : undefined
          }
          value={value.organization}
          width="full"
        />
      </div>

      <div className={classes.roleSelect}>
        <RoleValmetMultiSelect
          index={index}
          errorMessageTranslationKey={touched?.roles ? rolesError : undefined}
          scopeId={scopeId}
          organizationId={value.organization?.value}
          onChange={handleOrganizationRolesChange}
          onLoadComplete={loadComplete}
          roles={value.roles}
          multiSelectMenuContainerEl={multiSelectMenuContainerEl}
        />
      </div>

      <ValmetIcon
        className={classes.deleteIcon}
        icon="delete"
        size="medium"
        onClick={() => remove(index)}
      />
    </div>
  );
};
