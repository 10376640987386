import {
  ApiRoleRestrictionOutputDto,
  ApiScopeGetByIdOutputDto,
  ApiScopeOrganizationOutputDto,
} from '../../../types/api/Scopes/GetScopeByIdApi';
import {
  UpdatedOrganizationDto,
  ApiUpdateScopeOrganizationsInputDto,
} from '../../../types/api/Scopes/UpdateScopeApi';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {FormData, OrganizationWithRole} from './FormDataTypes';

const hasDifferentRoles = (
  roleRestrictions: ApiRoleRestrictionOutputDto[],
  roles: readonly Option[],
) => {
  return (
    roleRestrictions.length !== roles.length ||
    roleRestrictions.some(
      assignedRole =>
        !roles.some(updatedRole => updatedRole.value === assignedRole.roleId),
    )
  );
};

const isNewOrganization = (
  organizationId: string,
  initialOrganizations: ApiScopeOrganizationOutputDto[],
) => {
  return !initialOrganizations.some(o => o.organizationId === organizationId);
};

export const createUpdatePayload = (
  initialData: ApiScopeGetByIdOutputDto,
  updatedData: FormData,
): ApiUpdateScopeOrganizationsInputDto => {
  // first lets check the modified organizations and their modified roles
  const removedOrganizationIds: string[] = [];
  const updatedOrganizations: UpdatedOrganizationDto[] = [];
  for (const initialOrganization of initialData.organizationsWithAccess) {
    const updatedOrganization = updatedData.organizationsWithAccess.find(
      o => o.organization?.value === initialOrganization.organizationId,
    );
    if (updatedOrganization === undefined) {
      removedOrganizationIds.push(initialOrganization.organizationId);
    } else if (
      hasDifferentRoles(
        initialOrganization.roleRestrictions,
        updatedOrganization.roles,
      )
    ) {
      // checking which roles are removed or added
      const addedRoleIds: string[] = [];
      const removedRoleIds: string[] = [];
      // if new added role
      for (const updatedRole of updatedOrganization.roles) {
        const existingRole = initialOrganization.roleRestrictions.find(
          r => r.roleId === updatedRole.value,
        );
        if (existingRole === undefined) {
          addedRoleIds.push(updatedRole.value);
        }
      }
      // if removed role
      for (const initialRole of initialOrganization.roleRestrictions) {
        if (
          !updatedOrganization.roles.some(r => r.value === initialRole.roleId)
        ) {
          removedRoleIds.push(initialRole.roleId);
        }
      }

      updatedOrganizations.push({
        organizationId: initialOrganization.organizationId,
        addRoles: addedRoleIds,
        removeRoles: removedRoleIds,
      });
    }
  }

  // If not modified, then organization and its roles are new
  const addedOrganizations: UpdatedOrganizationDto[] = [];
  for (const updatedOrganization of updatedData.organizationsWithAccess) {
    const organization = updatedOrganization.organization;

    if (organization === null) {
      continue;
    }

    if (
      isNewOrganization(organization.value, initialData.organizationsWithAccess)
    ) {
      addedOrganizations.push({
        organizationId: organization.value,
        addRoles: updatedOrganization.roles.map(r => r.value),
        removeRoles: [],
      });
    }
  }

  return {
    removeOrganizations: removedOrganizationIds,
    updateOrganizations: [...updatedOrganizations, ...addedOrganizations],
  };
};

export const hasUserMadeChanges = (
  initialData: ApiScopeGetByIdOutputDto | null,
  updatedData: readonly OrganizationWithRole[],
): boolean => {
  // first lets check the modified organizations and their modified roles
  if (initialData === null && !updatedData.length) {
    return false;
  } else if (initialData === null) {
    return false;
  }

  for (const initialOrganization of initialData.organizationsWithAccess) {
    const updatedOrganization = updatedData.find(
      o => o.organization?.value === initialOrganization.organizationId,
    );
    if (updatedOrganization === undefined) {
      return true;
    } else if (
      hasDifferentRoles(
        initialOrganization.roleRestrictions,
        updatedOrganization.roles,
      )
    ) {
      // if new added role
      for (const updatedRole of updatedOrganization.roles) {
        const existingRole = initialOrganization.roleRestrictions.find(
          r => r.roleId === updatedRole.value,
        );
        if (existingRole === undefined) {
          return true;
        }
      }
      // if removed role
      for (const initialRole of initialOrganization.roleRestrictions) {
        if (
          !updatedOrganization.roles.some(r => r.value === initialRole.roleId)
        ) {
          return true;
        }
      }
    }
  }
  // If not modified, then organization and its roles are new
  for (const updatedOrganization of updatedData) {
    const organization = updatedOrganization.organization;

    if (organization === null) {
      continue;
    }

    if (
      isNewOrganization(
        organization.value,
        initialData.organizationsWithAccess,
      ) &&
      updatedOrganization.roles.length > 0
    ) {
      return true;
    }
  }

  return false;
};
