import {
  AccountInfo,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import config from '../configs/appConfig';

const {
  instance,
  tenant,
  clientId,
  postLogoutRedirectUri,
  cacheLocation,
  redirectUri,
  apiScopes,
} = config.msal;

const msalApp = new PublicClientApplication({
  auth: {
    clientId,
    authority: instance + tenant,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri,
    redirectUri,
  },
  cache: {
    cacheLocation: cacheLocation as 'localStorage' | 'sessionStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, msg: string) => {
        if (level === LogLevel.Error) {
          console.error(msg);
        } else {
          console.warn(msg);
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Warning,
    },
  },
});

export const handleRedirectPromise = () => {
  return msalApp.handleRedirectPromise();
};

export class AcquireTokenError extends Error {
  constructor() {
    super('Failed to acquire access token for back-end API');
    this.name = 'acquire_token_failed';
  }
}

const getApiScopes = () => {
  // constructs scopes e.g. api-client-id/Something.Read
  // this is the expected format by AAD v2
  const apiId = config.api.clientId;
  const scopes = apiScopes.split(' ');
  return scopes.map(s => `${apiId}/${s}`);
};

export const fetchServiceToken = async () => {
  try {
    const response = await msalApp.acquireTokenSilent({scopes: getApiScopes()});

    return response.accessToken;
  } catch (e) {
    throw new AcquireTokenError();
  }
};

export const login = () => {
  msalApp.loginRedirect({
    scopes: getApiScopes(),
  });
};

export const getUser = () => {
  return msalApp.getActiveAccount();
};

export const getAllAccounts = () => {
  return msalApp.getAllAccounts();
};

export const setActiveAccount = (account: AccountInfo) => {
  msalApp.setActiveAccount(account);
};

export const logout = () => {
  msalApp.logoutRedirect();
};
