import React, {useContext, useEffect, useState} from 'react';
import {ApiProblemDetails, ApiRoleOutputDto} from '../../../types/api';
import {ApiAssignableServiceAdminOutputDto} from '../../../types/api/Lookup/LookupAssignableServiceAdminsApi';
import {ApiGetAllOrganizationsOutputDto} from '../../../types/api/Organizations/GetAllOrganizationsApi';
import {ApiGetOrganizationByIdOutputDto} from '../../../types/api/Organizations/GetOrganizationByIdApi';
import {
  ApiOrganizationUpdateOutputDto,
  ApiUpdateOrganizationInputDto,
} from '../../../types/api/Organizations/UpdateOrganizationApi';
import {
  ContextPanel,
  ContextPanelContentSection,
  FormikValmetTextInput,
  FormikValmetComplexMultiSelect,
  FormCancelConfirmDialog,
  errorNotification,
  FormikValmetMultiSelect,
  LoadingScreen,
} from '@valmet-iop/ui-common';
import {Formik} from 'formik';
import {apiGetRequest, apiPutRequest} from '../../../services/api/apiService';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {ApiOrganizationTypeOutputDto} from '../../../types/api/Lookup/LookupAllOrganizationTypesApi';
import {AppContext} from '../../Layout';
import deepEqual from 'deep-equal';
import AllowedInviteEmailDomainEditor from './AllowedInviteEmailDomainEditor';
import {prop, sortBy} from 'ramda';

const updateOrganization = async (
  organizationId: string,
  model: ApiUpdateOrganizationInputDto,
) => {
  const result = await apiPutRequest<
    ApiUpdateOrganizationInputDto,
    ApiOrganizationUpdateOutputDto
  >(`api/organizations/${organizationId}`, model);
  return result;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('organizations:update.errors.nameRequired')
    .max(128, 'organizations:update.errors.nameMaxLength'),
  allowedInviteEmailDomains: Yup.array()
    .of(
      Yup.object().shape({
        domain: Yup.string()
          .required('organizations:allowedInviteEmailDomains.errorRequired')
          .matches(
            /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
            'organizations:allowedInviteEmailDomains.errorInvalidDomain',
          ),
      }),
    )
    .test(
      'no-duplicates',
      'organizations:allowedInviteEmailDomains.errorNoDuplicates',
      values => {
        // User should not be able to add multiple rows with same values
        if (!values) {
          return true;
        }

        const seenDomains = new Set<string>();
        for (const value of values) {
          if (!value || !value.domain) {
            continue;
          }

          const domain = value.domain.trim().toLowerCase();

          if (seenDomains.has(domain)) {
            return false;
          }

          seenDomains.add(domain);
        }

        return true;
      },
    ),
});

interface FormData {
  name: string;
  selectedTypes: {
    text: string;
    value: string;
  }[];
  selectedRoles: {
    text: string;
    value: string;
  }[];
  selectedAdministrators: {
    text: string;
    value: string;
  }[];
  selectedPartnerOrganizations: {
    text: string;
    value: string;
  }[];
  allowedInviteEmailDomains: {
    domain: string;
    immutableRowId: string;
  }[];
}

const getRemovedItems = (initialItems: string[], updatedItems: string[]) =>
  initialItems.filter(x => !updatedItems.includes(x));
const getAddedItems = (initialItems: string[], updatedItems: string[]) =>
  updatedItems.filter(x => !initialItems.includes(x));

const createUpdatePayload = (initialData: FormData, updatedData: FormData) => {
  const initialTypes = initialData.selectedTypes.map(x => x.value);
  const updatedTypes = updatedData.selectedTypes.map(x => x.value);
  const removedTypeIds = getRemovedItems(initialTypes, updatedTypes);
  const addedTypeIds = getAddedItems(initialTypes, updatedTypes);

  const initialRoles = initialData.selectedRoles.map(x => x.value);
  const updatedRoles = updatedData.selectedRoles.map(x => x.value);
  const removedRoleIds = getRemovedItems(initialRoles, updatedRoles);
  const addedRoleIds = getAddedItems(initialRoles, updatedRoles);

  const initialAdmins = initialData.selectedAdministrators.map(x => x.value);
  const updatedAdmins = updatedData.selectedAdministrators.map(x => x.value);
  const removedAdministratorIds = getRemovedItems(initialAdmins, updatedAdmins);
  const addedAdministratorIds = getAddedItems(initialAdmins, updatedAdmins);

  const initialPartners = initialData.selectedPartnerOrganizations.map(
    x => x.value,
  );
  const updatedPartners = updatedData.selectedPartnerOrganizations.map(
    x => x.value,
  );
  const removedPartnerOrganizationIds = getRemovedItems(
    initialPartners,
    updatedPartners,
  );
  const addedPartnerOrganizationIds = getAddedItems(
    initialPartners,
    updatedPartners,
  );

  const initialDomains = initialData.allowedInviteEmailDomains.map(
    x => x.domain,
  );
  const updatedDomains = updatedData.allowedInviteEmailDomains.map(
    x => x.domain,
  );
  const removedInviteEmailDomains = getRemovedItems(
    initialDomains,
    updatedDomains,
  );
  const addedInviteEmailDomains = getAddedItems(initialDomains, updatedDomains);
  return {
    name: updatedData.name,
    addTypes: addedTypeIds,
    removeTypes: removedTypeIds,
    addRoles: addedRoleIds,
    removeRoles: removedRoleIds,
    addAdministrators: addedAdministratorIds,
    removeAdministrators: removedAdministratorIds,
    addPartnerOrganizations: addedPartnerOrganizationIds,
    removePartnerOrganizations: removedPartnerOrganizationIds,
    addAllowedInviteEmailDomains: addedInviteEmailDomains,
    removeAllowedInviteEmailDomains: removedInviteEmailDomains,
  };
};

const emptyTypeOptions: {
  text: string;
  value: string;
}[] = [];
const emptyRoleOptions: {
  text: string;
  value: string;
}[] = [];
const emptyAdministratorOptions: {
  text: string;
  value: string;
}[] = [];
const emptyAssignablePartnerOrganizationsOptions: {
  text: string;
  value: string;
}[] = [];

const UpdateSidebar = (props: {
  isOpen: boolean;
  organizationId: string;
  onClose: () => void;
  onUpdate: (result: ApiOrganizationUpdateOutputDto) => void;
}) => {
  const {t} = useTranslation(['translation', 'organizations']);
  const appContext = useContext(AppContext);
  const [isCancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
    false,
  );
  const [initialValues, setInitialValues] = useState<FormData>({
    name: '',
    selectedTypes: [],
    selectedRoles: [],
    selectedAdministrators: [],
    selectedPartnerOrganizations: [],
    allowedInviteEmailDomains: [],
  });

  const {organizationId: selectedOrganizationId, isOpen} = props;
  const [hasAdminType, setHasAdminType] = useState(false);
  const [
    assignablePartnerOrganizationsOptions,
    setAssignablePartnerOrganizationsOptions,
  ] = useState(emptyAssignablePartnerOrganizationsOptions);

  useEffect(() => {
    if (isOpen && !!selectedOrganizationId) {
      const getSelectedOrganization = async (organizationId: string) => {
        try {
          const result = await apiGetRequest<ApiGetOrganizationByIdOutputDto>(
            `api/organizations/${organizationId}`,
          );
          setInitialValues({
            name: result.name,
            selectedTypes: result.assignedTypes.map(dto => ({
              text: dto.name,
              value: dto.typeId,
            })),
            selectedRoles: result.assignedRoles.map(dto => ({
              text: dto.name,
              value: dto.roleId,
            })),
            selectedAdministrators: result.administrators.map(dto => ({
              text: dto.displayName,
              value: dto.id,
            })),
            selectedPartnerOrganizations: result.partnerOrganizations.map(
              dto => ({
                text: dto.name,
                value: dto.id,
              }),
            ),
            allowedInviteEmailDomains: sortBy(
              prop('domain'),
              result.allowedInviteEmailDomains.map(dto => ({
                domain: dto.domainValue,
                immutableRowId: dto.domainId,
              })),
            ),
          });
          setHasAdminType(false);
          if (
            result.assignedTypes.some(
              x => x.typeId === appContext.adminOrganizationTypeId,
            )
          ) {
            setHasAdminType(true);
          }
        } catch {
          errorNotification(
            t('organizations:update.errors.getOrganizationFailed'),
          );
        }
      };
      clearValues();
      getSelectedOrganization(selectedOrganizationId);
      const getAssignablePartnerOrganizations = async () => {
        try {
          const result = await apiGetRequest<ApiGetAllOrganizationsOutputDto[]>(
            'api/organizations',
          );
          setAssignablePartnerOrganizationsOptions(
            result
              .map(dto => ({
                text: dto.name,
                value: dto.organizationId,
              }))
              .filter(x => x.value !== selectedOrganizationId),
          );
        } catch {
          errorNotification(
            t('organizations:update.errors.lookupPartnerOrganizationsFailed'),
          );
        }
      };

      getAssignablePartnerOrganizations();
    }
  }, [t, selectedOrganizationId, isOpen, appContext.adminOrganizationTypeId]);

  const clearValues = () => {
    setInitialValues({
      name: '',
      selectedTypes: [],
      selectedRoles: [],
      selectedAdministrators: [],
      selectedPartnerOrganizations: [],
      allowedInviteEmailDomains: [],
    });
  };

  const [typeOptions, setTypeOptions] = useState(emptyTypeOptions);
  const [roleOptions, setRoleOptions] = useState(emptyRoleOptions);
  const [administratorOptions, setAdministratorOptions] = useState(
    emptyAdministratorOptions,
  );

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isOpen) {
      const getTypes = async () => {
        try {
          const result = await apiGetRequest<ApiOrganizationTypeOutputDto[]>(
            'api/lookup/organization/types',
          );
          setTypeOptions(
            result
              .map(dto => ({
                text: dto.name,
                value: dto.typeId,
              }))
              .filter(x => x.value !== appContext.adminOrganizationTypeId),
          );
        } catch {
          errorNotification(t('organizations:update.errors.lookupTypesFailed'));
        }
      };

      const getRoles = async () => {
        try {
          const result = await apiGetRequest<ApiRoleOutputDto[]>('api/roles');
          setRoleOptions(
            result.map(dto => ({
              text: dto.name,
              value: dto.roleId,
            })),
          );
        } catch {
          errorNotification(t('organizations:update.errors.lookupRolesFailed'));
        }
      };

      const getAdministrators = async () => {
        try {
          const result = await apiGetRequest<
            ApiAssignableServiceAdminOutputDto[]
          >(
            `api/lookup/organization/administratorAssignment/${selectedOrganizationId}/administrators`,
          );
          setAdministratorOptions(
            result.map(dto => ({
              text: dto.displayName,
              value: dto.id,
            })),
          );
        } catch {
          errorNotification(
            t('organizations:update.errors.lookupAdminsFailed'),
          );
        }
      };

      setIsLoading(true);

      Promise.all([getTypes(), getRoles(), getAdministrators()]).finally(() =>
        setIsLoading(false),
      );
    }
  }, [appContext.adminOrganizationTypeId, isOpen, selectedOrganizationId, t]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, {setSubmitting, setFieldError, resetForm}) => {
        if (values.selectedTypes.length === 0) {
          setFieldError(
            'selectedTypes',
            'organizations:update.errors.typesRequired',
          );
          return;
        }
        setSubmitting(true);
        try {
          const payload = createUpdatePayload(initialValues, values);
          const result = await updateOrganization(selectedOrganizationId, {
            name: payload.name,
            addTypes: payload.addTypes,
            removeTypes: payload.removeTypes,
            addRoles: payload.addRoles,
            removeRoles: payload.removeRoles,
            removeAdministrators: payload.removeAdministrators,
            addAdministrators: payload.addAdministrators,
            addPartnerOrganizations: payload.addPartnerOrganizations,
            removePartnerOrganizations: payload.removePartnerOrganizations,
            addAllowedInviteEmailDomains: payload.addAllowedInviteEmailDomains,
            removeAllowedInviteEmailDomains:
              payload.removeAllowedInviteEmailDomains,
          });
          props.onUpdate(result);
          resetForm();
        } catch (e) {
          errorNotification(t('organizations:update.errors.updateFailed'));
          if ((e as ApiProblemDetails)?.status === 404) {
            setFieldError('name', 'organizations:update.errors.notFound');
          } else if ((e as ApiProblemDetails)?.status === 409) {
            setFieldError('name', 'organizations:update.errors.conflict');
          }
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({isSubmitting, values, errors, dirty, handleSubmit, resetForm}) => {
        const onCloseClick = () => {
          if (dirty && !isSubmitting) {
            setCancelConfirmDialogOpen(true);
            return;
          }

          resetForm();
          props.onClose();
        };
        const onCancelConfirmDialogResult = (confirmed: boolean) => {
          setCancelConfirmDialogOpen(false);
          if (confirmed) {
            resetForm();
            props.onClose();
          }
        };
        return (
          <>
            <ContextPanel
              open={props.isOpen}
              size="small"
              titleTranslationKey="organizations:update.title"
              titleSuffix={initialValues.name ?? ''}
              bottomActions={[
                {
                  translationKey: 'organizations:update.buttons.save',
                  style: 'primary',
                  isDisabled:
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    deepEqual(values, initialValues),
                  onClick: handleSubmit,
                },
                {
                  translationKey: 'organizations:update.buttons.cancel',
                  style: 'ghost',
                  isDisabled: isSubmitting,
                  onClick: onCloseClick,
                },
              ]}
              onCloseClick={onCloseClick}
            >
              {isSubmitting || isLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  <ContextPanelContentSection headerTranslationKey="organizations:update.general.header">
                    <FormikValmetTextInput
                      formProperty="name"
                      id="name"
                      labelTranslationKey="organizations:update.general.name"
                      isRequired
                    />
                    <FormikValmetMultiSelect
                      formProperty="selectedTypes"
                      id="types"
                      labelTranslationKey="organizations:update.general.types"
                      options={typeOptions}
                      isRequired
                      isDisabled={hasAdminType}
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="organizations:update.roles.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="selectedRoles"
                      options={roleOptions}
                      selectPlaceholderTranslationKey="organizations:update.roles.addNewPlaceholder"
                    />
                  </ContextPanelContentSection>
                  {appContext.adminOrganizationId !==
                    selectedOrganizationId && (
                    <ContextPanelContentSection headerTranslationKey="organizations:update.administrators.header">
                      <FormikValmetComplexMultiSelect
                        formProperty="selectedAdministrators"
                        selectPlaceholderTranslationKey="organizations:update.administrators.addNewPlaceholder"
                        options={administratorOptions}
                      />
                    </ContextPanelContentSection>
                  )}
                  <ContextPanelContentSection headerTranslationKey="organizations:update.partnerOrganizations.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="selectedPartnerOrganizations"
                      selectPlaceholderTranslationKey="organizations:update.partnerOrganizations.addNewPlaceholder"
                      options={assignablePartnerOrganizationsOptions}
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="organizations:allowedInviteEmailDomains.header">
                    <AllowedInviteEmailDomainEditor formProperty="allowedInviteEmailDomains" />
                  </ContextPanelContentSection>
                </>
              )}
            </ContextPanel>
            <FormCancelConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              onResult={onCancelConfirmDialogResult}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default UpdateSidebar;
