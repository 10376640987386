import {Typography, useTheme} from '@material-ui/core';
import React, {useState, useMemo, useCallback} from 'react';
import {
  StyledSpacing,
  ValmetTable,
  Column,
  Sort,
  RowAction,
} from '@valmet-iop/ui-common';
import {Row} from '@valmet-iop/ui-common/dist/components/ValmetTable';
import {descend, ascend, prop, sortWith} from 'ramda';
import {useTranslation} from 'react-i18next';

type DataRow = Row & {
  id: string;
  firstName: string;
  lastName: string;
};

const StyleguideTables = () => {
  useTranslation('styleguide');
  const theme = useTheme();
  const initialSort = undefined;
  const [sort, setSort] = useState<Sort | undefined>(initialSort);
  // You need to define the columns for the table
  const columns: Column[] = [
    {
      translationKey: 'styleguide:tables.columns.id', // Column headers are localized, so we need the key here
      dataKey: 'id', // This is the property name in the data that will be shown
      isHighlighted: true, // If true, the content will be underlined and bolded
      defaultWidthPixels: 100, // If set, overrides the default width
    },
    {
      translationKey: 'styleguide:tables.columns.firstName',
      dataKey: 'firstName',
      isSortable: true,
    },
    {
      translationKey: 'styleguide:tables.columns.lastName',
      dataKey: 'lastName',
      isSortable: true,
      defaultWidthPixels: 250,
    },
  ];
  // Define the currently shown data
  const data: DataRow[] = useMemo(
    () => [
      {
        id: '1',
        firstName: 'John',
        lastName: 'Smith',
      },
      {
        id: '2',
        firstName: 'Sylvia',
        lastName: 'Woods',
      },
      {
        id: '3',
        firstName: 'Testy',
        lastName: 'TesterTesterTesterTesterTesterTesterTesterTester',
      },
    ],
    [],
  );
  // An example of a possible sort implementation
  // You might be handling sort server-side, in which case this isn't actually needed
  const sortedData = useMemo(() => {
    const sortColumn = sort?.columnIndex;
    const sortProp =
      sortColumn === 1
        ? 'firstName'
        : sortColumn === 2
        ? 'lastName'
        : undefined;
    const sortDirection = sort?.direction;
    if (sortProp === undefined || sortDirection === undefined) {
      return data;
    }

    const directionFunc = sortDirection === 'descending' ? descend : ascend;
    const propFunc = prop(sortProp);
    return sortWith([directionFunc(propFunc)], data);
  }, [data, sort]);
  // You can define actions available on each row
  const actions: RowAction<DataRow>[] = [
    {
      icon: 'edit',
      onClick: (_, row) =>
        alert(
          `Edit row: Id ${row.id}, First ${row.firstName}, Last ${row.lastName}`,
        ),
      translationKey: 'styleguide:tables.actions.edit',
    },
    {
      icon: 'delete',
      translationKey: 'styleguide:tables.actions.delete',
      onClick: (_, row) =>
        alert(
          `Delete row: Id ${row.id}, First ${row.firstName}, Last ${row.lastName}`,
        ),
      color: theme.palette.error.main, // You can also set different colors if you want for the items
    },
  ];
  // But then you can change what actions are available per row if you want
  // for example if the user is not authorized for all the actions on all rows
  const getActionsForRow = useCallback(
    (row: DataRow, globalActions: readonly RowAction<DataRow>[]) => {
      if (row.id === '2') {
        return globalActions.slice(0, 1);
      }
      if (row.id === '3') {
        return [];
      }

      return globalActions;
    },
    [],
  );

  // If you do sorting client-side, you can just store it in local state like here
  // But if you do sorting server-side, you need to actually trigger data loading here
  const onSortChange = (sort: Sort | undefined) => {
    setSort(sort);
  };

  return (
    <>
      <Typography variant="body1">
        Use the ValmetTable component to display data. It supports sorting,
        actions per row, and all the columns keep their width even if the screen
        is smaller. The user can change the columns width. A scrollbar will
        appear on the bottom if the columns do not fit. The right-most column
        will always be shown. The table takes up 100% of its container&apos;s
        width. The user can also change which columns are shown from the
        top-right menu.
      </Typography>
      <StyledSpacing />
      <ValmetTable
        columns={columns}
        actions={actions}
        data={sortedData}
        initialSort={initialSort}
        actionsMenuNoItemsTooltip="No permissions to perform actions"
        onSortChange={onSortChange}
        getActionsForRow={getActionsForRow}
      />
    </>
  );
};

export default StyleguideTables;
