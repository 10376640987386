import api from './middleware/api';
import normalize from './middleware/normalize';
import createRootReducer from './reducers';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import asyncMiddleware from './middleware/async';
import exportMiddleware from './middleware/export';
import thunk from 'redux-thunk';

const store = createStore(
  createRootReducer,
  {},
  composeWithDevTools(
    applyMiddleware(thunk, asyncMiddleware, exportMiddleware, normalize, api),
  ),
);

export default store;
