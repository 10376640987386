const translations = {
  tables: {
    columns: {
      id: 'Identifier',
      firstName: 'First name',
      lastName: 'Last name',
    },
    actions: {
      edit: 'Edit',
      delete: 'Delete',
    },
  },
  contextPanel: {
    bottomButtons: {
      next: 'Next',
      cancel: 'Cancel',
    },
    readOnly: {
      openButton: 'Open read-only context panel',
      panelTitle: 'Example panel',
      section1: {
        header: 'General',
        code: 'Code',
        origin: 'Origin',
        destination: 'Destination',
        arrivalTime: 'Arrival time',
        supplier: 'Supplier',
        waybillWeight: 'Waybill weight',
      },
      section2: {
        header: 'Auditing',
      },
      section3: {
        header: 'Transport',
      },
    },
    editor: {
      openButton: 'Open editor context panel',
      panelTitle: 'Editor',
      section1: {
        header: 'General',
        name: {
          label: 'Name',
          helpText: 'Enter a descriptive name',
        },
        description: {
          label: 'Description',
          helpText: 'Enter a helpful description',
        },
        types: {
          label: 'Types',
          helpText: 'Some description here',
        },
      },
    },
  },
  inputs: {
    uncontrolledText: {
      label: 'Example text input',
      helpText: 'Some help text goes here',
      errorMessage: 'Only As are allowed',
    },
    controlledTextWithError: {
      label: 'Controlled text (with error)',
    },
    uncontrolledRequiredText: {
      label: 'Uncontrolled required text',
    },
    disabledText: {
      label: 'Disabled text',
    },
    textArea: {
      errorMessage: 'Maximum 32 characters are allowed',
      uncontrolled: {
        label: 'Uncontrolled text area',
        helpText: 'Some help text goes here',
      },
      controlled: {
        label: 'Controlled text area',
        helpText: 'Some help text goes here',
      },
    },
    uncontrolledSelect: {
      label: 'Uncontrolled select (required)',
      helpText: 'Some help text for the select goes here',
      errorMessage: 'This selection is required',
    },
    controlledSelect: {
      label: 'Controlled select',
      helpText:
        'This select is controlled, meaning value changes in others will affect this',
    },
    disabledSelect: {
      label: 'Disabled select',
      helpText:
        'This select is disabled and its value cannot be changed by the user',
    },
    controlledMultiSelect: {
      label: 'Example multi-select',
      helpText: 'Some help text goes here',
    },
    multiSelectAtLeastThree: {
      label: 'Multi-select with error',
      errorText: 'You must select at least 3 options',
    },
    disabledMultiSelect: {
      label: 'Disabled multi-select',
      helpText: 'This multi-select is disabled',
    },
    radios: {
      label: 'Example radio buttons',
    },
    complexMultiSelect: {
      placeholder: '+ Add a person',
    },
  },
  tablePageLayout: {
    pageTitle: 'Example list of people',
    create: 'Create',
    columns: {
      firstName: 'First name',
      lastName: 'Last name',
      jobTitle: 'Job title',
      phoneNumber: 'Phone number',
    },
  },
};

export default translations;
