const resources = {
  en: {
    translation: {
      logout: 'Log out',
      unknownUser: 'Unknown user',
      close: 'Close',
      errors: {
        requestFailed: 'Request failed',
      },
      navigation: {
        title: 'Access management',
        organizations: 'Organizations',
        permissionGroups: 'Permission groups',
        users: 'Users',
        applications: 'Applications',
        roles: 'Roles',
        scopes: 'Scopes',
      },
      pagination: {
        pageIndicatorTemplate: '{{start}}-{{end}} of {{results}} results',
        perPage: '/ page',
        previous: 'Previous',
        next: 'Next',
      },
      filter: {
        placeholder: 'Filter...',
      },
      select: {
        open: 'Open',
        close: 'Close',
        clear: 'Clear',
        noOptions: 'No options found',
        loading: 'Loading...',
      },
      menus: {
        multiSelect: {
          selectAll: 'Select all',
          clear: 'Clear',
          filterResults: 'Filter results',
        },
      },
      table: {
        multiLineText: {
          showMore: 'Show more',
        },
        tooltips: {
          sortAscending: 'Sort by ascending',
          sortDescending: 'Sort by descending',
          resetSort: 'Reset sort',
          columnPreferences: 'Column preferences',
          rowActions: 'Row actions',
          rowActionsNoPermissions: 'No permissions to perform actions',
        },
      },
      tooltips: {
        delete: 'Delete',
      },
      forms: {
        cancelConfirmDialog: {
          header: 'Are you sure you want to close without saving?',
          cancel: 'Cancel',
          closeWithoutSaving: 'Close without saving',
        },
        confirmDialog: {
          no: 'No',
          yes: 'Yes',
        },
      },
      inputs: {
        optional: 'optional',
      },
      login: {
        header: 'Authentication required',
        body: 'To continue to the application, authentication is required.',
        loggingOut: 'Logging out...',
        loginInProgress: 'Login in progress...',
        login: 'Login',
        failedErrorMessage: 'Something went wrong when logging you in.',
      },
      userNotFound: {
        header: 'Unable to load user data',
        errorDefault:
          'Your account is not registered in IAM database or something went wrong connecting to the server.',
        errorUserNotFound: 'Your account is not registered in IAM database.',
        errorConnectionFailed: 'Connection to IAM server failed.',
      },
      pageNotFound: {
        header: 'Page not found',
        body: 'The requested URL was not found on this server.',
      },
    },
  },
};

export default resources;
