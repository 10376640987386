const Prefix = 'https://valmet.com/iop/iam/errors/';
const ValidationError = Prefix + 'validation';
const ServerError = Prefix + 'servererror';
const ErrorType = {
  unknown: Prefix + 'unknown',
  validationError: ValidationError,
  authenticationError: Prefix + 'authentication',
  entityNotFound: Prefix + 'entitynotfound',
  inviteUserAlreadyExists: ValidationError + '/inviteduserexists',
  inviteUserEmailSendFailed: ServerError + '/emailsendfailed',
  inviteUserInvalidEmail: ValidationError + '/invalidemail',
  inviteUserNotAllowedEmailDomain:
    ValidationError + '/inviteemaildomainnotallowed',
};

function makeReadonly<T>(obj: T): Readonly<T> {
  return obj;
}

export default makeReadonly(ErrorType);
