import React, {useEffect, useState} from 'react';
import {ValmetMultiSelect, errorNotification} from '@valmet-iop/ui-common';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {ApiRoleForScopeLookupOutputDto} from '../../../types/api/Lookup/LookupOrganizationRolesForScopeAssignmentApi';
import {TFunction} from 'i18next';
import {apiGetRequest} from '../../../services/api/apiService';
import {useTranslation} from 'react-i18next';

const emptyOptions: Option[] = [];

const useLookupData = (
  scopeId: string,
  organizationId: string | undefined,
  t: TFunction,
  onLoadComplete: (organizationId: string) => void,
) => {
  const [data, setData] = useState<{
    isLoaded: boolean;
    roleOptions: Option[];
  }>({
    isLoaded: false,
    roleOptions: emptyOptions,
  });

  useEffect(() => {
    if (!!organizationId && !!scopeId) {
      const getOrganizationRoles = async (
        organizationId: string,
        scopeId: string,
      ) => {
        try {
          const result = await apiGetRequest<ApiRoleForScopeLookupOutputDto[]>(
            `api/lookup/scope/roleAssignment/${organizationId}/${scopeId}/roles`,
          );
          return result;
        } catch {
          errorNotification(
            t('scopes:update.errors.lookupOrganizationRolesFailed'),
          );
          return [];
        }
      };

      setData({
        isLoaded: false,
        roleOptions: [],
      });
      getOrganizationRoles(organizationId, scopeId).then(res => {
        setData({
          isLoaded: true,
          roleOptions:
            res.map(o => ({
              text: o.name,
              value: o.roleId,
            })) ?? [],
        });

        onLoadComplete(organizationId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeId, organizationId, t]);
  return data;
};

const RoleValmetMultiSelect = (props: {
  index: number;
  multiSelectMenuContainerEl: Element | null;
  scopeId: string;
  organizationId: string | undefined;
  roles: readonly Option[];
  errorMessageTranslationKey: string | undefined;
  onChange: (selectedOptions: Option[]) => void;
  onLoadComplete: (organizationId: string) => void;
}) => {
  const {t} = useTranslation(['translation', 'scopes']);
  const {
    index: i,
    scopeId: selectedScopeId,
    organizationId: selectedOrganizationId,
    roles: selectedRoles,
    onChange: onRoleSelectionChange,
    onLoadComplete,
    errorMessageTranslationKey,
    multiSelectMenuContainerEl,
  } = props;

  const {roleOptions} = useLookupData(
    selectedScopeId,
    selectedOrganizationId,
    t,
    onLoadComplete,
  );

  /**
   * MultiSelect requires reference-equal values.
   * Since the selected values prop objects are different from
   * the objects returned by useLookupData, we need to map
   * the selected values to objects from lookup data.
   * This way the objects in "values" are the same
   * objects as in "options" and everything works correctly.
   */
  const referenceEqualValues: Option[] = [];
  for (const referenceEqualValue of selectedRoles.map(sr =>
    roleOptions.find(ro => ro.value === sr.value),
  )) {
    if (referenceEqualValue) {
      referenceEqualValues.push(referenceEqualValue);
    }
  }

  return (
    <ValmetMultiSelect
      id={`organization-roles-${i}`}
      labelTranslationKey="scopes:update.organizations.roles"
      errorMessageTranslationKey={errorMessageTranslationKey}
      onChange={onRoleSelectionChange}
      options={roleOptions}
      values={referenceEqualValues}
      width="full"
      isDisabled={selectedOrganizationId === undefined}
      menuContainerEl={multiSelectMenuContainerEl}
    />
  );
};

export default RoleValmetMultiSelect;
