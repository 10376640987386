import React, {useEffect, useState} from 'react';
import {ApiUserInformationOutputDto} from '../../../types/api';
import {ApiScopeGetByIdOutputDto} from '../../../types/api/Scopes/GetScopeByIdApi';
import {ApiLookupScopeAssignableOrganizationOutputDTO} from '../../../types/api/Lookup/LookupScopeAssignableOrganizationsTypesApi';
import {
  ApiUpdateScopeOrganizationsInputDto,
  ApiUpdateScopeOrganizationsOutputDto,
} from '../../../types/api/Scopes/UpdateScopeApi';
import {
  ContextPanel,
  ContextPanelContentSection,
  FormCancelConfirmDialog,
  errorNotification,
  ApiProblemDetails,
  LoadingScreen,
} from '@valmet-iop/ui-common';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
import {TFunction} from 'i18next';
import {apiGetRequest, apiPutRequest} from '../../../services/api/apiService';
import * as Yup from 'yup';
import ScopeOrganizationEditor from '../Scopes/ScopeOrganizationEditor';
import {FormData, OrganizationWithRole} from './FormDataTypes';
import {createUpdatePayload} from './UpdateSidebarFunctions';
import {makeStyles, Theme} from '@material-ui/core';

const validationSchema = Yup.object({
  organizationsWithAccess: Yup.array().of(
    Yup.object().shape({
      organization: Yup.object<Option>()
        .nullable()
        .required('scopes:update.errors.organizationRequired'),
      roles: Yup.array<Option>()
        .nullable()
        .required('scopes:update.errors.roleRequired')
        .min(1, 'scopes:update.errors.atLeastOneRoleRequired'),
    }),
  ),
});

const emptyOptions: Option[] = [];
const initialFormData: FormData = {
  organizationsWithAccess: [],
};

const updateScope = async (
  scopeId: string,
  model: ApiUpdateScopeOrganizationsInputDto,
) => {
  const result = await apiPutRequest<
    ApiUpdateScopeOrganizationsInputDto,
    ApiUpdateScopeOrganizationsOutputDto
  >(`api/scopes/${scopeId}`, model);
  return result;
};

const useLookupData = (
  isSidebarOpen: boolean,
  selectedScopeId: string,
  t: TFunction,
) => {
  const [data, setData] = useState<{
    isLoaded: boolean;
    scope: ApiScopeGetByIdOutputDto | null;
    organizationOptions: Option[];
  }>({
    isLoaded: false,
    scope: null,
    organizationOptions: emptyOptions,
  });

  useEffect(() => {
    if (isSidebarOpen && !!selectedScopeId) {
      const getSelectedScope = async (scopeId: string) => {
        try {
          const result = await apiGetRequest<ApiScopeGetByIdOutputDto>(
            `api/scopes/${scopeId}`,
          );
          return result;
        } catch {
          errorNotification(t('scopes:update.errors.getScopeFailed'));
          return null;
        }
      };
      const getOrganizations = async (scopeId: string) => {
        try {
          const result = await apiGetRequest<
            ApiLookupScopeAssignableOrganizationOutputDTO[]
          >(`api/lookup/scope/${scopeId}/assignableOrganizations`);
          return result;
        } catch {
          errorNotification(
            t('scopes:update.errors.lookupOrganizationsFailed'),
          );
          return [];
        }
      };
      setData({
        isLoaded: false,
        scope: null,
        organizationOptions: [],
      });
      Promise.all([
        getSelectedScope(selectedScopeId),
        getOrganizations(selectedScopeId),
      ]).then(res => {
        const scope = res[0];
        // The owner of the scope should not be assignable.
        // They already have access to use any role that their organization has.
        const ownerOrganizationId = scope?.ownerOrganization.organizationId;
        setData({
          isLoaded: true,
          scope,
          organizationOptions:
            res[1]
              ?.filter(o => o.organizationId !== ownerOrganizationId)
              .map(o => ({
                text: o.name,
                value: o.organizationId,
              })) ?? [],
        });
      });
    }
  }, [isSidebarOpen, selectedScopeId, t]);
  return data;
};

const useEditorStyles = makeStyles<Theme, {isLoading: boolean}>(theme => ({
  spinner: {
    display: props => (props.isLoading ? 'block' : 'none'),
  },
  editor: {
    display: props => (props.isLoading ? 'none' : 'block'),
  },
}));

const UpdateSidebar = (props: {
  isOpen: boolean;
  scopeId: string;
  user: ApiUserInformationOutputDto | null;
  onClose: () => void;
  onUpdate: (result: ApiUpdateScopeOrganizationsOutputDto) => void;
}) => {
  const {t} = useTranslation(['translation', 'scopes']);
  const [isCancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
    false,
  );
  const [
    contextPanelMenuContainerEl,
    setContextPanelMenuContainerEl,
  ] = useState<Element | null>(null);

  const {isOpen, scopeId} = props;

  const [initialValues, setInitialValues] = useState<FormData>(initialFormData);
  const [initComplete, setInitComplete] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const {
    isLoaded: isLookupDataLoaded,
    scope,
    organizationOptions,
  } = useLookupData(isOpen, scopeId, t);
  // Once lookup data (scope + organizations) are available, set initial values for form
  // Available roles are loaded invidually when user selects organization
  useEffect(() => {
    if (!isLookupDataLoaded || scope === null) {
      return;
    }
    const organizationWithRoles: OrganizationWithRole[] = [];
    for (const organizationAssignedRole of scope.organizationsWithAccess) {
      const organizationOption = organizationOptions.find(
        oo => oo.value === organizationAssignedRole.organizationId,
      );
      if (organizationOption === undefined) {
        continue;
      }

      const roles: Option[] = [];
      for (const initialRole of organizationAssignedRole.roleRestrictions) {
        if (initialRole === undefined) {
          continue;
        }

        const roleOption: Option = {
          text: initialRole.name,
          value: initialRole.roleId,
        };
        roles.push(roleOption);
      }

      organizationWithRoles.push({organization: organizationOption, roles});
    }

    setInitialValues({
      organizationsWithAccess: organizationWithRoles,
    });

    if (organizationWithRoles.length === 0) {
      setInitComplete(true);
    }
  }, [isLookupDataLoaded, organizationOptions, scope]);

  useEffect(() => {
    if (!isOpen) {
      setInitComplete(false);
    }
  }, [isOpen]);

  const handleLoadAllComplete = () => {
    setInitComplete(true);
  };

  const classes = useEditorStyles({isLoading: !initComplete || saveInProgress});

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        setSaveInProgress(true);
        try {
          if (scope === null) {
            // Added to make the TS compiler happy
            throw new Error('Scope not available (should not happen)');
          }

          const result = await updateScope(
            scope.scopeId,
            createUpdatePayload(scope, values),
          );
          props.onUpdate(result);
          setInitialValues(initialFormData);
          resetForm({values: initialFormData});
        } catch (e) {
          if ((e as ApiProblemDetails)?.status === 404) {
            errorNotification(t('scopes:update.errors.updateForbidden'));
          } else {
            errorNotification(t('scopes:update.errors.updateFailed'));
          }
        } finally {
          setSubmitting(false);
          setSaveInProgress(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({isSubmitting, values, errors, dirty, handleSubmit, resetForm}) => {
        const onCloseClick = () => {
          if (dirty && !isSubmitting) {
            setCancelConfirmDialogOpen(true);
            return;
          }

          setInitialValues(initialFormData);
          resetForm({values: initialFormData});
          props.onClose();
        };
        const onCancelConfirmDialogResult = (confirmed: boolean) => {
          setCancelConfirmDialogOpen(false);
          if (confirmed) {
            setInitialValues(initialFormData);
            resetForm({values: initialFormData});
            props.onClose();
          }
        };
        return (
          <>
            <ContextPanel
              open={props.isOpen}
              size="medium"
              titleTranslationKey="scopes:update.title"
              titleSuffix={!!scope ? `${scope.name}` : ''}
              bottomActions={[
                {
                  translationKey: 'scopes:update.buttons.save',
                  style: 'primary',
                  isDisabled:
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    !dirty ||
                    values === initialValues,
                  onClick: handleSubmit,
                },
                {
                  translationKey: 'scopes:update.buttons.cancel',
                  style: 'ghost',
                  isDisabled: isSubmitting,
                  onClick: onCloseClick,
                },
              ]}
              onCloseClick={onCloseClick}
              paperRef={setContextPanelMenuContainerEl}
            >
              <div className={classes.spinner}>
                <LoadingScreen />
              </div>
              <div className={classes.editor}>
                <ContextPanelContentSection headerTranslationKey="scopes:update.organizations.header">
                  <ScopeOrganizationEditor
                    scopeId={scopeId}
                    organizationOptions={organizationOptions}
                    multiSelectMenuContainerEl={contextPanelMenuContainerEl}
                    loadAllComplete={handleLoadAllComplete}
                  ></ScopeOrganizationEditor>
                </ContextPanelContentSection>
              </div>
            </ContextPanel>
            <FormCancelConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              onResult={onCancelConfirmDialogResult}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default UpdateSidebar;
