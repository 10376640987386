import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import resources from './translations';
import addOrganizations from '../components/Pages/Organizations/translations';
import addPermissionGroups from '../components/Pages/PermissionGroups/translations';
import addRoles from '../components/Pages/Roles/translations';
import addScopes from '../components/Pages/Scopes/translations';
import addStyleguide from '../components/Pages/Styleguide/translations';
import addUsers from '../components/Pages/Users/translations';

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en-US',
  lng: 'en-US',
  ns: ['translation'],
  defaultNS: 'translation',
  preload: ['en-US'],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

// Add feature-specific translations
addOrganizations(i18n);
addPermissionGroups(i18n);
addRoles(i18n);
addScopes(i18n);
addStyleguide(i18n);
addUsers(i18n);

export default i18n;
