const translations = {
  title: 'Scopes',
  columns: {
    scope: 'Scope',
    owner: 'Owner',
    organizationsWithAccess: 'Organizations with access',
    created: 'Created',
  },
  actions: {
    delete: 'Delete',
    edit: 'Edit',
  },
  buttons: {
    create: 'New scope',
  },
  errors: {
    searchFailed: 'Loading scopes failed',
  },
  list: {
    noResults: 'No data available.',
  },
  update: {
    title: 'Edit scope:',
    organizations: {
      header: 'Organizations and roles',
      addButton: 'Add organization',
      organization: 'Organization',
      selectOrganization: 'Select organization',
      roles: 'Roles',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    success: 'Scope {{scopeName}} successfully updated.',
    errors: {
      organizationRequired: 'Select organization',
      roleRequired: 'Select role',
      atLeastOneRoleRequired: 'Select at least one role',
      changesRequired: 'No user made changes detected',
      lookupOrganizationsFailed: 'Organizations could not be loaded',
      lookupOrganizationRolesFailed: 'Organization roles could not be loaded',
      updateForbidden: 'Scope update forbidden',
      updateFailed: 'Scope update failed',
      getScopeFailed: 'Scope could not be loaded',
    },
  },
};

export default translations;
