import {Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {
  StyledSpacing,
  PrimaryButton,
  ContextPanel,
  ContextPanelContentSection,
  ContextPanelDataDisplay,
  TopAction,
  ValmetTextInput,
  ValmetMultiSelect,
} from '@valmet-iop/ui-common';
import {BottomAction} from '@valmet-iop/ui-common/dist/components/ContextPanel/BottomActions';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetMultiSelect';
import {useTranslation} from 'react-i18next';

const EditorPanelTypeOptions: Option[] = [
  {
    text: 'Alternative Fuels Provider',
    value: '0',
  },
  {
    text: 'Logistics Operator',
    value: '1',
  },
  {
    text: 'Alternative Fuels Consumer',
    value: '2',
  },
];

const StyleguideContextPanel = () => {
  const {t} = useTranslation('styleguide');
  const [openPanel, setOpenPanel] = useState<'readOnly' | 'editor' | null>(
    null,
  );
  const [
    editorPanelMenuContainerEl,
    setEditorPanelMenuContainerEl,
  ] = useState<Element | null>(null);
  const [editorPanelTypes, setEditorPanelTypes] = useState<Option[]>([]);

  const onButtonClick = (panel: 'readOnly' | 'editor') => {
    setOpenPanel(panel);
  };
  const onPanelClose = () => {
    setOpenPanel(null);
  };

  const topActions: TopAction[] = [
    {
      icon: 'edit',
      onClick: () => alert('Edit clicked'),
    },
    {
      icon: 'delete',
      onClick: () => alert('Delete clicked'),
    },
  ];
  const bottomActions: BottomAction[] = [
    {
      translationKey: 'styleguide:contextPanel.bottomButtons.next',
      style: 'primary',
      onClick: () => alert('Next clicked'),
    },
    {
      translationKey: 'styleguide:contextPanel.bottomButtons.cancel',
      style: 'ghost',
      onClick: onPanelClose,
    },
  ];

  return (
    <>
      <Typography variant="body1">
        Context panels can be used to show editor forms to the user etc. There
        are three different sizes: small (400px), medium (800px), and large
        (1200px). Their size is however limited to the viewport. The content
        placed inside the panel has scrolling enabled so if there is too much
        content, a vertical scrollbar will appear.
      </Typography>
      <StyledSpacing />
      <PrimaryButton
        text={t('styleguide:contextPanel.readOnly.openButton')}
        onClick={onButtonClick.bind(null, 'readOnly')}
      />
      <StyledSpacing />
      <PrimaryButton
        text={t('styleguide:contextPanel.editor.openButton')}
        onClick={onButtonClick.bind(null, 'editor')}
      />
      <ContextPanel
        open={openPanel === 'readOnly'}
        size="small"
        titleTranslationKey="styleguide:contextPanel.readOnly.panelTitle"
        topActions={topActions}
        bottomActions={bottomActions}
        onCloseClick={onPanelClose}
      >
        <ContextPanelContentSection headerTranslationKey="styleguide:contextPanel.readOnly.section1.header">
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.code"
            value="100265"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.origin"
            value="Satama"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.destination"
            value="Siilo"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.arrivalTime"
            value="17.05.2019 16:43"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.supplier"
            value="Supplier X"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.waybillWeight"
            value="300.41 kg"
          />
        </ContextPanelContentSection>
        <ContextPanelContentSection headerTranslationKey="styleguide:contextPanel.readOnly.section2.header">
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.code"
            value="100265"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.origin"
            value="Satama"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.destination"
            value="Siilo"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.arrivalTime"
            value="17.05.2019 16:43"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.supplier"
            value="Supplier X"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.waybillWeight"
            value="300.41 kg"
          />
        </ContextPanelContentSection>
        <ContextPanelContentSection headerTranslationKey="styleguide:contextPanel.readOnly.section3.header">
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.code"
            value="100265"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.origin"
            value="Satama"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.destination"
            value="Siilo"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.arrivalTime"
            value="17.05.2019 16:43"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.supplier"
            value="Supplier X"
          />
          <ContextPanelDataDisplay
            labelTranslationKey="styleguide:contextPanel.readOnly.section1.waybillWeight"
            value="300.41 kg"
          />
        </ContextPanelContentSection>
      </ContextPanel>
      <ContextPanel
        open={openPanel === 'editor'}
        size="small"
        titleTranslationKey="styleguide:contextPanel.editor.panelTitle"
        topActions={topActions}
        bottomActions={bottomActions}
        onCloseClick={onPanelClose}
        paperRef={setEditorPanelMenuContainerEl}
      >
        <ContextPanelContentSection headerTranslationKey="styleguide:contextPanel.editor.section1.header">
          <ValmetTextInput
            id="name"
            labelTranslationKey="styleguide:contextPanel.editor.section1.name.label"
            onChange={() => undefined}
            helpTextTranslationKey="styleguide:contextPanel.editor.section1.name.helpText"
            isRequired
          />
          <ValmetTextInput
            id="name"
            labelTranslationKey="styleguide:contextPanel.editor.section1.description.label"
            onChange={() => undefined}
            helpTextTranslationKey="styleguide:contextPanel.editor.section1.description.helpText"
          />
          <ValmetMultiSelect
            id="types"
            labelTranslationKey="styleguide:contextPanel.editor.section1.types.label"
            helpTextTranslationKey="styleguide:contextPanel.editor.section1.types.helpText"
            onChange={setEditorPanelTypes}
            options={EditorPanelTypeOptions}
            values={editorPanelTypes}
            menuContainerEl={editorPanelMenuContainerEl}
          />
        </ContextPanelContentSection>
      </ContextPanel>
    </>
  );
};

export default StyleguideContextPanel;
