import {makeStyles, Theme, Tooltip, Typography} from '@material-ui/core';
import React from 'react';
import {StyledSpacing, PrimaryButton} from '@valmet-iop/ui-common';

const noOp = () => undefined;

const useStyles = makeStyles<Theme, {}>(theme => ({
  buttonRoot: {
    marginRight: theme.spacing(1),
  },
}));

const StyleguideTooltips = () => {
  const classes = useStyles({});
  return (
    <>
      <Typography variant="body1">
        Tooltips can be attached to components by wrapping them with the Tooltip
        component. You can specify its default location (left/top/right/bottom).
        It may show it elsewhere if Material UI decides it does not fit where
        you wanted it. Note that it does support additional locations, but those
        have not been adjusted for the styling.
      </Typography>
      <StyledSpacing />
      <Tooltip title="Some tooltip text here" placement="right">
        <PrimaryButton
          onClick={noOp}
          text="Right"
          classes={{root: classes.buttonRoot}}
        />
      </Tooltip>
      <Tooltip title="Some tooltip text here" placement="top">
        <PrimaryButton
          onClick={noOp}
          text="Top"
          classes={{root: classes.buttonRoot}}
        />
      </Tooltip>
      <Tooltip title="Some tooltip text here" placement="bottom">
        <PrimaryButton
          onClick={noOp}
          text="Bottom"
          classes={{root: classes.buttonRoot}}
        />
      </Tooltip>
      <Tooltip title="Some tooltip text here" placement="left">
        <PrimaryButton
          onClick={noOp}
          text="Left"
          classes={{root: classes.buttonRoot}}
        />
      </Tooltip>
    </>
  );
};

export default StyleguideTooltips;
