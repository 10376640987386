import React, {useEffect, useState} from 'react';
import {
  ApiProblemDetails,
  ApiPermissionGroupBasicOutputDto,
  ApiPermissionBasicOutputDto,
  ApiRoleOutputDto,
  ApiUpdateRoleInputDto,
} from '../../../types/api';
import {
  ContextPanel,
  ContextPanelContentSection,
  FormikValmetTextInput,
  FormikValmetComplexMultiSelect,
  FormCancelConfirmDialog,
  errorNotification,
  LoadingScreen,
} from '@valmet-iop/ui-common';
import {Formik} from 'formik';
import {apiGetRequest, apiPutRequest} from '../../../services/api/apiService';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import deepEqual from 'deep-equal';

const updateRole = async (roleId: string, model: ApiUpdateRoleInputDto) => {
  const result = await apiPutRequest<ApiUpdateRoleInputDto, ApiRoleOutputDto>(
    `api/roles/${roleId}`,
    model,
  );
  return result;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('roles:update.errors.nameRequired')
    .max(128, 'roles:update.errors.nameMaxLength'),
  description: Yup.string().max(
    256,
    'roles:update.errors.descriptionMaxLength',
  ),
});

const emptyOptions: {
  text: string;
  value: string;
}[] = [];

interface FormData {
  name: string;
  description: string;
  selectedPermissionGroups: {
    text: string;
    value: string;
  }[];
  selectedPermissions: {
    text: string;
    value: string;
  }[];
}

const getRemovedItems = (initialItems: string[], updatedItems: string[]) =>
  initialItems.filter(x => !updatedItems.includes(x));
const getAddedItems = (initialItems: string[], updatedItems: string[]) =>
  updatedItems.filter(x => !initialItems.includes(x));
const createUpdatePayload = (initialData: FormData, updatedData: FormData) => {
  const removedPermissionIds = getRemovedItems(
    initialData.selectedPermissions.map(x => x.value),
    updatedData.selectedPermissions.map(x => x.value),
  );
  const removedPermissionGroupIds = getRemovedItems(
    initialData.selectedPermissionGroups.map(x => x.value),
    updatedData.selectedPermissionGroups.map(x => x.value),
  );
  const addedPermissionIds = getAddedItems(
    initialData.selectedPermissions.map(x => x.value),
    updatedData.selectedPermissions.map(x => x.value),
  );
  const addedPermissionGroupIds = getAddedItems(
    initialData.selectedPermissionGroups.map(x => x.value),
    updatedData.selectedPermissionGroups.map(x => x.value),
  );
  return {
    name: updatedData.name,
    description: updatedData.description,
    addPermissions: addedPermissionIds,
    removePermissions: removedPermissionIds,
    addPermissionGroups: addedPermissionGroupIds,
    removePermissionGroups: removedPermissionGroupIds,
  };
};

const UpdateSidebar = (props: {
  isOpen: boolean;
  roleId: string;
  onClose: () => void;
  onUpdate: (result: ApiRoleOutputDto) => void;
}) => {
  const {t} = useTranslation(['translation', 'roles']);
  const [isCancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
    false,
  );
  const [initialValues, setInitialValues] = useState<FormData>({
    name: '',
    description: '',
    selectedPermissionGroups: [],
    selectedPermissions: [],
  });

  const {roleId: selectedRoleId, isOpen} = props;
  useEffect(() => {
    if (isOpen && !!selectedRoleId) {
      const getSelectedRole = async (roleId: string) => {
        try {
          const result = await apiGetRequest<ApiRoleOutputDto>(
            `api/roles/${roleId}`,
          );
          setInitialValues({
            name: result.name,
            description: result.description,
            selectedPermissionGroups: result.permissionGroups.map(dto => ({
              text: dto.groupName,
              value: dto.permissionGroupId,
            })),
            selectedPermissions: result.permissions.map(dto => ({
              text: dto.displayName,
              value: dto.permissionId,
            })),
          });
        } catch {
          errorNotification(t('roles:update.errors.getRoleFailed'));
        }
      };
      clearValues();
      getSelectedRole(selectedRoleId);
    }
  }, [selectedRoleId, isOpen, t]);

  const clearValues = () => {
    setInitialValues({
      name: '',
      description: '',
      selectedPermissionGroups: [],
      selectedPermissions: [],
    });
  };

  const [permissionGroupOptions, setPermissionGroupOptions] = useState(
    emptyOptions,
  );

  const [permissionOptions, setPermissionOptions] = useState(emptyOptions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const getPermissionGroups = async () => {
        try {
          const result = await apiGetRequest<
            ApiPermissionGroupBasicOutputDto[]
          >('api/permissionGroups');
          setPermissionGroupOptions(
            result.map(dto => ({
              text: dto.groupName,
              value: dto.permissionGroupId,
            })),
          );
        } catch {
          errorNotification(
            t('roles:update.errors.lookupPermissionGroupsFailed'),
          );
        }
      };

      const getPermissions = async () => {
        try {
          const result = await apiGetRequest<ApiPermissionBasicOutputDto[]>(
            'api/permissions',
          );
          setPermissionOptions(
            result.map(dto => ({
              text: dto.displayName,
              value: dto.permissionId,
            })),
          );
        } catch {
          errorNotification(t('roles:update.errors.lookupPermissionsFailed'));
        }
      };

      setIsLoading(true);
      Promise.all([getPermissions(), getPermissionGroups()]).finally(() =>
        setIsLoading(false),
      );
    }
  }, [isOpen, t]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, {setSubmitting, setFieldError, resetForm}) => {
        setSubmitting(true);
        try {
          const payload = createUpdatePayload(initialValues, values);
          const result = await updateRole(selectedRoleId, {
            name: payload.name,
            description: payload.description,
            addPermissions: payload.addPermissions,
            removePermissions: payload.removePermissions,
            addPermissionGroups: payload.addPermissionGroups,
            removePermissionGroups: payload.removePermissionGroups,
          });
          props.onUpdate(result);
          resetForm();
        } catch (e) {
          errorNotification(t('roles:update.errors.updateFailed'));
          if ((e as ApiProblemDetails)?.status === 404) {
            setFieldError('name', 'roles:update.errors.notFound');
          } else if ((e as ApiProblemDetails)?.status === 409) {
            setFieldError('name', 'roles:update.errors.conflict');
          }
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({isSubmitting, values, errors, dirty, handleSubmit, resetForm}) => {
        const onCloseClick = () => {
          if (dirty && !isSubmitting) {
            setCancelConfirmDialogOpen(true);
            return;
          }

          resetForm();
          props.onClose();
        };
        const onCancelConfirmDialogResult = (confirmed: boolean) => {
          setCancelConfirmDialogOpen(false);
          if (confirmed) {
            resetForm();
            props.onClose();
          }
        };
        return (
          <>
            <ContextPanel
              open={props.isOpen}
              size="small"
              titleTranslationKey="roles:update.title"
              titleSuffix={initialValues.name ?? ''}
              bottomActions={[
                {
                  translationKey: 'roles:update.buttons.save',
                  style: 'primary',
                  isDisabled:
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    deepEqual(values, initialValues),
                  onClick: handleSubmit,
                },
                {
                  translationKey: 'roles:update.buttons.cancel',
                  style: 'ghost',
                  isDisabled: isSubmitting,
                  onClick: onCloseClick,
                },
              ]}
              onCloseClick={onCloseClick}
            >
              {isSubmitting || isLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  <ContextPanelContentSection headerTranslationKey="roles:update.general.header">
                    <FormikValmetTextInput
                      formProperty="name"
                      id="name"
                      labelTranslationKey="roles:update.general.name"
                      isRequired
                    />
                    <FormikValmetTextInput
                      formProperty="description"
                      id="description"
                      labelTranslationKey="roles:update.general.description"
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="roles:update.permissionGroups.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="selectedPermissionGroups"
                      options={permissionGroupOptions}
                      selectPlaceholderTranslationKey="roles:update.permissionGroups.addNewPlaceholder"
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="roles:update.individualPermissions.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="selectedPermissions"
                      options={permissionOptions}
                      selectPlaceholderTranslationKey="roles:update.individualPermissions.addNewPlaceholder"
                    />
                  </ContextPanelContentSection>
                </>
              )}
            </ContextPanel>
            <FormCancelConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              onResult={onCancelConfirmDialogResult}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default UpdateSidebar;
