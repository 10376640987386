import {Dispatch, AnyAction} from 'redux';
import {startsWith, endsWith, replace, compose, prop} from 'ramda';
import {saveAs} from 'file-saver';

/* EXPORT MIDDLEWARE

This middleware takes care of triggering file save dialog in browser.
Actions that download files should implement at least IExportParams

*/

export default () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  const actionType = prop('type', action);
  const isExport = startsWith('EXPORT', actionType);
  const isDone = endsWith('DONE', actionType);
  const shouldSave = isExport && isDone;

  if (shouldSave) {
    const fileName = action.payload.params.fileName;
    const extension = action.payload.params.fileExtension;
    const defaultFileName = compose(
      replace('EXPORT', ''),
      replace('_', ''),
      replace('_DONE', ''),
    )(actionType);

    saveAs(
      new Blob([action.payload.result]),
      `${fileName || defaultFileName}.${extension}`,
    );
  }
  return next(action);
};
