import React, {useState, useMemo, useCallback, useContext} from 'react';
import {useTheme} from '@material-ui/core';
import {useErrorNotification, useSearchDebounce} from '../../Hooks/Hooks';
import {
  ApiUserSearchOutputDto,
  ApiPaginatedUserQueryDto,
  ApiUserSortColumn,
  ApiPagedResponse,
} from '../../../types/api';
import {mapSortDirectionToOrderByType} from '../../../types/typeMapping';
import {
  TablePageLayout,
  Column,
  Sort,
  RowAction,
  ConfirmDialog,
  TimestampInfo,
  errorNotification,
  successNotification,
} from '@valmet-iop/ui-common';
import {Row} from '@valmet-iop/ui-common/dist/components/ValmetTable';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import CreateSidebar from './CreateSidebar';
import UpdateSidebar from './UpdateSidebar';
import {
  apiDeleteRequest,
  apiPostRequest,
} from '../../../services/api/apiService';
import {AppContext} from '../../Layout';
import {useQuery} from 'react-query';
import {
  getOrganizationAndServiceRoleValueFromRoles,
  getTranslationKeyForCombinedRole,
} from './utils';

const PageSizes = [10, 20, 30];

type RowItem = Row & {
  id: string;
  organizationName: string;
  displayName: string;
  email: string;
  type: string;
  roles: string[];
  creationInfo: TimestampInfo;
};

const Columns: readonly (Column & {sortColumn?: ApiUserSortColumn})[] = [
  {
    translationKey: 'users:columns.organizationName',
    dataKey: 'organizationName',
    isSortable: true,
    sortColumn: 'OrganizationName',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'users:columns.name',
    dataKey: 'displayName',
    isSortable: true,
    sortColumn: 'DisplayName',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'users:columns.email',
    dataKey: 'email',
    isSortable: true,
    sortColumn: 'Email',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'users:columns.type',
    dataKey: 'type',
    defaultWidthPixels: 140,
  },
  {
    translationKey: 'users:columns.roles',
    dataKey: 'roles',
    dataType: 'stringArray',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'users:columns.created',
    dataKey: 'creationInfo',
    dataType: 'timestampInfo',
    isSortable: true,
    sortColumn: 'CreatedAt',
    defaultWidthPixels: 180,
  },
];

const List = () => {
  const theme = useTheme();
  const {t} = useTranslation(['translation', 'users']);
  const appContext = useContext(AppContext);
  const {user} = appContext;

  const initialSort: Sort = {
    columnIndex: 1,
    direction: 'ascending',
  };
  const [searchParams, setSearchParams] = useState<ApiPaginatedUserQueryDto>({
    orderByType: 'Ascending',
    pageNumber: 1,
    pageSize: 10,
    searchWord: '',
    sortColumnName: 'DisplayName',
  });

  const {data: result, isError, isLoading, refetch: executeSearch} = useQuery(
    ['users', searchParams],
    ({queryKey}) =>
      apiPostRequest<
        ApiPaginatedUserQueryDto,
        ApiPagedResponse<ApiUserSearchOutputDto>
      >('api/users/search', queryKey[1] as ApiPaginatedUserQueryDto),
  );

  useErrorNotification(isError, 'users:errors.searchFailed');

  const [searchQuery, setSearchQuery] = useState('');
  useSearchDebounce(searchQuery, query =>
    setSearchParams({...searchParams, searchWord: query, pageNumber: 1}),
  );

  const [isCreatePanelOpen, setCreatePanelOpen] = useState(false);
  const [isUpdatePanelOpen, setUpdatePanelOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [
    selectedUsersOrganizationId,
    setSelectedUsersOrganizationId,
  ] = useState('');

  const [deleteState, setDeleteState] = useState({
    id: '',
    displayName: '',
    dialogVisible: false,
  });

  const data: readonly RowItem[] = useMemo(
    () =>
      result?.data.map((u: ApiUserSearchOutputDto) => ({
        id: u.userId,
        organizationId: u.organizationId,
        organizationName: u.organizationName,
        displayName: u.displayName,
        email: u.email,
        type: t(
          getTranslationKeyForCombinedRole(
            getOrganizationAndServiceRoleValueFromRoles(
              u.organizationRole,
              u.serviceRole,
            ),
          ),
        ),
        roles: u.userAssignedRoles.map(a => `${a.roleName}`),
        creationInfo: {
          timestamp: moment(u.createdAt),
          userName: u.createdBy || t('unknownUser'),
        },
      })) ?? [],
    [result, t],
  );

  const isInviteButtonHidden =
    user === null ||
    (user.serviceRole === null && user.organizationRole === 'User');

  const deleteUser = (userId: string) => {
    return apiDeleteRequest(`api/users/${userId}`);
  };

  const actions: RowAction<RowItem>[] = [
    {
      icon: 'edit',
      translationKey: 'users:actions.edit',
      onClick: rowId => {
        setSelectedUserId(rowId);
        const rowData = result?.data.find(
          (d: ApiUserSearchOutputDto) => d.userId === rowId,
        );
        if (rowData !== undefined) {
          setSelectedUsersOrganizationId(rowData.organizationId);
        }
        setUpdatePanelOpen(true);
      },
    },
    {
      icon: 'delete',
      translationKey: 'users:actions.delete',
      color: theme.palette.error.main,
      onClick: (_, row) => setDeleteState({...row, dialogVisible: true}),
    },
  ];
  const getActionsForRow = useCallback(
    (row: RowItem, globalActions: readonly RowAction<RowItem>[]) => {
      const dataItem = result?.data.find(
        (d: ApiUserSearchOutputDto) => d.userId === row.id,
      );
      if (dataItem === undefined || user === null) {
        return [];
      }

      if (
        user.organizationRole === 'OrganizationAdmin' &&
        dataItem.organizationId === user.organizationId
      ) {
        return globalActions;
      }

      if (
        (user.serviceRole === 'ServiceAdmin' ||
          user.serviceRole === 'DeliveryProjectLeadEngineer') &&
        user.administratedOrganizations.some(
          ao => ao.organizationId === dataItem.organizationId,
        )
      ) {
        return globalActions;
      }

      return [];
    },
    [result, user],
  );

  const onDeleteConfirmResult = (confirmed: boolean) => {
    const executeDelete = async (userId: string, userDisplayName: string) => {
      try {
        await deleteUser(userId);
        successNotification(
          t('users:delete.success', {displayName: userDisplayName}),
        );
        executeSearch();
      } catch {
        errorNotification(
          t('users:delete.error', {displayName: userDisplayName}),
        );
      }
    };
    if (confirmed) {
      executeDelete(deleteState.id, deleteState.displayName);
    }

    setDeleteState({...deleteState, dialogVisible: false});
  };

  const onFilterTextChange = (filterText: string) => {
    setSearchQuery(filterText);
  };

  const onPageChange = (page: number) => {
    setSearchParams({
      ...searchParams,
      pageNumber: page + 1,
    });
  };
  const onPageSizeChange = (pageSize: number) => {
    setSearchParams({
      ...searchParams,
      pageSize,
      pageNumber: 1,
    });
  };

  const onSortChange = ({columnIndex, direction}: Sort) => {
    // eslint-disable-next-line security/detect-object-injection
    const sortColumnName = Columns[columnIndex].sortColumn;
    if (sortColumnName === undefined) {
      return;
    }

    setSearchParams({
      ...searchParams,
      orderByType: mapSortDirectionToOrderByType(direction),
      pageNumber: 1,
      sortColumnName,
    });
  };

  return (
    <>
      <TablePageLayout
        titleTranslationKey="users:title"
        createButtonTextTranslationKey="users:buttons.create"
        isCreateButtonHidden={isInviteButtonHidden}
        filter={{
          filterText: searchQuery,
          onFilterTextChange,
        }}
        pagination={{
          page: searchParams.pageNumber - 1,
          pageSize: searchParams.pageSize,
          pageSizes: PageSizes,
          totalResults: result?.resultCount ?? 0,
          onPageChange,
          onPageSizeChange,
        }}
        table={{
          columns: Columns,
          actions,
          data,
          initialSort,
          actionsMenuNoItemsTooltip: t(
            'table.tooltips.rowActionsNoPermissions',
          ),
          onSortChange,
          getActionsForRow,
          isLoading: isLoading,
          noResultsMessageTranslationKey: t('users:list.noResults'),
        }}
        onCreateClick={() => setCreatePanelOpen(true)}
      />
      <CreateSidebar
        isOpen={isCreatePanelOpen}
        onClose={() => setCreatePanelOpen(false)}
        onCreate={model => {
          setCreatePanelOpen(false);
          successNotification(
            t('users:create.success', {displayName: model.name}),
          );
          executeSearch();
        }}
      />
      <UpdateSidebar
        isOpen={isUpdatePanelOpen}
        onClose={() => setUpdatePanelOpen(false)}
        onUpdate={model => {
          setUpdatePanelOpen(false);
          successNotification(
            t('users:update.success', {displayName: model.displayName}),
          );
          executeSearch();
        }}
        userId={selectedUserId}
        userOrganizationId={selectedUsersOrganizationId}
      />
      <ConfirmDialog
        headerText={t('users:delete.header')}
        isOpen={deleteState.dialogVisible}
        onResult={onDeleteConfirmResult}
        width="small"
        bodyText={t('users:delete.bodyFormat', {
          displayName: deleteState.displayName,
        })}
      />
    </>
  );
};

export default List;
