import React, {useContext, useEffect, useState} from 'react';
import {
  ApiUserUpdateInputDto,
  ApiUserUpdateOutputDto,
  ApiUserSearchOutputDto,
  ApiRoleToAddDto,
} from '../../../types/api';
import {ApiUserRoleLookupOutputDto} from '../../../types/api/Lookup/LookupUserRolesForAssignmentApi';
import {
  ContextPanel,
  ContextPanelContentSection,
  FormikValmetTextInput,
  FormCancelConfirmDialog,
  errorNotification,
  LoadingScreen,
} from '@valmet-iop/ui-common';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {Formik} from 'formik';
import {apiGetRequest, apiPutRequest} from '../../../services/api/apiService';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {
  getOrganizationAndServiceRoleValueFromRoles,
  getOrganizationRoleFromCombinedOption,
  getServiceRoleFromCombinedOption,
  OrganizationAndServiceRoleValue,
} from './utils';
import UserRoleEditor from '../Users/UserRoleEditor';
import {TFunction} from 'i18next';
import {RoleWithScopes} from './FormDataTypes';
import {AppContext} from '../../Layout';
import RoleRadioButtons from './RoleRadioButtons';
import {makeStyles, Theme} from '@material-ui/core';

const updateUser = async (userId: string, model: ApiUserUpdateInputDto) => {
  const result = await apiPutRequest<
    ApiUserUpdateInputDto,
    ApiUserUpdateOutputDto
  >(`api/users/${userId}`, model);
  return result;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('users:update.errors.nameRequired')
    .max(128, 'users:update.errors.nameMaxLength'),
  rolesWithScopes: Yup.array().of(
    Yup.object().shape({
      role: Yup.object<Option>()
        .nullable()
        .required('users:update.errors.roleRequired'),
      scopes: Yup.array<Option>().nullable(),
    }),
  ),
});

interface FormData {
  name: string;
  role: OrganizationAndServiceRoleValue;
  rolesWithScopes: readonly RoleWithScopes[];
}

const emptyOptions: {
  text: string;
  value: string;
}[] = [];
const initialFormData: FormData = {
  name: '',
  role: 'User',
  rolesWithScopes: [],
};

const createUpdatePayload = (
  initialData: ApiUserSearchOutputDto,
  updatedData: FormData,
): ApiUserUpdateInputDto => {
  const removedRoleIds: string[] = [];
  const updatedRoles: ApiRoleToAddDto[] = [];
  for (const userAssignedRole of initialData.userAssignedRoles) {
    const updatedRole = updatedData.rolesWithScopes.find(
      r => r.role?.value === userAssignedRole.roleId,
    );
    if (updatedRole === undefined) {
      removedRoleIds.push(userAssignedRole.roleId);
    } else {
      const hasDifferentScopes =
        userAssignedRole.scopes.length !== updatedRole.scopes.length ||
        userAssignedRole.scopes.some(
          assignedScope =>
            !updatedRole.scopes.some(
              updatedScope => updatedScope.value === assignedScope.scopeId,
            ),
        );
      if (hasDifferentScopes) {
        updatedRoles.push({
          roleId: userAssignedRole.roleId,
          scopes: updatedRole.scopes.map(s => s.value),
          expiresAt: null,
        });
      }
    }
  }
  const addedRoles: ApiRoleToAddDto[] = [];
  for (const updatedRole of updatedData.rolesWithScopes) {
    const role = updatedRole.role;
    if (role === null) {
      continue;
    }

    const isNewRole = !initialData.userAssignedRoles.some(
      r => r.roleId === role.value,
    );
    if (isNewRole) {
      addedRoles.push({
        roleId: role.value,
        scopes: updatedRole.scopes.map(s => s.value),
        expiresAt: null,
      });
    }
  }

  return {
    displayName: updatedData.name,
    organizationRole: getOrganizationRoleFromCombinedOption(updatedData.role),
    serviceRole: getServiceRoleFromCombinedOption(updatedData.role),
    addRoles: [...addedRoles, ...updatedRoles],
    removeRoles: removedRoleIds,
  };
};
const useLookupData = (
  isSidebarOpen: boolean,
  selectedUserId: string,
  t: TFunction,
) => {
  const [data, setData] = useState<{
    isLoaded: boolean;
    user: ApiUserSearchOutputDto | null;
    roleOptions: Option[];
  }>({
    isLoaded: false,
    user: null,
    roleOptions: emptyOptions,
  });
  useEffect(() => {
    if (isSidebarOpen && !!selectedUserId) {
      const getSelectedUser = async (userId: string) => {
        try {
          const result = await apiGetRequest<ApiUserSearchOutputDto>(
            `api/users/${userId}`,
          );
          return result;
        } catch {
          errorNotification(t('users:update.errors.getUserFailed'));
          return null;
        }
      };
      const getRoles = async (userId: string) => {
        try {
          const result = await apiGetRequest<ApiUserRoleLookupOutputDto[]>(
            `api/lookup/user/roleAssignment/roles?targetUserId=${userId}`,
          );
          return result;
        } catch {
          errorNotification(t('users:update.errors.lookupRolesFailed'));
          return [];
        }
      };

      // Reset the data
      setData({
        isLoaded: false,
        roleOptions: [],
        user: null,
      });
      Promise.all([
        getSelectedUser(selectedUserId),
        getRoles(selectedUserId),
      ]).then(res => {
        setData({
          isLoaded: true,
          user: res[0],
          roleOptions:
            res[1]?.map(r => ({
              text: r.name,
              value: r.roleId,
            })) ?? [],
        });
      });
    }
  }, [isSidebarOpen, selectedUserId, t]);
  return data;
};

const useEditorStyles = makeStyles<Theme, {isLoading: boolean}>(theme => ({
  spinner: {
    display: props => (props.isLoading ? 'block' : 'none'),
  },
  editor: {
    display: props => (props.isLoading ? 'none' : 'block'),
  },
}));

const UpdateSidebar = (props: {
  isOpen: boolean;
  userId: string;
  userOrganizationId: string;
  onClose: () => void;
  onUpdate: (result: ApiUserUpdateOutputDto) => void;
}) => {
  const {t} = useTranslation(['translation', 'users']);
  const appContext = useContext(AppContext);
  const [isCancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
    false,
  );
  const [initialValues, setInitialValues] = useState<FormData>(initialFormData);
  const [initComplete, setInitComplete] = useState(false);
  const [roleInitComplete, setRoleInitComplete] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [
    contextPanelMenuContainerEl,
    setContextPanelMenuContainerEl,
  ] = useState<Element | null>(null);

  const {userId: selectedUserId, userOrganizationId, isOpen} = props;
  const isTargetUserFromAdminOrganization =
    appContext.user?.adminOrganizationId === userOrganizationId;
  const userIsServiceAdmin = appContext.user?.serviceRole === 'ServiceAdmin';

  const {isLoaded: isLookupDataLoaded, user, roleOptions} = useLookupData(
    isOpen,
    selectedUserId,
    t,
  );
  // Once lookup data (user + roles) are available, set initial values for form
  // Available scopes options are loaded individually based on selected role and user
  useEffect(() => {
    if (!isLookupDataLoaded || user === null) {
      return;
    }

    const rolesWithScopes: RoleWithScopes[] = [];
    for (const userAssignedRole of user.userAssignedRoles) {
      const roleOption = roleOptions.find(
        ro => ro.value === userAssignedRole.roleId,
      );
      if (roleOption === undefined) {
        continue;
      }

      const scopes: Option[] = [];
      for (const initialScope of userAssignedRole.scopes) {
        if (initialScope === undefined) {
          continue;
        }

        const scopeOption: Option = {
          text: initialScope.scopeDisplayName,
          value: initialScope.scopeId,
        };
        scopes.push(scopeOption);
      }

      rolesWithScopes.push({
        role: roleOption,
        scopes,
        immutableRowId: roleOption.value,
      });
    }

    // We set the role to OrganizationAdmin if the edited user has a service role
    // and the current user is not a service admin.
    // This is because they cannot see the service role options and thus
    // the user would have no role from their point of view.
    // We also disable the role selection in this situation so that they cannot edit
    // it.
    setInitialValues({
      name: user.displayName,
      role: userIsServiceAdmin
        ? getOrganizationAndServiceRoleValueFromRoles(
            user.organizationRole,
            user.serviceRole,
          )
        : user.organizationRole === 'OrganizationAdmin'
        ? 'OrganizationAdmin'
        : 'User',
      rolesWithScopes: rolesWithScopes,
    });

    setInitComplete(true);
    if (rolesWithScopes.length === 0) {
      setRoleInitComplete(true);
    }
  }, [isLookupDataLoaded, roleOptions, user, userIsServiceAdmin]);

  useEffect(() => {
    if (!isOpen) {
      setInitComplete(false);
      setRoleInitComplete(false);
    }
  }, [isOpen]);

  const handleLoadAllComplete = () => {
    setRoleInitComplete(true);
  };

  const classes = useEditorStyles({
    isLoading: !initComplete || !roleInitComplete || saveInProgress,
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, {setSubmitting}) => {
        setSubmitting(true);
        setSaveInProgress(true);
        try {
          if (user === null) {
            // Added to make the TS compiler happy
            throw new Error('User not available (should not happen)');
          }

          const result = await updateUser(
            props.userId,
            createUpdatePayload(user, values),
          );
          props.onUpdate(result);
          setInitialValues(initialFormData);
        } catch {
          errorNotification(t('users:update.errors.updateFailed'));
        } finally {
          setSubmitting(false);
          setSaveInProgress(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({isSubmitting, values, errors, dirty, handleSubmit}) => {
        const onCloseClick = () => {
          if (dirty && !isSubmitting) {
            setCancelConfirmDialogOpen(true);
            return;
          }
          setInitialValues(initialFormData);
          props.onClose();
        };
        const onCancelConfirmDialogResult = (confirmed: boolean) => {
          setCancelConfirmDialogOpen(false);
          if (confirmed) {
            setInitialValues(initialFormData);
            props.onClose();
          }
        };
        return (
          <>
            <ContextPanel
              open={props.isOpen}
              size="medium"
              titleTranslationKey="users:update.title"
              titleSuffix={
                !!user ? `${user.displayName}, ${user.organizationName}` : ''
              }
              bottomActions={[
                {
                  translationKey: 'users:update.buttons.save',
                  style: 'primary',
                  isDisabled:
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    values === initialValues,
                  onClick: handleSubmit,
                },
                {
                  translationKey: 'users:update.buttons.cancel',
                  style: 'ghost',
                  isDisabled: isSubmitting,
                  onClick: onCloseClick,
                },
              ]}
              onCloseClick={onCloseClick}
              paperRef={setContextPanelMenuContainerEl}
            >
              <div className={classes.spinner}>
                <LoadingScreen />
              </div>
              <div className={classes.editor}>
                <ContextPanelContentSection headerTranslationKey="users:update.general.header">
                  <FormikValmetTextInput
                    formProperty="name"
                    id="name"
                    labelTranslationKey="users:update.general.name"
                    isRequired
                  />
                  <RoleRadioButtons
                    formProperty="role"
                    labelTranslationKey="users:update.general.role"
                    isUserInAdminOrganization={
                      isTargetUserFromAdminOrganization
                    }
                    isDisabled={!userIsServiceAdmin && !!user?.serviceRole}
                  />
                </ContextPanelContentSection>
                <ContextPanelContentSection headerTranslationKey="users:update.roles.header">
                  <UserRoleEditor
                    userOrganizationId={userOrganizationId}
                    roleOptions={roleOptions}
                    multiSelectMenuContainerEl={contextPanelMenuContainerEl}
                    loadAllComplete={handleLoadAllComplete}
                  />
                </ContextPanelContentSection>
              </div>
            </ContextPanel>

            <FormCancelConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              onResult={onCancelConfirmDialogResult}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default UpdateSidebar;
