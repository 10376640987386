const translations = {
  actions: {
    delete: 'Delete',
    edit: 'Edit',
  },
  buttons: {
    create: 'New group',
  },
  columns: {
    id: 'ID',
    groupName: 'Group name',
    description: 'Description',
    rolesWithGroup: 'Roles with group',
    permissions: 'Permissions',
    created: 'Created',
  },
  cells: {
    all: 'All',
  },
  list: {
    noResults: 'No data available.',
  },
  title: 'Permission groups',
  errors: {
    searchFailed: 'Loading permission groups failed',
  },
  create: {
    title: 'Create permission group',
    success: 'Permission group {{groupName}} successfully created.',
    general: {
      header: 'General information',
      name: 'Name',
      description: 'Description',
    },
    permissions: {
      header: 'Permissions',
      addNewPlaceholder: '+ Add new permission',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      createFailed: 'Error in creating permission group.',
      lookupPermissionsFailed: 'Error in getting available permissions.',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      descriptionMaxLength: "Description can't be longer than 256 characters",
      conflict: 'Permission group with this name already exists',
    },
  },
  update: {
    title: 'Edit permission group:',
    success: 'Permission group {{groupName}} successfully saved.',
    general: {
      header: 'General information',
      name: 'Name',
      description: 'Description',
    },
    permissions: {
      header: 'Permissions',
      addNewPlaceholder: '+ Add new permission',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      getPermissionGroupFailed: 'Error in getting permission group.',
      updateFailed: 'Error in updating permission group.',
      lookupPermissionsFailed: 'Error in getting available permissions.',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      descriptionMaxLength: "Description can't be longer than 256 characters",
      conflict: 'Permission group with this name already exists',
      notFound: 'Given permission group or permission not found',
    },
  },
  delete: {
    error: 'Error in deleting permission group {{groupName}}.',
    header: 'Are you sure?',
    bodyFormat:
      'Permission group "{{groupName}}" will be deleted. This action cannot be undone.',
    success: 'Permission group {{groupName}} successfully deleted.',
  },
};

export default translations;
