const host = window.location.host;
let env: string | undefined;
switch (host) {
  case 'localhost:3000':
    env = 'local';
    break;
  case 'fnc-sap-euw-dv-iop-iam-ui.azurewebsites.net':
    env = 'dev';
    break;
  case 'access.dev.integratedoperations.on.valmetsystem.com':
    env = 'dev';
    break;
  case 'fnc-sap-euw-qa-iop-iam-ui.azurewebsites.net':
    env = 'test';
    break;
  case 'access.test.integratedoperations.on.valmetsystem.com':
    env = 'test';
    break;
  case 'fnc-sap-euw-pd-iop-iam-ui.azurewebsites.net':
    env = 'prod';
    break;
  case 'access.integratedoperations.on.valmetsystem.com':
    env = 'prod';
    break;
  case 'fnc-sap-euw-st-iop-iam-ui.azurewebsites.net':
    env = 'staging';
    break;
  case 'access.staging.integratedoperations.on.valmetsystem.com':
    env = 'staging';
    break;
  case 'fnc-sad-euw-dm-iop-iam-ui.azurewebsites.net':
    env = 'demo';
    break;
  case 'access.demo.integratedoperations.on.valmetsystem.com':
    env = 'demo';
    break;
  case 'fnc-sad-euw-id-iop-iam-ui.azurewebsites.net':
    env = 'internaldemo';
    break;
  case 'access.internaldemo.integratedoperations.on.valmetsystem.com':
    env = 'internaldemo';
    break;
}

const config = {
  api: {
    url: '',
    version: '1.0-internal',
    clientId: '',
  },
  msal: {
    instance: 'https://login.microsoftonline.com/',
    tenant: '',
    clientId: '',
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage',
    apiScopes: 'IAM.Access',
    redirectUri: window.location.origin + '/aad-callback',
  },
};

if (env === 'local') {
  config.api.url = 'http://localhost:10586';
  config.api.clientId = '0abd5925-804e-4ad7-a5d4-fcd7e1a23143';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = '711f2e19-a0e9-4451-be9f-d494b62247cf';
} else if (env === 'dev') {
  config.api.url = 'https://wes-sap-euw-dv-iop-iam-iam.azurewebsites.net';
  config.api.clientId = '0abd5925-804e-4ad7-a5d4-fcd7e1a23143';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = '711f2e19-a0e9-4451-be9f-d494b62247cf';
} else if (env === 'test') {
  config.api.url = 'https://wes-sap-euw-qa-iop-iam-iam.azurewebsites.net';
  config.api.clientId = '0abd5925-804e-4ad7-a5d4-fcd7e1a23143';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = '711f2e19-a0e9-4451-be9f-d494b62247cf';
} else if (env === 'prod') {
  config.api.url = 'https://wes-sap-euw-pd-iop-iam-iam.azurewebsites.net';
  config.api.clientId = '038c4f12-414b-4014-a089-172feccd6e00';
  config.msal.tenant = 'valmetsolutions.onmicrosoft.com';
  config.msal.clientId = '16f242b9-3e9e-498b-ac0a-6c94b55aeae8';
} else if (env === 'staging') {
  config.api.url = 'https://wes-sap-euw-st-iop-iam-iam.azurewebsites.net';
  config.api.clientId = 'd1a82065-77ea-4949-a61a-5d03c3671add';
  config.msal.tenant = 'valmetsolutions.onmicrosoft.com';
  config.msal.clientId = '2fdc759a-0872-47cd-ac7e-761f281aa6d0';
} else if (env === 'demo') {
  config.api.url = 'https://wes-sad-euw-dm-iop-iam-iam.azurewebsites.net';
  config.api.clientId = '0abd5925-804e-4ad7-a5d4-fcd7e1a23143';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = '711f2e19-a0e9-4451-be9f-d494b62247cf';
} else if (env === 'internaldemo') {
  config.api.url = 'https://wes-sad-euw-id-iop-iam-iam.azurewebsites.net';
  config.api.clientId = '0abd5925-804e-4ad7-a5d4-fcd7e1a23143';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = '711f2e19-a0e9-4451-be9f-d494b62247cf';
} else {
  throw new Error('Unknown environment: ' + env);
}

export default config;
