import {ApiAction} from '../actions/common';
import {API_ACTION} from '../actions/constants';
import {Action, Dispatch} from 'redux';
import apiRequest from '../../services/api/apiService';
import {acquireTokenFailedActionCreator} from '../actions/user';
import {AcquireTokenError} from '../../services/authService';
import {AnyAction} from 'typescript-fsa';
import i18n from '../../i18n/index';
import {errorNotification} from '@valmet-iop/ui-common';

/*
API MIDDLEWARE

Centralized place to send API requests when using redux
*/

export default ({dispatch}: {dispatch: Dispatch<AnyAction>}) => (
  next: Dispatch<Action<any>>,
) => (action: ApiAction<any, any, any>) => {
  if (action.type === API_ACTION) {
    const {meta} = action;

    dispatch(meta.actions.started(action.type));

    const {method, resource, responseType} = meta;
    const {payload} = action;

    apiRequest({
      method,
      resource,
      payload,
      responseType,
    })
      .then(result => {
        const payload = result.data ? result.data : result;
        dispatch(
          meta.actions.done({params: meta.params || {}, result: payload}),
        );
      })
      .catch((error: any) => {
        if (error instanceof AcquireTokenError) {
          // Failed to get access token from AAD, user will need to re-authenticate
          dispatch(acquireTokenFailedActionCreator());
        }

        if (error.response) {
          dispatch(
            meta.actions.failed({
              params: meta.params || {},
              error: error.response.data,
            }),
          );
        } else if (error.request) {
          dispatch(
            meta.actions.failed({
              params: meta.params || {},
              error: 'request was made, no response',
            }),
          );
        } else {
          dispatch(
            meta.actions.failed({
              params: meta.params || {},
              error: 'unable to make request',
            }),
          );
        }

        errorNotification(i18n.t('errors.requestFailed'));
      });
  }
  next(action);
};
