import appStateReducer, * as fromAppStateReducer from './appState';
import userReducer, * as fromUserReducer from './user';
import {combineReducers} from 'redux';
import {IState} from '../../types/types';

export default combineReducers<IState>({
  user: userReducer,
  appState: appStateReducer,
});

export const getUserName = (state: IState) => {
  return fromUserReducer.getUserName(state.user);
};

export const getUserIsAuthenticated = (state: IState) => {
  return (
    !!fromUserReducer.getUserName(state.user) &&
    !fromUserReducer.getAcquireTokenFailed(state.user)
  );
};

export const getUserIsLoggingOut = (state: IState) => {
  return fromUserReducer.getUserIsLoggingOut(state.user);
};

export const getIsAppInitialized = (state: IState) => {
  return fromAppStateReducer.getIsAppInitialized(state.appState);
};
