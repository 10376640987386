import axios, {AxiosError, AxiosResponse, CancelTokenSource} from 'axios';
import {startsWith, includes} from 'ramda';
import config from '../../configs/appConfig';
import ApiRequest from './apiRequest';
import HttpMethod from './httpMethod';
import {AcquireTokenError, fetchServiceToken} from '../authService';
import {ApiProblemDetails} from '../../types/api';
import {acquireTokenFailedActionCreator} from '../../redux/actions/user';
import store from '../../redux/store';

/*
Centralized code for all API requests. Extend axiosConfig if you need more advanced features.
*/

interface IHeaders {
  [header: string]: string;
}

class RequestHeaders {
  private headers: IHeaders;
  public constructor() {
    this.headers = {};
  }
  public async addAuthorizationHeader(token?: string) {
    if (!token) {
      token = await fetchServiceToken();
    }

    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token}`,
    };

    return this;
  }
  public addJsonContentType() {
    this.headers = {
      ...this.headers,
      'Content-Type': 'application/json',
    };
    return this;
  }

  public addNoCache() {
    this.headers = {
      ...this.headers,
      'Cache-Control': 'no-cache',
    };
    return this;
  }

  public add() {
    return this.headers;
  }
}

const formatResourceUrl = (resource: string) => {
  if (!startsWith('http', resource)) {
    resource = `${config.api.url}/${resource}`;
  }

  if (!includes('api-version', resource)) {
    const apiVersionQueryParamDelimiter =
      resource.indexOf('?') > -1 ? '&' : '?';
    resource = `${resource}${apiVersionQueryParamDelimiter}api-version=${config.api.version}`;
  }

  return resource;
};

const addPayload = (options: any, method: HttpMethod, payload: any) => {
  if (payload && method === HttpMethod.GET) {
    options.params = payload;
  } else {
    options.data = payload;
  }
};

const addResponseType = (options: any, responseType?: string) => {
  if (!!responseType) {
    options.responseType = responseType;
  }
};

function withCancellation<T>(
  promise: Promise<T>,
  source: CancelTokenSource,
): Promise<T> {
  (promise as any).cancel = () => {
    source.cancel('Request canceled');
  };
  return promise;
}

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError;
}

async function mapResponse<T>(
  promise: Promise<AxiosResponse<T>>,
  method: HttpMethod,
): Promise<T> {
  try {
    const res = await promise;
    return res.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      // Bit of a hack, the result can only be undefined
      // if the caller cancels the request
      // react-query handles this correctly and there the result is undefined initially as well
      // When using the API functions here directly, cancellation is not typically used.
      // Without this hack we need to check for undefined in *all* usages of these API functions.
      // Even if they don't use cancellation and thus it isn't a possible situation.
      return undefined as any;
    }

    if (
      isAxiosError(err) &&
      err.response?.headers &&
      err.response.headers['content-type']?.startsWith(
        'application/problem+json',
      )
    ) {
      const problemDetails: ApiProblemDetails = err.response?.data;
      throw problemDetails;
    }

    if (err instanceof AcquireTokenError && method === HttpMethod.GET) {
      store.dispatch(acquireTokenFailedActionCreator());
    }

    throw err;
  }
}

export default async function apiRequest<D, T>({
  method,
  resource,
  token,
  payload,
  responseType,
  cancelTokenSource,
}: ApiRequest<D>): Promise<AxiosResponse<T>> {
  if (!method) {
    method = HttpMethod.GET;
  }

  const url = formatResourceUrl(resource);
  const headers = new RequestHeaders().addJsonContentType();

  await headers.addAuthorizationHeader(token);

  const options: any = {
    method,
    url,
    headers: headers.add(),
    cancelToken: cancelTokenSource?.token,
  };

  addPayload(options, method, payload);
  addResponseType(options, responseType);

  return axios(options).then((response: AxiosResponse<T>) => {
    return response;
  });
}

function getWrappedRequest<D, T>(
  method: HttpMethod,
  resource: string,
  payload?: D,
) {
  const cancelTokenSource = axios.CancelToken.source();
  const promise = apiRequest<D, T>({
    method,
    resource,
    cancelTokenSource,
    payload,
  });

  // withCancellation should be top wrapper as that exposes a
  // cancel function for react-query that it can use to cancel the request
  return withCancellation(mapResponse(promise, method), cancelTokenSource);
}

export function apiGetRequest<T>(resource: string) {
  return getWrappedRequest<{}, T>(HttpMethod.GET, resource, {});
}

export function apiPostRequest<D, T>(resource: string, payload: D) {
  return getWrappedRequest<D, T>(HttpMethod.POST, resource, payload);
}

export function apiPutRequest<D, T>(resource: string, payload: D) {
  return getWrappedRequest<D, T>(HttpMethod.PUT, resource, payload);
}

export function apiDeleteRequest<D = {}, T = {}>(
  resource: string,
  payload?: D,
) {
  return getWrappedRequest<D, T>(HttpMethod.DELETE, resource, payload);
}
