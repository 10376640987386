import {FormHelperText, makeStyles, TableRow, Theme} from '@material-ui/core';
import {
  TextButton,
  ValmetBasicTable,
  ValmetBasicTableCellWithRemoveButton,
  ValmetTextInput,
} from '@valmet-iop/ui-common';
import {
  FieldArray,
  FormikErrors,
  FormikTouched,
  isString,
  useFormikContext,
} from 'formik';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {nanoid} from 'nanoid';

export interface AllowedInviteEmailDomainEditorProps {
  formProperty: string;
}

interface FormAllowedInviteEmailDomain {
  domain: string;
  immutableRowId: string;
}

const useAllowedInviteEmailDomainEditorStyles = makeStyles<Theme, {}>(
  theme => ({
    addButton: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const AllowedInviteEmailDomainEditor = (
  props: AllowedInviteEmailDomainEditorProps,
) => {
  const {t} = useTranslation(['translation', 'organizations']);
  const classes = useAllowedInviteEmailDomainEditorStyles({});
  const {
    values: formValues,
    errors: formErrors,
    touched: formTouched,
    setFieldTouched,
  } = useFormikContext<any>();

  const value = formValues[
    props.formProperty
  ] as FormAllowedInviteEmailDomain[];
  const errors = formErrors[props.formProperty] as
    | string
    | FormikErrors<FormAllowedInviteEmailDomain>[]
    | undefined;
  const touched =
    (formTouched[props.formProperty] as FormikTouched<
      FormAllowedInviteEmailDomain
    >[]) ?? [];

  return (
    <FieldArray name={props.formProperty}>
      {({remove, replace, insert}) => (
        <>
          <TextButton
            className={classes.addButton}
            text={t('organizations:allowedInviteEmailDomains.add')}
            icon="add"
            onClick={() => {
              insert(0, {immutableRowId: nanoid(), domain: ''});
            }}
          />
          {value.length > 0 && (
            <ValmetBasicTable
              columns={[
                {
                  translationKey:
                    'organizations:allowedInviteEmailDomains.domainHeader',
                  width: '100%',
                },
              ]}
            >
              {value.map((x, i) => {
                const rowErrors =
                  errors !== undefined && !isString(errors)
                    ? // eslint-disable-next-line security/detect-object-injection
                      errors[i]
                    : undefined;
                // eslint-disable-next-line security/detect-object-injection
                const rowTouched = touched[i];
                return (
                  <EditorRow
                    key={x.immutableRowId}
                    index={i}
                    errors={rowErrors}
                    touched={rowTouched}
                    setDomainTouched={index =>
                      setFieldTouched(`${props.formProperty}.${index}.domain`)
                    }
                    remove={remove}
                    replace={replace}
                    value={x}
                  />
                );
              })}
            </ValmetBasicTable>
          )}
          <ErrorDisplay formProperty={props.formProperty} errors={formErrors} />
        </>
      )}
    </FieldArray>
  );
};

export default AllowedInviteEmailDomainEditor;

interface EditorRowProps {
  index: number;
  errors: FormikErrors<FormAllowedInviteEmailDomain> | undefined;
  touched: FormikTouched<FormAllowedInviteEmailDomain> | undefined;
  value: FormAllowedInviteEmailDomain;
  setDomainTouched: (index: number) => void;
  remove: (index: number) => void;
  replace: (index: number, value: FormAllowedInviteEmailDomain) => void;
}

const EditorRow = ({
  index,
  errors,
  touched,
  value,
  setDomainTouched,
  remove,
  replace,
}: EditorRowProps) => {
  const handleDomainChange = (updatedValue: string) => {
    replace(index, {...value, domain: updatedValue.trim().toLowerCase()});
  };
  const handleDomainBlur = () => {
    setDomainTouched(index);
  };
  const handleRemoveClick = () => {
    remove(index);
  };

  return (
    <TableRow>
      <ValmetBasicTableCellWithRemoveButton onRemoveClick={handleRemoveClick}>
        <ValmetTextInput
          id={`allowedInviteEmailDomain-${value.immutableRowId}`}
          labelTranslationKey=""
          onChange={handleDomainChange}
          disableInputWrapperMargin
          onBlur={handleDomainBlur}
          errorMessageTranslationKey={
            touched?.domain ? errors?.domain : undefined
          }
          value={value.domain}
        />
      </ValmetBasicTableCellWithRemoveButton>
    </TableRow>
  );
};

const useErrorDisplayStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    color: theme.palette.error.main,
  },
}));

const ErrorDisplay = ({
  formProperty,
  errors,
}: {
  formProperty: string;
  errors: FormikErrors<any>;
}) => {
  const {t} = useTranslation(['translation', 'organizations']);
  const classes = useErrorDisplayStyles({});
  // eslint-disable-next-line security/detect-object-injection
  const error = errors[formProperty];
  if (!error || !isString(error)) {
    return null;
  }

  return <FormHelperText className={classes.root}>{t(error)}</FormHelperText>;
};
