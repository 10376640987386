import {LoadingScreen, PrimaryButton} from '@valmet-iop/ui-common';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
  getUserIsLoggingOut,
  getUserIsAuthenticated,
} from '../../redux/reducers';
import {login} from '../../services/authService';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {IState} from '../../types/types';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const AutoLoginTimestampKey = 'last-autologin';
const currentUnixTimestampSeconds = () => {
  return Math.floor(new Date().getTime() / 1000);
};

const isAutoLoginRequired = () => {
  const lastAutoLoginTimestampValue = sessionStorage.getItem(
    AutoLoginTimestampKey,
  );
  if (!lastAutoLoginTimestampValue) {
    return true;
  }

  const lastAutoLoginTimestamp = Number(lastAutoLoginTimestampValue);
  const now = currentUnixTimestampSeconds();
  const diff = now - lastAutoLoginTimestamp;
  // If last auto-login was 1 minute ago, we can trigger it again
  return diff > 60;
};

const storeAutoLoginTimestamp = () => {
  const timestamp = currentUnixTimestampSeconds();
  sessionStorage.setItem(AutoLoginTimestampKey, timestamp.toString());
};

const Login: React.FC = () => {
  const {t} = useTranslation();
  const [loginState, setLoginState] = useState<
    | 'UNKNOWN'
    | 'WAITING_FOR_USER'
    | 'STARTED_MANUALLY'
    | 'STARTED_AUTOMATICALLY'
  >('UNKNOWN');

  const isAuthenticated = !!useSelector<IState, boolean>(
    getUserIsAuthenticated,
    shallowEqual,
  );
  const userIsLoggingOut = useSelector<IState, boolean>(
    getUserIsLoggingOut,
    shallowEqual,
  );

  useEffect(() => {
    const triggerAutoLogin = isAutoLoginRequired();
    if (triggerAutoLogin) {
      storeAutoLoginTimestamp();
      login();
      setLoginState('STARTED_AUTOMATICALLY');
    } else {
      // Don't trigger automatic login,
      // show the login button to user instead
      setLoginState('WAITING_FOR_USER');
    }
  }, []);

  const formSubmitted = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoginState('STARTED_MANUALLY');
    login();
  };

  if (loginState === 'UNKNOWN' || loginState === 'STARTED_AUTOMATICALLY') {
    return null;
  }

  return (
    <>
      {userIsLoggingOut && (
        <LoadingScreen text={t('login.loggingOut')} showText />
      )}
      {!userIsLoggingOut && isAuthenticated && <Redirect to="/" />}
      {!userIsLoggingOut && !isAuthenticated && (
        <Card>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              {t('login.header')}
            </Typography>
            <Typography gutterBottom>{t('login.body')}</Typography>
            <PrimaryButton
              onClick={formSubmitted}
              isDisabled={loginState === 'STARTED_MANUALLY'}
              text={
                loginState === 'STARTED_MANUALLY'
                  ? t('login.loginInProgress')
                  : t('login.login')
              }
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Login;
