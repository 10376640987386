import React from 'react';
import {Card, CardContent, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {AppContext} from '../Layout';
import ErrorType from '../../types/ErrorType';
import {AxiosError} from 'axios';
import {ApiProblemDetails} from '@valmet-iop/ui-common';

const getErrorTranslationKey = (userInfoLoadError: any) => {
  // If we actually get a response, the error is an ApiProblemDetails
  if (userInfoLoadError?.type === ErrorType.entityNotFound) {
    return 'userNotFound.errorUserNotFound';
  }

  // If connection fails to server, the error will be an AxiosError
  if (userInfoLoadError.isAxiosError) {
    const axiosError = userInfoLoadError as AxiosError<ApiProblemDetails>;
    if (!axiosError.response) {
      return 'userNotFound.errorConnectionFailed';
    }
  }

  return 'userNotFound.errorDefault';
};

const UserNotFound = () => {
  const {t} = useTranslation();
  const {userInfoLoadError} = useContext(AppContext);
  const errorTranslationKey = getErrorTranslationKey(userInfoLoadError);

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('userNotFound.header')}
        </Typography>
        <Typography gutterBottom>{t(errorTranslationKey)}</Typography>
      </CardContent>
    </Card>
  );
};

export default UserNotFound;
