import {Typography} from '@material-ui/core';
import {remove} from 'ramda';
import React, {useState} from 'react';
import {
  StyledSpacing,
  ValmetChip,
  ChipPerLineContainer,
  PrimaryButton,
} from '@valmet-iop/ui-common';

const defaultChips = [
  {
    label: 'Chip 1',
    disabled: false,
  },
  {
    label: 'Disabled Chip 2',
    disabled: true,
  },
  {
    label:
      'Chip with a really long text more text more text more text more text more text more text more text',
    disabled: false,
  },
];

const StyleguideChips = () => {
  const [chips, setChips] = useState(defaultChips);

  const handleDelete = (index: number) => () => {
    setChips(remove(index, 1, chips));
  };
  const resetChips = () => {
    setChips(defaultChips);
  };

  return (
    <>
      <Typography variant="body1">
        Using ValmetChips, you can show the user a list of things they have
        selected, and allow them to remove them. ChipPerLineContainer can be
        used to arrange chips so that one appears per line.
      </Typography>
      <StyledSpacing />
      <ChipPerLineContainer>
        {chips.map((c, i) => (
          <ValmetChip key={c.label} {...c} onDelete={handleDelete(i)} />
        ))}
      </ChipPerLineContainer>
      <StyledSpacing />
      <PrimaryButton text="Reset chips" onClick={resetChips} />
      <StyledSpacing />
    </>
  );
};

export default StyleguideChips;
