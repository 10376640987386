import React, {useReducer, useEffect, useContext} from 'react';
import {Typography, makeStyles, Theme} from '@material-ui/core';
import {useLocation, Link} from 'react-router-dom';
import {Location} from 'history';
import {IconNames, ValmetIcon, colors} from '@valmet-iop/ui-common';
import {unnest, filter, descend, prop, sortWith} from 'ramda';
import {useTranslation} from 'react-i18next';
import UserMenu from './UserMenu';
import {ApiUserInformationOutputDto} from '../../types/api';
import {AppContext} from '.';
const {white, grey3, blue3, grey13} = colors;

interface OwnProps {
  isExpanded: boolean;
  isMobile: boolean;
  onExpandToggleButtonClick: () => void;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    maxWidth: props => (props.isExpanded ? '320px' : '60px'),
    minWidth: props => (props.isExpanded ? '230px' : '60px'),
    height: 'calc(100vh - 70px)',
  },
  collapsed: {
    backgroundColor: grey3,
    height: '100%',
  },
  expanded: {
    backgroundColor: grey3,
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto 1fr',
  },
  content: {
    overflowY: 'auto',
  },
  userMenu: {
    marginTop: theme.spacing(4),
    color: white,
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface MenuItemDatum {
  translationKey: string;
  href?: string;
  icon?: IconNames;
  requiresServiceRole?: boolean;
  children?: {
    [key: string]: MenuItemDatum;
  };
}

const MenuData: Record<string, MenuItemDatum> = {
  organizations: {
    translationKey: 'navigation.organizations',
    icon: 'users',
    href: '/organizations',
    requiresServiceRole: true,
  },
  users: {
    translationKey: 'navigation.users',
    icon: 'menu',
    href: '/users',
  },
  roles: {
    translationKey: 'navigation.roles',
    icon: 'menu',
    href: '/roles',
    requiresServiceRole: true,
  },
  permissionGroups: {
    translationKey: 'navigation.permissionGroups',
    icon: 'menu',
    href: '/permissiongroups',
    requiresServiceRole: true,
  },
  scopes: {
    translationKey: 'navigation.scopes',
    icon: 'menu',
    href: '/scopes',
  },
  applications: {
    translationKey: 'navigation.applications',
    icon: 'menu',
    href: '/applications',
    requiresServiceRole: true,
  },
};
const getInitialMenuState = (location: Location<unknown>): MenuState => {
  const getMenuState = (
    itemKey: string,
    item: MenuItemDatum,
    parentKey?: string,
  ): MenuItemsState => {
    const key = !parentKey ? itemKey : `${parentKey}.${itemKey}`;
    const childState = unnest(
      item.children !== undefined
        ? Object.keys(item.children).map(childKey =>
            getMenuState(
              childKey,
              item.children !== undefined
                ? // eslint-disable-next-line security/detect-object-injection
                  item.children[childKey]
                : {translationKey: ''},
              key,
            ),
          )
        : [],
    );
    const isActive =
      location.pathname === item.href || childState.some(c => c.isActive);
    return [
      {
        key,
        href: item.href,
        isActive,
        isExpanded: isActive,
        isHidden: !!item.requiresServiceRole,
        requiresServiceRole: item.requiresServiceRole,
      },
      ...childState,
    ];
  };

  const items = unnest(
    // eslint-disable-next-line security/detect-object-injection
    Object.keys(MenuData).map(key => getMenuState(key, MenuData[key])),
  );
  const activeItems = sortWith(
    [descend<MenuItemState>(prop('key'))],
    filter(x => x.isActive, items),
  );

  return {
    items,
    activeItemHref: activeItems.length > 0 ? activeItems[0].href : undefined,
  };
};

interface MenuItemState {
  key: string;
  href?: string;
  isActive: boolean;
  isExpanded: boolean;
  isHidden: boolean;
  requiresServiceRole?: boolean;
}

type MenuItemsState = readonly MenuItemState[];

interface MenuState {
  items: MenuItemsState;
  activeItemHref?: string;
}

type MenuAction =
  | {
      type: 'EXPAND';
      itemKey: string;
    }
  | {
      type: 'COLLAPSE';
      itemKey: string;
    }
  | {
      type: 'ACTIVATE';
      itemHref: string;
    }
  | {
      type: 'SET_USER_INFO';
      user: ApiUserInformationOutputDto;
    };

const menuReducer = (state: MenuState, action: MenuAction): MenuState => {
  let itemToCollapse: MenuItemState | undefined;
  let itemToActivate: MenuItemState | undefined;
  switch (action.type) {
    case 'EXPAND':
      return {
        ...state,
        items: state.items.map(i => ({
          ...i,
          isExpanded: i.isExpanded || i.key === action.itemKey,
        })),
      };
    case 'COLLAPSE':
      itemToCollapse = state.items.find(i => i.key === action.itemKey);
      if (itemToCollapse === undefined) {
        return state;
      }

      return {
        ...state,
        items: state.items.map(i => ({
          ...i,
          isExpanded:
            i.isExpanded && !i.key.startsWith(itemToCollapse?.key ?? ''),
        })),
      };
    case 'ACTIVATE':
      itemToActivate = state.items.find(i => i.href === action.itemHref);
      if (itemToActivate === undefined) {
        return {
          items: state.items.map(i => ({...i, isActive: false})),
          activeItemHref: undefined,
        };
      }

      return {
        items: state.items.map(i => {
          // We need to activate the item in question and all of its parents
          // We also expand all of them and make sure to keep existing expanded ones expanded as well
          const targetKeyIsPrefixOfThisItemKey =
            itemToActivate?.key.startsWith(i.key) ?? false;
          return {
            ...i,
            isActive: targetKeyIsPrefixOfThisItemKey,
            isExpanded: i.isExpanded || targetKeyIsPrefixOfThisItemKey,
          };
        }),
        activeItemHref: itemToActivate.href,
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        items: state.items.map(i => {
          return {
            ...i,
            isHidden:
              !!i.requiresServiceRole &&
              action.user.serviceRole !== 'ServiceAdmin' &&
              action.user.serviceRole !== 'DeliveryProjectLeadEngineer',
          };
        }),
      };
  }
};

const SideNav = (props: Props) => {
  const classes = useStyles(props);
  const location = useLocation();
  const [menuState, menuDispatch] = useReducer(
    menuReducer,
    getInitialMenuState(location),
  );
  const {t} = useTranslation();
  useEffect(() => {
    if (menuState.activeItemHref !== location.pathname) {
      menuDispatch({
        type: 'ACTIVATE',
        itemHref: location.pathname,
      });
    }
  }, [menuState.activeItemHref, location.pathname, menuDispatch]);
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (appContext.user !== null) {
      menuDispatch({
        type: 'SET_USER_INFO',
        user: appContext.user,
      });
    }
  }, [appContext.user, menuDispatch]);

  const userName = appContext.user?.displayName ?? '';

  const getMenuItem = (
    itemKey: string,
    item: MenuItemDatum,
    level = 1,
    parentKey?: string,
  ): JSX.Element | null => {
    const key = !parentKey ? itemKey : `${parentKey}.${itemKey}`;
    const stateItem = menuState.items.find(x => x.key === key);
    if (!stateItem) {
      return null;
    }

    return (
      <MenuItem
        key={key}
        text={t(item.translationKey)}
        active={stateItem.isActive}
        isExpanded={stateItem.isExpanded}
        level={level}
        toggleItemExpanded={() =>
          menuDispatch({
            type: stateItem.isExpanded ? 'COLLAPSE' : 'EXPAND',
            itemKey: key,
          })
        }
        toggleNavExpanded={props.onExpandToggleButtonClick}
        href={item.href}
        icon={item.icon}
        isHidden={stateItem.isHidden}
        isMobile={props.isMobile}
      >
        {item.children !== undefined
          ? Object.keys(item.children).map(childKey =>
              getMenuItem(
                childKey,
                item.children !== undefined
                  ? // eslint-disable-next-line security/detect-object-injection
                    item.children[childKey]
                  : {translationKey: ''},
                level + 1,
                key,
              ),
            )
          : undefined}
      </MenuItem>
    );
  };

  return (
    <div className={classes.root}>
      {!props.isExpanded && (
        <div className={classes.collapsed}>
          <ExpandToggleButton
            isExpanded={false}
            onClick={props.onExpandToggleButtonClick}
          />
        </div>
      )}
      {props.isExpanded && (
        <div className={classes.expanded}>
          <ExpandToggleButton
            isExpanded
            onClick={props.onExpandToggleButtonClick}
          />
          <div className={classes.content}>
            <SideNavTitle text={t('navigation.title')} />
            {/* eslint-disable-next-line security/detect-object-injection */}
            {Object.keys(MenuData).map(key => getMenuItem(key, MenuData[key]))}
            {props.isMobile && (
              <div className={classes.userMenu}>
                <UserMenu userName={userName} clickableAreaHeight="46px" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNav;

interface ExpandToggleButtonProps {
  isExpanded: boolean;
  onClick: () => void;
}

const useExpandToggleButtonStyles = makeStyles<Theme, ExpandToggleButtonProps>(
  () => ({
    root: {
      backgroundColor: props => (props.isExpanded ? '#1B1B1B' : grey3),
      height: '60px',
      overflowY: 'hidden',
    },
    button: {
      color: white,
      cursor: 'pointer',
      paddingTop: '22px',
      paddingLeft: '20px',
      paddingBottom: '17px',
    },
  }),
);

const ExpandToggleButton = (props: ExpandToggleButtonProps) => {
  const classes = useExpandToggleButtonStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.button} onClick={props.onClick}>
        <ValmetIcon
          size="medium"
          icon={props.isExpanded ? 'arrow-left' : 'arrow-right'}
        />
      </div>
    </div>
  );
};

const useTitleStyles = makeStyles<Theme>(() => ({
  root: {
    paddingTop: '21px',
    paddingLeft: '20px',
    backgroundColor: '#282828',
    height: '60px',
  },
  text: {
    color: white,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '17px',
  },
}));

const SideNavTitle = ({text}: {text: string}) => {
  const classes = useTitleStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.text} variant="h3">
        {text}
      </Typography>
    </div>
  );
};

interface MenuItemProps {
  text: string;
  level: number;
  active: boolean;
  isExpanded: boolean;
  isMobile: boolean;
  isHidden: boolean;
  href?: string;
  icon?: IconNames;
  children?: React.ReactNode;
  toggleItemExpanded: () => void;
  toggleNavExpanded: () => void;
}

const useMenuItemStyles = makeStyles<Theme, MenuItemProps>(theme => ({
  root: {
    paddingLeft: props => theme.spacing(props.level),
    paddingRight: theme.spacing(1),
    backgroundColor: props => (props.active ? '#414142' : grey3),
    display: props => (props.isHidden ? 'none' : 'flex'),
    alignItems: 'center',
    height: '28px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: props => (props.active ? blue3 : grey13),
    '&:hover': {
      color: props => (props.active ? blue3 : white),
    },
  },
  expandChevron: {
    color: '#DEDEDE',
    visibility: props =>
      props.children === undefined ||
      (Array.isArray(props.children) && props.children.length === 0)
        ? 'hidden'
        : 'visible',
    paddingRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    color: props => (props.active ? blue3 : 'inherit'),
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const MenuItem = (props: MenuItemProps) => {
  const classes = useMenuItemStyles(props);

  const handleChevronClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    props.toggleItemExpanded();
    e.stopPropagation();
  };
  const handleItemClick = () => {
    props.toggleItemExpanded();
  };
  const handleLinkClick = () => {
    if (props.isMobile) {
      props.toggleNavExpanded();
    }
  };

  const getContainerChildren = () => {
    return (
      <>
        <span className={classes.expandChevron} onClick={handleChevronClick}>
          <ValmetIcon icon={props.isExpanded ? 'arrow-up' : 'arrow-down'} />
        </span>
        <span className={classes.icon}>
          <ValmetIcon icon={props.icon ?? 'menu'} />
        </span>
        <Typography variant="body1" className={classes.text}>
          {props.text}
        </Typography>
      </>
    );
  };

  return (
    <>
      {props.href && (
        <Link
          className={classes.root}
          to={props.href}
          onClick={handleLinkClick}
        >
          {getContainerChildren()}
        </Link>
      )}
      {!props.href && (
        <div className={classes.root} onClick={handleItemClick}>
          {getContainerChildren()}
        </div>
      )}
      {props.isExpanded && props.children}
    </>
  );
};
