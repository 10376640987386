import React, {useState, useMemo} from 'react';
import {TablePageLayout, Column, Sort, RowAction} from '@valmet-iop/ui-common';
import faker from 'faker';
import {
  range,
  prop,
  descend,
  ascend,
  sortWith,
  drop,
  take,
  cond,
  equals,
  always,
  T,
} from 'ramda';
import {useTheme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const PageSizes = [10, 20, 30];
type RowItem = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
};
faker.seed(0);
const StaticData: readonly RowItem[] = range(0, 225).map(() => ({
  id: faker.random.uuid(),
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  jobTitle: faker.name.jobTitle(),
  phoneNumber: faker.phone.phoneNumber(),
}));

const useStaticData = ({
  filterText,
  page,
  pageSize,
  sort,
}: {
  filterText: string;
  page: number;
  pageSize: number;
  sort: Sort;
}) => {
  const filteredData = useMemo(() => {
    return StaticData.filter(
      x =>
        !filterText ||
        x.firstName.includes(filterText) ||
        x.lastName.includes(filterText) ||
        x.jobTitle.includes(filterText) ||
        x.phoneNumber.includes(filterText),
    );
  }, [filterText]);

  const sortedData = useMemo(() => {
    const sortProp = cond([
      [equals(0), always('firstName')],
      [equals(1), always('lastName')],
      [equals(2), always('jobTitle')],
      [equals(3), always('phoneNumber')],
      [T, always('firstName')],
    ]);
    const propSelector = prop(sortProp(sort.columnIndex));
    const directionFunc = sort.direction === 'descending' ? descend : ascend;

    return sortWith([directionFunc(propSelector)], filteredData);
  }, [filteredData, sort.columnIndex, sort.direction]);

  const pageOfData = useMemo(() => {
    return take(pageSize, drop(page * pageSize, sortedData));
  }, [sortedData, page, pageSize]);

  return {
    data: pageOfData,
    totalResults: filteredData.length,
  };
};

const StyleguideTablePageLayout = () => {
  useTranslation('styleguide');
  const theme = useTheme();
  const [filterText, setFilterText] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PageSizes[0]);
  const initialSort: Sort = {columnIndex: 0, direction: 'ascending'};
  const [sort, setSort] = useState(initialSort);

  // In actual use this would need to be an API effect etc.
  const {data, totalResults} = useStaticData({
    filterText,
    page,
    pageSize,
    sort,
  });

  const columns: Column[] = [
    {
      translationKey: 'styleguide:tablePageLayout.columns.firstName',
      dataKey: 'firstName',
      isSortable: true,
    },
    {
      translationKey: 'styleguide:tablePageLayout.columns.lastName',
      dataKey: 'lastName',
      isSortable: true,
    },
    {
      translationKey: 'styleguide:tablePageLayout.columns.jobTitle',
      dataKey: 'jobTitle',
      isSortable: true,
    },
    {
      translationKey: 'styleguide:tablePageLayout.columns.phoneNumber',
      dataKey: 'phoneNumber',
      isSortable: true,
    },
  ];
  const actions: RowAction[] = [
    {
      icon: 'edit',
      onClick: rowId => alert('Edit row id: ' + rowId),
      translationKey: 'styleguide:tables.actions.edit',
    },
    {
      icon: 'delete',
      translationKey: 'styleguide:tables.actions.delete',
      onClick: rowId => alert('Delete row id: ' + rowId),
      color: theme.palette.error.main, // You can also set different colors if you want for the items
    },
  ];

  const onFilterTextChange = (text: string) => {
    setPage(0);
    setFilterText(text);
  };
  const onPageChange = (updatePage: number) => {
    setPage(updatePage);
  };
  const onPageSizeChange = (updatedPageSize: number) => {
    setPage(0);
    setPageSize(updatedPageSize);
  };
  const onSortChange = ({columnIndex, direction}: Sort) => {
    setSort({columnIndex, direction});
  };

  return (
    <TablePageLayout
      titleTranslationKey="styleguide:tablePageLayout.pageTitle"
      createButtonTextTranslationKey="styleguide:tablePageLayout.create"
      onCreateClick={() => alert('Create clicked')}
      filter={{
        filterText,
        onFilterTextChange,
      }}
      pagination={{
        page,
        pageSize,
        pageSizes: PageSizes,
        totalResults,
        onPageChange,
        onPageSizeChange,
      }}
      table={{
        initialSort,
        onSortChange,
        data,
        actions,
        columns,
      }}
    />
  );
};

export default StyleguideTablePageLayout;
