import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
  ApiInviteUserInputDto,
  ApiRoleAssignmentInputDto,
  ApiOrganizationUserOutputDto,
  ApiRedirectTargetApplication,
  ApiProblemDetails,
} from '../../../types/api';
import ErrorType from '../../../types/ErrorType';
import {ApiRoleLookupOutputDto} from '../../../types/api/Lookup/LookupOrganizationRolesForAssignmentApi';
import {
  ContextPanel,
  ContextPanelContentSection,
  FormikValmetTextInput,
  ValmetSelect,
  FormCancelConfirmDialog,
  FormikValmetRadioButtons,
  FormikValmetTextArea,
  errorNotification,
  LoadingScreen,
} from '@valmet-iop/ui-common';
import {Option as ValmetSelectOption} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {Formik} from 'formik';
import {apiGetRequest, apiPostRequest} from '../../../services/api/apiService';
import * as Yup from 'yup';
import {AppContext} from '../../Layout';
import {sortBy} from 'ramda';
import {useTranslation} from 'react-i18next';
import {
  getOrganizationRoleFromCombinedOption,
  getServiceRoleFromCombinedOption,
  OrganizationAndServiceRoleValue,
} from './utils';
import UserRoleEditor from './UserRoleEditor';
import {TFunction} from 'i18next';
import {RoleWithScopes} from './FormDataTypes';
import RoleRadioButtons from './RoleRadioButtons';
import {makeStyles, Theme} from '@material-ui/core';

interface FormData {
  selectedOrganization: {text: string; value: string} | null;
  name: string;
  email: string;
  invitationMessage: string;
  role: OrganizationAndServiceRoleValue;
  redirectToApplication: ApiRedirectTargetApplication;
  rolesWithScopes: readonly RoleWithScopes[];
}

const emptyOptions: {
  text: string;
  value: string;
}[] = [];

const inviteUser = async (
  organizationId: string,
  model: ApiInviteUserInputDto,
) => {
  const result = await apiPostRequest<
    ApiInviteUserInputDto,
    ApiOrganizationUserOutputDto
  >(`api/organizations/${organizationId}/users`, model);
  return result;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('users:create.errors.nameRequired')
    .max(128, 'users:create.errors.nameMaxLength'),
  email: Yup.string()
    .required('users:create.errors.emailRequired')
    .email('users:create.errors.invalidEmail')
    .max(128, 'users:create.errors.emailMaxLength'),
  selectedOrganization: Yup.object()
    .nullable()
    .required('users:create.errors.organizationRequired'),
  invitationMessage: Yup.string()
    .optional()
    .max(1000, 'users:create.errors.invitationMessageMaxLength'),
  rolesWithScopes: Yup.array().of(
    Yup.object().shape({
      role: Yup.object<ValmetSelectOption>()
        .nullable()
        .required('users:create.errors.roleRequired'),
      scopes: Yup.array<ValmetSelectOption>().nullable(),
    }),
  ),
});

const createInvitePayload = (formData: FormData): ApiInviteUserInputDto => {
  const selectedRoles: ApiRoleAssignmentInputDto[] = [];

  for (const item of formData.rolesWithScopes) {
    if (item.role === null) {
      continue;
    }
    selectedRoles.push({
      roleId: item.role.value,
      scopes: item.scopes.map(s => s.value),
      expiresAt: null,
    });
  }

  return {
    displayName: formData.name,
    email: formData.email,
    invitationMessage: formData.invitationMessage,
    organizationRole: getOrganizationRoleFromCombinedOption(formData.role),
    serviceRole: getServiceRoleFromCombinedOption(formData.role),
    redirectToApplication: formData.redirectToApplication,
    roleAssignments: selectedRoles,
  };
};

const useLookupData = (
  isSidebarOpen: boolean,
  selectedOrganizationId: string | undefined,
  t: TFunction,
) => {
  const [data, setData] = useState<{
    isLoaded: boolean;
    roleOptions: ValmetSelectOption[];
  }>({
    isLoaded: false,
    roleOptions: emptyOptions,
  });
  useEffect(() => {
    if (isSidebarOpen && !!selectedOrganizationId) {
      const getRoles = async (organizationId: string) => {
        try {
          const result = await apiGetRequest<ApiRoleLookupOutputDto[]>(
            `api/lookup/organization/roleAssignment/${organizationId}/roles`,
          );
          return result;
        } catch {
          errorNotification(t('users:create.errors.lookupRolesFailed'));
          return [];
        }
      };

      // Reset the data
      setData({
        isLoaded: false,
        roleOptions: [],
      });
      getRoles(selectedOrganizationId).then(res => {
        setData({
          isLoaded: true,
          roleOptions: res.map(r => ({
            text: r.name,
            value: r.roleId,
          })),
        });
      });
    }
  }, [isSidebarOpen, selectedOrganizationId, t]);
  return data;
};

const useRedirectToApplicationOptions = () => {
  const {t} = useTranslation('users');
  return useMemo(() => {
    return [
      {
        text: t('users:redirectToApplication.azureIop'),
        value: 'AzureIop',
      },
      {
        text: t('users:redirectToApplication.awsIop'),
        value: 'AwsIop',
      },
    ];
  }, [t]);
};

const useEditorStyles = makeStyles<Theme, {isLoading: boolean}>(theme => ({
  spinner: {
    display: props => (props.isLoading ? 'block' : 'none'),
  },
  editor: {
    display: props => (props.isLoading ? 'none' : 'block'),
  },
}));

const CreateSidebar = (props: {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (model: ApiOrganizationUserOutputDto) => void;
}) => {
  const {t} = useTranslation(['translation', 'users']);
  const appContext = useContext(AppContext);
  const [isCancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
    false,
  );
  const [
    contextPanelMenuContainerEl,
    setContextPanelMenuContainerEl,
  ] = useState<Element | null>(null);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const userHasServiceRole = !!appContext.user?.serviceRole;
  const userIsValmetAdmin = appContext.user?.serviceRole === 'ServiceAdmin';
  const userOrganizationId = appContext.user?.organizationId ?? '';
  const userOrganizationName = appContext.user?.organizationName ?? '';
  const userAdministratedOrganizations = useMemo(
    () => appContext.user?.administratedOrganizations ?? [],
    [appContext.user],
  );
  // We memo the options because the Select requires referential equality for options
  const ownOrganizationOption = useMemo(
    () => ({
      text: userOrganizationName,
      value: userOrganizationId,
    }),
    [userOrganizationId, userOrganizationName],
  );

  const defaultValues: FormData = useMemo(() => {
    return {
      selectedOrganization: userHasServiceRole ? null : ownOrganizationOption,
      name: '',
      email: '',
      invitationMessage: '',
      role: 'User',
      redirectToApplication: 'AzureIop',
      rolesWithScopes: [],
    };
  }, [userHasServiceRole, ownOrganizationOption]);

  const organizationOptions = useMemo(() => {
    // Valmet admins can choose from their administrated organizations
    const options = userHasServiceRole
      ? userAdministratedOrganizations.map(o => ({
          text: o.organizationName,
          value: o.organizationId,
        }))
      : [];
    // Valmet admins can choose their own organization as well
    if (userIsValmetAdmin) {
      options.push(ownOrganizationOption);
    }

    return sortBy(o => o.text.toLowerCase(), options);
  }, [
    userIsValmetAdmin,
    userHasServiceRole,
    userAdministratedOrganizations,
    ownOrganizationOption,
  ]);

  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<ValmetSelectOption | null>(null);

  const {isOpen} = props;
  const {roleOptions} = useLookupData(isOpen, selectedOrganization?.value, t);

  const redirectToApplicationOptions = useRedirectToApplicationOptions();

  const classes = useEditorStyles({
    isLoading: saveInProgress,
  });

  if (appContext.user === null) {
    return null;
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={async (values, {setSubmitting, setFieldError, resetForm}) => {
        const organizationId = values.selectedOrganization?.value ?? '';

        if (!organizationId) {
          setFieldError(
            'selectedOrganization',
            'users:create.errors.organizationRequired',
          );
          return;
        }

        setSubmitting(true);
        setSaveInProgress(true);
        try {
          const result = await inviteUser(
            organizationId,
            createInvitePayload(values),
          );
          props.onCreate(result);
          resetForm();
          setSelectedOrganization(null);
        } catch (e) {
          if ((e as ApiProblemDetails)?.status === 409) {
            setFieldError('name', 'users:create.errors.conflict');
          }

          let errorTranslationKey = 'users:create.errors.createFailed';
          const errorType = (e as ApiProblemDetails)?.type;
          if (errorType === ErrorType.inviteUserAlreadyExists) {
            errorTranslationKey =
              'users:create.errors.userWithEmailAlreadyExists';
          } else if (errorType === ErrorType.inviteUserEmailSendFailed) {
            errorTranslationKey = 'users:create.errors.emailSendFailed';
          } else if (errorType === ErrorType.inviteUserInvalidEmail) {
            errorTranslationKey = 'users:create.errors.invalidEmail';
            setFieldError('email', 'users:create.errors.invalidEmail');
          } else if (errorType === ErrorType.inviteUserNotAllowedEmailDomain) {
            errorTranslationKey = 'users:create.errors.notAllowedDomain';
            setFieldError('email', 'users:create.errors.notAllowedDomain');
          }

          errorNotification(t(errorTranslationKey));
        } finally {
          setSubmitting(false);
          setSaveInProgress(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        errors,
        dirty,
        touched,
        handleSubmit,
        resetForm,
        setFieldValue,
        setFieldTouched,
      }) => {
        const onCloseClick = () => {
          if (dirty && !isSubmitting) {
            setCancelConfirmDialogOpen(true);
            return;
          }

          resetForm();
          props.onClose();
        };
        const onCancelConfirmDialogResult = (confirmed: boolean) => {
          setCancelConfirmDialogOpen(false);
          if (confirmed) {
            resetForm();
            setSelectedOrganization(null);
            props.onClose();
          }
        };
        return (
          <>
            <ContextPanel
              open={props.isOpen}
              size="medium"
              titleTranslationKey="users:create.title"
              bottomActions={[
                {
                  translationKey: 'users:create.buttons.save',
                  style: 'primary',
                  isDisabled:
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    values === defaultValues,
                  onClick: handleSubmit,
                },
                {
                  translationKey: 'users:create.buttons.cancel',
                  style: 'ghost',
                  isDisabled: isSubmitting,
                  onClick: onCloseClick,
                },
              ]}
              onCloseClick={onCloseClick}
              paperRef={setContextPanelMenuContainerEl}
            >
              <div className={classes.spinner}>
                <LoadingScreen />
              </div>
              <div className={classes.editor}>
                <ContextPanelContentSection headerTranslationKey="users:create.general.header">
                  {userHasServiceRole && (
                    <ValmetSelect
                      id="selectedOrganization"
                      labelTranslationKey="users:create.general.selectedOrganization"
                      options={organizationOptions}
                      isRequired
                      value={selectedOrganization}
                      onChange={option => {
                        if (option === null) {
                          return;
                        }
                        setSelectedOrganization(option);
                        setFieldValue('role', 'User');
                        setFieldValue('selectedOrganization', option);
                        setFieldTouched('selectedOrganization');
                        setFieldValue('rolesWithScopes', []);
                        setFieldTouched('rolesWithScopes');
                      }}
                      onBlur={() => setFieldTouched('selectedOrganization')}
                      errorMessageTranslationKey={
                        touched.selectedOrganization
                          ? errors.selectedOrganization
                          : undefined
                      }
                    />
                  )}
                  <FormikValmetTextInput
                    formProperty="name"
                    id="name"
                    labelTranslationKey="users:create.general.name"
                    isRequired
                  />
                  <FormikValmetTextInput
                    formProperty="email"
                    id="email"
                    labelTranslationKey="users:create.general.email"
                    isRequired
                  />
                  <RoleRadioButtons
                    formProperty="role"
                    labelTranslationKey="users:create.general.role"
                    isUserInAdminOrganization={
                      selectedOrganization?.value ===
                      appContext.user?.adminOrganizationId
                    }
                  />
                  <FormikValmetRadioButtons
                    formProperty="redirectToApplication"
                    labelTranslationKey="users:create.general.redirectToApplication"
                    options={redirectToApplicationOptions}
                  />
                  <FormikValmetTextArea
                    isOptional
                    rows={5}
                    formProperty="invitationMessage"
                    id="invitationMessage"
                    labelTranslationKey="users:create.general.invitationMessage"
                  />
                </ContextPanelContentSection>
                <ContextPanelContentSection headerTranslationKey="users:create.rolesAndPermissions.header">
                  <UserRoleEditor
                    userOrganizationId={selectedOrganization?.value ?? ''}
                    roleOptions={roleOptions}
                    multiSelectMenuContainerEl={contextPanelMenuContainerEl}
                    loadAllComplete={() => {}}
                  />
                </ContextPanelContentSection>
              </div>
            </ContextPanel>
            <FormCancelConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              onResult={onCancelConfirmDialogResult}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default CreateSidebar;
