import 'react-toastify/dist/ReactToastify.css';

import PrivateRoute from '../Common/PrivateRoute';
import Home from '../Pages';
import AadCallback from '../Pages/AadCallback';
import Login from '../Pages/Login';
import PageNotFound from '../Pages/PageNotFound';
import Styleguide from '../Pages/Styleguide';
import StyleguideTablePageLayout from '../Pages/Styleguide/StyleguideTablePageLayout';
import OrganizationsList from '../Pages/Organizations/List';
import PermissionGroupsList from '../Pages/PermissionGroups/List';
import UsersList from '../Pages/Users/List';
import ScopesList from '../Pages/Scopes/List';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {
  getIsAppInitialized,
  getUserIsAuthenticated,
} from '../../redux/reducers';
import React, {useState, useContext, useEffect} from 'react';
import {useQuery} from 'react-query';
import {shallowEqual, useSelector} from 'react-redux';
import {ToastContainer} from '@valmet-iop/ui-common';
import {IState} from '../../types/types';

import TopHeader from './TopHeader';
import SideNav from './SideNav';
import {Hidden, useMediaQuery} from '@material-ui/core';
import {ApiUserInformationOutputDto} from '../../types/api';
import RolesList from '../Pages/Roles/List';
import {apiGetRequest} from '../../services/api/apiService';

const useStyles = makeStyles<Theme, {}>(theme => ({
  '@global': {
    body: {
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  },
  sideNavContentContainer: {
    display: 'flex',
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 70px)',
    '& > .MuiCard-root > .MuiCardContent-root': {
      padding: theme.spacing(8, 4, 4, 4),
    },
  },
}));

interface AppContextModel {
  adminOrganizationTypeId: string;
  adminOrganizationId: string;
  user: ApiUserInformationOutputDto | null;
  userInfoLoadStatus: 'NOT_STARTED' | 'LOADING' | 'LOADED' | 'LOAD_FAILED';
  userInfoLoadError: any;
  refreshUserInfo: () => void;
}

export const AppContext = React.createContext<AppContextModel>({
  adminOrganizationTypeId: '7fd0d737-057e-4f98-b2df-f3a0bfe071e1',
  adminOrganizationId: '87785db3-e083-4cc3-9dd1-6270377064e2',
  user: null,
  userInfoLoadStatus: 'NOT_STARTED',
  userInfoLoadError: null,
  refreshUserInfo: () => undefined,
});

const Layout = (props: {}) => {
  const classes = useStyles(props);
  const applicationReady = useSelector<IState, boolean>(
    getIsAppInitialized,
    shallowEqual,
  );

  const {
    data: userInfo,
    refetch: refetchUserInfo,
    isSuccess: isUserInfoAvailable,
    isLoading: isUserInfoLoading,
    isError: isUserInfoError,
    error: userInfoError,
  } = useQuery(
    ['userInfo'],
    () => apiGetRequest<ApiUserInformationOutputDto>('api/users/me'),
    {enabled: applicationReady, retry: false},
  );

  // On mobile the side nav is hidden by default, on desktop visible by default
  // We track these separately and use a media query hook to decide which one to use and update
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [mobileNavExpanded, setMobileNavExpanded] = useState(false);
  const [desktopNavExpanded, setDesktopNavExpanded] = useState(true);
  const onSideNavExpandToggle = () => {
    if (isMobile) {
      setMobileNavExpanded(!mobileNavExpanded);
    } else {
      setDesktopNavExpanded(!desktopNavExpanded);
    }
  };
  const sideNavExpanded = isMobile ? mobileNavExpanded : desktopNavExpanded;

  useEffect(() => {
    document.title = 'Valmet IOP IAM';
  }, []);

  return (
    <>
      <AppContext.Provider
        value={{
          adminOrganizationTypeId: '7fd0d737-057e-4f98-b2df-f3a0bfe071e1',
          adminOrganizationId: '87785db3-e083-4cc3-9dd1-6270377064e2',
          user: isUserInfoAvailable && userInfo !== undefined ? userInfo : null,
          userInfoLoadStatus: !!userInfo?.organizationId
            ? 'LOADED'
            : isUserInfoLoading
            ? 'LOADING'
            : isUserInfoError
            ? 'LOAD_FAILED'
            : 'NOT_STARTED',
          userInfoLoadError: userInfoError,
          refreshUserInfo: refetchUserInfo,
        }}
      >
        <ToastContainer />

        <TopHeader onMenuButtonClick={onSideNavExpandToggle} />

        <div className={classes.sideNavContentContainer}>
          {/* The router is necessary here so the nav can know what route is active */}
          <Router>
            <SideNavContainer
              isExpanded={sideNavExpanded}
              onSideNavExpandToggle={onSideNavExpandToggle}
            />
            {applicationReady && (
              <main className={classes.contentContainer}>
                <Switch>
                  <PrivateRoute comp={Home} path="/" exact />
                  <PrivateRoute
                    comp={StyleguideTablePageLayout}
                    path="/styleguide/tablelayout"
                    exact
                  />
                  <PrivateRoute
                    comp={OrganizationsList}
                    path="/organizations"
                    exact
                  />
                  <PrivateRoute
                    comp={PermissionGroupsList}
                    path="/permissiongroups"
                  />
                  <PrivateRoute comp={RolesList} path="/roles" exact />
                  <PrivateRoute comp={UsersList} path="/users" exact />
                  <PrivateRoute comp={ScopesList} path="/scopes" exact />
                  <PrivateRoute comp={Styleguide} path="/styleguide" exact />
                  <Route path="/login" exact>
                    <Login />
                  </Route>
                  <Route path="/aad-callback" exact>
                    <AadCallback />
                  </Route>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </main>
            )}
          </Router>
        </div>
      </AppContext.Provider>
    </>
  );
};

export default Layout;

const useSideNavContainerStyles = makeStyles<Theme, {}>(() => ({
  desktopSideNav: {},
  mobileSideNav: {
    position: 'absolute',
    zIndex: 1,
    width: '100vw',
  },
}));

const SideNavContainer = ({
  isExpanded,
  onSideNavExpandToggle,
}: {
  isExpanded: boolean;
  onSideNavExpandToggle: () => void;
}) => {
  const isAuthenticated = useSelector<IState, boolean>(
    getUserIsAuthenticated,
    shallowEqual,
  );
  const classes = useSideNavContainerStyles({});
  const {userInfoLoadStatus} = useContext(AppContext);
  if (!isAuthenticated || userInfoLoadStatus !== 'LOADED') {
    return null;
  }

  return (
    <>
      <Hidden smDown>
        <div className={classes.desktopSideNav}>
          <SideNav
            isMobile={false}
            isExpanded={isExpanded}
            onExpandToggleButtonClick={onSideNavExpandToggle}
          />
        </div>
      </Hidden>
      <Hidden mdUp>
        {isExpanded && (
          <div className={classes.mobileSideNav}>
            <SideNav
              isMobile
              isExpanded
              onExpandToggleButtonClick={onSideNavExpandToggle}
            />
          </div>
        )}
      </Hidden>
    </>
  );
};
