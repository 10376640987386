import {Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {StyledSpacing, Pagination} from '@valmet-iop/ui-common';
import {partial} from 'ramda';

const PageSizes = [10, 20, 30];

const StyleguidePagination = () => {
  const [page, setPage] = useState({
    normal: 0,
    short: 0,
  });
  const [pageSize, setPageSize] = useState(10);

  // Normally you would get this from the API response
  const normalTotalResults = 245;
  const shortTotalResults = 22;

  const onPageChange = (component: 'normal' | 'short', updatedPage: number) => {
    setPage({
      ...page,
      [component]: updatedPage,
    });
  };
  const onPageSizeChange = (updatedPageSize: number) => {
    setPage({
      normal: 0,
      short: 0,
    });
    setPageSize(updatedPageSize);
  };

  return (
    <>
      <Typography variant="body1">
        Use the Pagination component to allow the user to navigate through pages
        of data. You need to tell it what the current page is (0-based), total
        count of results, the current page size, and the available page sizes.
        Two callbacks allow you to know when the page or page size changes. Note
        that page size changes might require resetting the page as well as the
        user could be out of range for valid pages.
      </Typography>
      <StyledSpacing />
      <Pagination
        page={page.normal}
        pageSize={pageSize}
        pageSizes={PageSizes}
        totalResults={normalTotalResults}
        onPageChange={partial(onPageChange, ['normal'])}
        onPageSizeChange={onPageSizeChange}
      />
      <StyledSpacing />
      <Typography variant="body1">
        Component supports any number of pages
      </Typography>
      <StyledSpacing />
      <Pagination
        page={page.short}
        pageSize={pageSize}
        pageSizes={PageSizes}
        totalResults={shortTotalResults}
        onPageChange={partial(onPageChange, ['short'])}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
};

export default StyleguidePagination;
