import {Divider, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {
  StyledSpacing,
  ValmetTextInput,
  ValmetTextArea,
  ValmetSelect,
  ValmetMultiSelect,
  ValmetRadioButtons,
  ValmetCheckbox,
  ValmetComplexMultiSelect,
} from '@valmet-iop/ui-common';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import faker from 'faker';
import {range, sortBy, update} from 'ramda';
import {useTranslation} from 'react-i18next';

faker.seed(0);
const SelectOptions = sortBy(
  x => x.text,
  range(0, 100)
    .map(() => ({
      text: faker.name.findName(),
      value: faker.random.uuid(),
    }))
    .concat([
      {
        text:
          'Test very long option long option long option long option long option long option long option',
        value: 'abc',
      },
      {
        text: 'Short 1',
        value: 'short1',
      },
      {
        text: 'Short 2',
        value: 'short2',
      },
    ]),
);

const StyleguideInputs = () => {
  useTranslation('styleguide');
  return (
    <div>
      <TextInputs />
      <Divider light />
      <TextArea />
      <Divider light />
      <SingleSelects />
      <Divider light />
      <MultiSelects />
      <Divider light />
      <ComplexMultiSelect />
      <Divider light />
      <RadioButtons />
      <Divider light />
      <Checkboxes />
    </div>
  );
};

export default StyleguideInputs;

const TextInputs = () => {
  const [textFieldValue, setTextFieldValue] = useState('Controlled value');
  const onTextFieldValueChange = (val: string) => {
    setTextFieldValue(val);
  };
  // Validator functions return either true (valid) or a translation key for an error message (invalid)
  const validateTextFieldValue = (value: string) => {
    const regex = new RegExp(/[^A]/);
    if (regex.test(value)) {
      return 'styleguide:inputs.uncontrolledText.errorMessage';
    }

    return true;
  };
  return (
    <>
      <Typography variant="body1">
        Use ValmetTextInput to collect text data from the user. It accepts
        translation keys for the label and help text. An id is required so that
        we can connect the label to the input. The component can be either
        controlled or uncontrolled. If you specify a value, then it is
        controlled.
      </Typography>
      <StyledSpacing />
      <ValmetTextInput
        id="uncontrolled-text"
        labelTranslationKey="styleguide:inputs.uncontrolledText.label"
        helpTextTranslationKey="styleguide:inputs.uncontrolledText.helpText"
        onChange={onTextFieldValueChange}
        validator={validateTextFieldValue}
      />
      <ValmetTextInput
        id="controlled-text-with-error"
        labelTranslationKey="styleguide:inputs.controlledTextWithError.label"
        onChange={onTextFieldValueChange}
        value={textFieldValue}
        validator={validateTextFieldValue}
      />
      <ValmetTextInput
        id="uncontrolled-required-text"
        labelTranslationKey="styleguide:inputs.uncontrolledRequiredText.label"
        onChange={onTextFieldValueChange}
        isRequired
      />
      <ValmetTextInput
        id="disabled-text"
        labelTranslationKey="styleguide:inputs.disabledText.label"
        onChange={onTextFieldValueChange}
        isDisabled
        value={textFieldValue}
      />
    </>
  );
};

const TextArea = () => {
  const [textAreaValue, setTextAreaValue] = useState(
    'Controlled value\nSecond line',
  );
  const onTextAreaValueChange = (val: string) => {
    setTextAreaValue(val);
  };
  // Validator functions return either true (valid) or a translation key for an error message (invalid)
  const validateTextAreaValue = (value: string) => {
    const maxLength = 32;
    if (value?.length > maxLength) {
      return 'styleguide:inputs.textArea.errorMessage';
    }

    return true;
  };
  return (
    <>
      <Typography variant="body1">
        ValmetTextArea is a variant of ValmetTextInput that allows the user to
        enter multiple lines of text.
      </Typography>
      <StyledSpacing />
      <ValmetTextArea
        id="uncontrolled-text-area"
        labelTranslationKey="styleguide:inputs.textArea.uncontrolled.label"
        helpTextTranslationKey="styleguide:inputs.textArea.uncontrolled.helpText"
        isRequired
        onChange={onTextAreaValueChange}
        validator={validateTextAreaValue}
        rows={4}
      />
      <StyledSpacing />
      <ValmetTextArea
        id="controlled-text-area"
        labelTranslationKey="styleguide:inputs.textArea.controlled.label"
        helpTextTranslationKey="styleguide:inputs.textArea.controlled.helpText"
        isOptional
        onChange={onTextAreaValueChange}
        validator={validateTextAreaValue}
        value={textAreaValue}
        rows={4}
      />
    </>
  );
};

const SingleSelects = () => {
  // Selects must always have a value if you make them controlled
  // You can put e.g. { text: '', value: '' } as a default option so nothing is selected
  const [selectValue, setSelectValue] = useState<Option | null>(null);

  const onSelectValueChange = (val: Option | null) => {
    setSelectValue(val);
  };

  // Validator functions return either true (valid) or a translation key for an error message (invalid)
  const validateRequiredSelectValue = (val: Option | null | undefined) => {
    return val !== undefined && val !== null
      ? true
      : 'styleguide:inputs.uncontrolledSelect.errorMessage';
  };
  return (
    <>
      <Typography variant="body1">
        Use ValmetSelect to allow the user to select from pre-defined options.
        Like the text field, it accepts translation keys for the label and help
        text. An id is required so that we can connect the label to the input.
        The component can be either controlled or uncontrolled. If you specify a
        value, then it is controlled.
      </Typography>
      <StyledSpacing />
      <ValmetSelect
        id="uncontrolled-required-select"
        labelTranslationKey="styleguide:inputs.uncontrolledSelect.label"
        helpTextTranslationKey="styleguide:inputs.uncontrolledSelect.helpText"
        options={SelectOptions}
        onChange={onSelectValueChange}
        isRequired
        validator={validateRequiredSelectValue}
      />
      <ValmetSelect
        id="controlled-select"
        labelTranslationKey="styleguide:inputs.controlledSelect.label"
        helpTextTranslationKey="styleguide:inputs.controlledSelect.helpText"
        options={SelectOptions}
        onChange={onSelectValueChange}
        value={selectValue}
      />
      <ValmetSelect
        id="disabled-select"
        labelTranslationKey="styleguide:inputs.disabledSelect.label"
        helpTextTranslationKey="styleguide:inputs.disabledSelect.helpText"
        options={SelectOptions}
        onChange={onSelectValueChange}
        value={selectValue}
        isDisabled
      />
    </>
  );
};

const MultiSelects = () => {
  const [selectValues, setSelectValues] = useState<
    {text: string; value: string}[]
  >([SelectOptions[3], SelectOptions[5]]);

  const onSelectValuesChange = (vals: Option[]) => {
    setSelectValues(vals);
  };
  const requireAtLeastThreeSelected = (vals: readonly Option[]) => {
    return vals.length >= 3
      ? true
      : 'styleguide:inputs.multiSelectAtLeastThree.errorText';
  };

  return (
    <>
      <Typography variant="body1">
        Use ValmetMultiSelect to allow the user to select multiple values from a
        set of options.
      </Typography>
      <StyledSpacing />
      <ValmetMultiSelect
        id="controlledMultiSelect"
        labelTranslationKey="styleguide:inputs.controlledMultiSelect.label"
        helpTextTranslationKey="styleguide:inputs.controlledMultiSelect.helpText"
        options={SelectOptions}
        values={selectValues}
        onChange={onSelectValuesChange}
      />
      <ValmetMultiSelect
        id="multiSelectAtLeastThree"
        labelTranslationKey="styleguide:inputs.multiSelectAtLeastThree.label"
        helpTextTranslationKey="styleguide:inputs.controlledMultiSelect.helpText"
        options={SelectOptions}
        values={selectValues}
        onChange={onSelectValuesChange}
        isRequired
        validator={requireAtLeastThreeSelected}
      />
      <ValmetMultiSelect
        id="disabledMultiSelect"
        labelTranslationKey="styleguide:inputs.disabledMultiSelect.label"
        helpTextTranslationKey="styleguide:inputs.disabledMultiSelect.helpText"
        options={SelectOptions}
        values={selectValues}
        onChange={onSelectValuesChange}
        isDisabled
      />
    </>
  );
};

const RadioButtons = () => {
  const [value, setValue] = useState('a');
  const options = [
    {
      text: 'Option A',
      value: 'a',
    },
    {
      text: 'Option B',
      value: 'b',
    },
  ];

  const onChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    updatedValue: string,
  ) => {
    setValue(updatedValue);
  };

  return (
    <>
      <Typography variant="body1">
        ValmetRadioButtons can be used to show a set of radio buttons. Used only
        when the number of choices is small and we want to show them all at
        once.
      </Typography>
      <StyledSpacing />
      <ValmetRadioButtons
        labelTranslationKey="styleguide:inputs.radios.label"
        name="testValue"
        onChange={onChange}
        options={options}
        value={value}
      />
      <Typography variant="body1">{`Current value: ${value}`}</Typography>
    </>
  );
};

const Checkboxes = () => {
  const [checkedStates, setCheckedStates] = useState([
    false,
    false,
    true,
    false,
    false,
  ]);

  const handleChange = (index: number) => (checked: boolean) => {
    setCheckedStates(update(index, checked, checkedStates));
  };

  return (
    <>
      <Typography variant="body1">
        Use ValmetCheckbox to collect a boolean value from the user. There is a
        normal and small variant.
      </Typography>
      <StyledSpacing />
      <ValmetCheckbox
        isChecked={checkedStates[0]}
        label="Basic checkbox"
        name="basic"
        onChange={handleChange(0)}
      />
      <ValmetCheckbox
        isDisabled
        isChecked={checkedStates[1]}
        label="Disabled checkbox"
        name="disabled"
        onChange={handleChange(1)}
      />
      <ValmetCheckbox
        isDisabled
        isChecked={checkedStates[2]}
        label="Disabled and checked checkbox"
        name="disabledChecked"
        onChange={handleChange(2)}
      />
      <ValmetCheckbox
        size="small"
        isChecked={checkedStates[3]}
        label="Small checkbox"
        name="small"
        onChange={handleChange(3)}
      />
      <ValmetCheckbox
        size="small"
        isDisabled
        isChecked={checkedStates[4]}
        label="Small disabled checkbox"
        name="smallDisabled"
        onChange={handleChange(4)}
      />
    </>
  );
};

const ComplexMultiSelect = () => {
  const [selectedOptions, setSelectedOptions] = useState([
    SelectOptions[0],
    SelectOptions[1],
  ]);

  return (
    <>
      <Typography variant="body1">
        ValmetComplexMultiSelect can be used to modify a list of related
        entities on an entity. For example it is used to modify roles assigned
        to an organization.
      </Typography>
      <StyledSpacing />
      <ValmetComplexMultiSelect
        options={SelectOptions}
        selectedOptions={selectedOptions}
        onChange={setSelectedOptions}
        selectId="complex-multi-select"
        selectPlaceholderTranslationKey="styleguide:inputs.complexMultiSelect.placeholder"
      />
    </>
  );
};
