import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ApiOrganizationRole,
  ApiServiceRole,
  LocalServiceRole,
} from '../../../types/api';

export const useOrganizationRoleOptions = () => {
  const {t} = useTranslation('users');
  return useMemo(() => {
    return [
      {
        text: t('users:organizationRoles.user'),
        value: 'User',
      },
      {
        text: t('users:organizationRoles.admin'),
        value: 'OrganizationAdmin',
      },
    ];
  }, [t]);
};

export const useServiceRoleOptions = () => {
  const {t} = useTranslation('users');
  return useMemo(() => {
    return [
      {
        text: t('users:serviceRoles.user'),
        value: 'User',
      },
      {
        text: t('users:serviceRoles.serviceAdmin'),
        value: 'ServiceAdmin',
      },
      {
        text: t('users:serviceRoles.deliveryProjectLeadEngineer'),
        value: 'DeliveryProjectLeadEngineer',
      },
    ];
  }, [t]);
};

export type OrganizationAndServiceRoleValue =
  | 'User'
  | 'OrganizationAdmin'
  | 'ServiceAdmin'
  | 'DeliveryProjectLeadEngineer';
export interface OrganizationAndServiceRoleOption {
  text: string;
  value: OrganizationAndServiceRoleValue;
}

/**
 * Use for getting the organizationRole value to send to back-end
 * for user create/update.
 */
export const getOrganizationRoleFromCombinedOption = (
  role: OrganizationAndServiceRoleValue,
): ApiOrganizationRole => {
  switch (role) {
    case 'User':
      return 'User';
    case 'OrganizationAdmin':
      return 'OrganizationAdmin';
    case 'ServiceAdmin':
      return 'OrganizationAdmin';
    case 'DeliveryProjectLeadEngineer':
      // DPLE is not an organization admin,
      // only administrates the assigned organizations
      return 'User';
  }
};
export const getServiceRoleFromCombinedOption = (
  role: OrganizationAndServiceRoleValue,
): ApiServiceRole | undefined => {
  switch (role) {
    case 'User':
      return undefined;
    case 'OrganizationAdmin':
      return undefined;
    case 'ServiceAdmin':
      return 'ServiceAdmin';
    case 'DeliveryProjectLeadEngineer':
      return 'DeliveryProjectLeadEngineer';
  }
};
export const getOrganizationAndServiceRoleValueFromRoles = (
  organizationRole: ApiOrganizationRole,
  serviceRole: ApiServiceRole | undefined,
): OrganizationAndServiceRoleValue => {
  switch (serviceRole) {
    case 'DeliveryProjectLeadEngineer':
      return 'DeliveryProjectLeadEngineer';
    case 'ServiceAdmin':
      return 'ServiceAdmin';
  }

  switch (organizationRole) {
    case 'OrganizationAdmin':
      return 'OrganizationAdmin';
    case 'User':
      return 'User';
  }
};

export const getTranslationKeyForCombinedRole = (
  role: OrganizationAndServiceRoleValue,
): string => {
  switch (role) {
    case 'User':
      return 'users:combinedRoles.user';
    case 'OrganizationAdmin':
      return 'users:combinedRoles.organizationAdmin';
    case 'ServiceAdmin':
      return 'users:combinedRoles.serviceAdmin';
    case 'DeliveryProjectLeadEngineer':
      return 'users:combinedRoles.deliveryProjectLeadEngineer';
  }
};

export const useOrganizationAndServiceRoleOptions = (
  showServiceRoles: boolean,
): OrganizationAndServiceRoleOption[] => {
  const {t} = useTranslation('users');
  const allOptions = useMemo<OrganizationAndServiceRoleOption[]>(() => {
    return [
      {
        text: t(getTranslationKeyForCombinedRole('User')),
        value: 'User',
      },
      {
        text: t(getTranslationKeyForCombinedRole('OrganizationAdmin')),
        value: 'OrganizationAdmin',
      },
      {
        text: t(getTranslationKeyForCombinedRole('ServiceAdmin')),
        value: 'ServiceAdmin',
      },
      {
        text: t(
          getTranslationKeyForCombinedRole('DeliveryProjectLeadEngineer'),
        ),
        value: 'DeliveryProjectLeadEngineer',
      },
    ];
  }, [t]);

  return showServiceRoles ? allOptions : allOptions.slice(0, 2);
};

export const mapServiceRole = (
  serviceRole: LocalServiceRole,
): ApiServiceRole | undefined => {
  if (serviceRole === 'User') {
    return undefined;
  }
  return serviceRole;
};
