/* eslint-disable security/detect-object-injection */
import {initializeUserActionCreator} from '../actions/user';
import {dequeueApiRequest, queueApiRequest} from '../actions/appState';
import {reducerWithInitialState} from 'typescript-fsa-reducers';
import {filter} from 'ramda';
import {ApiRequestDict} from '../../types/types';

export interface IAppState {
  isInitialized: boolean;
  apiRequestsPending: number;
  apiRequests: ApiRequestDict;
}

const initialState: IAppState = {
  isInitialized: false,
  apiRequestsPending: 0,
  apiRequests: {},
};

const queueApiRequestHandler = (state: IAppState, payload: string) => {
  const newState = {...state};
  const key = `'${payload}'`;
  newState.apiRequests[key] = (state.apiRequests[key] || 0) + 1;
  newState.apiRequestsPending = state.apiRequestsPending + 1;
  return newState;
};
const dequeueApiRequestHandler = (state: IAppState, payload: string) => {
  const newState = {...state};
  const key = `'${payload}'`;
  const newValue = (state.apiRequests[key] || 0) - 1;
  newState.apiRequests[key] = newValue;
  if (newValue <= 0) {
    newState.apiRequests[key] = undefined;
  }
  newState.apiRequestsPending = state.apiRequestsPending - 1;
  return newState;
};

const appStateReducer = reducerWithInitialState<IAppState>(initialState)
  .case(queueApiRequest, queueApiRequestHandler)
  .case(dequeueApiRequest, dequeueApiRequestHandler)
  .cases(
    [initializeUserActionCreator.done, initializeUserActionCreator.failed],
    state => ({
      ...state,
      isInitialized: true,
    }),
  );

export const isActionPending: (state: IAppState, type: string) => boolean = (
  state,
  type,
) => {
  const value = state.apiRequests[`'${type}'`];
  return value !== undefined && value > 0;
};

export const areActionsPending: (
  state: IAppState,
  types: string[],
) => boolean = (state, types) => {
  return filter(type => isActionPending(state, type), types).length > 0;
};

export const getIsAppInitialized: (state: IAppState) => boolean = state => {
  return state.isInitialized;
};

export default appStateReducer;
