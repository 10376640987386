const translations = {
  title: 'Roles',
  columns: {
    name: 'Role name',
    created: 'Created',
    description: 'Description',
    organizations: 'Organizations',
    users: 'No. of users',
    permissionGroups: 'Permission groups',
    permissions: 'Permissions',
  },
  cells: {
    allOrganizations: 'All',
    allPermissionGroups: 'All',
  },
  actions: {
    delete: 'Delete',
    edit: 'Edit',
  },
  buttons: {
    create: 'New role',
  },
  errors: {
    searchFailed: 'Loading roles failed',
  },
  list: {
    noResults: 'No data available.',
  },
  create: {
    title: 'Create role',
    success: 'Role {{roleName}} successfully created.',
    general: {
      header: 'General information',
      name: 'Name',
      description: 'Description',
    },
    permissionGroups: {
      header: 'Permission groups',
      addNewPlaceholder: '+ Add new group',
    },
    individualPermissions: {
      header: 'Individual permissions',
      addNewPlaceholder: '+ Add new permission',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      createFailed: 'Error in creating role.',
      lookupPermissionGroupsFailed:
        'Error in getting available permission groups.',
      lookupPermissionsFailed: 'Error in getting available permissions.',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      descriptionMaxLength: "Description can't be longer than 256 characters",
      conflict: 'Role with this name already exists',
    },
  },
  update: {
    title: 'Edit role:',
    success: 'Role {{roleName}} successfully saved.',
    general: {
      header: 'General information',
      name: 'Name',
      description: 'Description',
    },
    permissionGroups: {
      header: 'Permission groups',
      addNewPlaceholder: '+ Add new group',
    },
    individualPermissions: {
      header: 'Individual permissions',
      addNewPlaceholder: '+ Add new permission',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      updateFailed: 'Error in updating role.',
      getRoleFailed: 'Error in getting role.',
      lookupPermissionGroupsFailed:
        'Error in getting available permission groups.',
      lookupPermissionsFailed: 'Error in getting available permissions.',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      descriptionMaxLength: "Description can't be longer than 256 characters",
      conflict: 'Role with this name already exists',
      notFound: 'Given role or permission or permission group not found',
    },
  },
  delete: {
    header: 'Are you sure?',
    bodyFormat:
      'Role "{{roleName}}" will be deleted. This action cannot be undone.',
    error: 'Error in deleting role {{roleName}}.',
    success: 'Role {{roleName}} successfully deleted.',
  },
};

export default translations;
