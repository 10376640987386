import {Typography, makeStyles, Theme} from '@material-ui/core';
import React, {useMemo} from 'react';
import {
  StyledSpacing,
  PrimaryButton,
  errorNotification,
  infoNotification,
  successNotification,
} from '@valmet-iop/ui-common';

const useStyles = makeStyles<Theme, {}>(theme => ({
  buttonRoot: {
    marginRight: theme.spacing(1),
  },
}));

const StyleguideNotifications = () => {
  const classes = useStyles({});
  const buttons = useMemo(() => {
    return [
      {
        text: 'Show on list',
        onClick: () => undefined,
      },
      {
        text: 'Close',
        onClick: () => undefined,
      },
    ];
  }, []);
  const handleNotification = (
    notificationType: 'success' | 'error' | 'info' | 'successButtons',
  ) => () => {
    if (notificationType === 'success') {
      successNotification('Entry 101987 successfully saved.');
    } else if (notificationType === 'error') {
      errorNotification('Error in saving item 101987');
    } else if (notificationType === 'info') {
      infoNotification('Entry 101987 successfully saved.');
    } else if (notificationType === 'successButtons') {
      successNotification('Entry 101987 successfully saved.', buttons);
    }
  };

  return (
    <>
      <Typography variant="body1">
        Notifications can be shown (success, error, information) with the
        corresponding functions. The additional buttons on the bottom are
        optional. A third optional argument can also be specified for advanced
        options.
      </Typography>
      <StyledSpacing />
      <PrimaryButton
        text="Success"
        onClick={handleNotification('success')}
        classes={{root: classes.buttonRoot}}
      />
      <PrimaryButton
        text="Error"
        onClick={handleNotification('error')}
        classes={{root: classes.buttonRoot}}
      />
      <PrimaryButton
        text="Information"
        onClick={handleNotification('info')}
        classes={{root: classes.buttonRoot}}
      />
      <PrimaryButton
        text="Success with buttons"
        onClick={handleNotification('successButtons')}
        classes={{root: classes.buttonRoot}}
      />
      <StyledSpacing />
    </>
  );
};

export default StyleguideNotifications;
