import {Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {
  StyledSpacing,
  ConfirmDialog,
  FormCancelConfirmDialog,
  PrimaryButton,
  DialogBase,
} from '@valmet-iop/ui-common';
import {DialogButton} from '@valmet-iop/ui-common/dist/components/Dialogs/DialogBase';

const StyleguideDialogs = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmResult, setConfirmResult] = useState<boolean | null>(null);

  const [isFormCancelConfirmOpen, setIsFormCancelConfirmOpen] = useState(false);
  const [formCancelConfirmResult, setFormCancelConfirmResult] = useState<
    boolean | null
  >(null);

  const [isDialogBaseOpen, setIsDialogBaseOpen] = useState(false);
  const [dialogBaseResult, setDialogBaseResult] = useState<string | null>(null);

  const onOpenConfirmClick = () => {
    setIsConfirmOpen(true);
  };
  const onConfirmResult = (confirmed: boolean) => {
    setIsConfirmOpen(false);
    setConfirmResult(confirmed);
  };

  const onOpenFormCancelConfirmClick = () => {
    setIsFormCancelConfirmOpen(true);
  };
  const onFormCancelConfirmResult = (confirmed: boolean) => {
    setIsFormCancelConfirmOpen(false);
    setFormCancelConfirmResult(confirmed);
  };

  const onOpenDialogBaseClick = () => {
    setIsDialogBaseOpen(true);
  };
  const onDialogBaseClose = () => {
    setDialogBaseResult('close');
    setIsDialogBaseOpen(false);
  };

  const dialogBaseButtons: DialogButton[] = [
    {
      text: 'No',
      type: 'default',
      onClick: () => {
        setIsDialogBaseOpen(false);
        setDialogBaseResult('no');
      },
    },
    {
      text: 'Maybe',
      type: 'ghost',
      onClick: () => {
        setIsDialogBaseOpen(false);
        setDialogBaseResult('maybe');
      },
    },
    {
      text: 'Yes',
      type: 'ghost',
      onClick: () => {
        setIsDialogBaseOpen(false);
        setDialogBaseResult('yes');
      },
    },
  ];

  return (
    <>
      <Typography variant="body1">
        ConfirmDialog can be used to ask the user to confirm an action.
        FormCancelConfirmDialog has predefined text and can be used to confirm
        closing a form without saving the form data. More custom dialogs can be
        made by using DialogBase.
      </Typography>
      <StyledSpacing />
      <PrimaryButton text="Open confirm dialog" onClick={onOpenConfirmClick} />
      <Typography variant="body1">{`Result: ${confirmResult}`}</Typography>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        headerText="Are you sure?"
        bodyText="Some more text can go here. This part is optional."
        width="small"
        onResult={onConfirmResult}
      />
      <StyledSpacing />
      <PrimaryButton
        text="Open form cancel confirm dialog"
        onClick={onOpenFormCancelConfirmClick}
      />
      <Typography variant="body1">{`Result: ${formCancelConfirmResult}`}</Typography>
      <FormCancelConfirmDialog
        isOpen={isFormCancelConfirmOpen}
        onResult={onFormCancelConfirmResult}
      />
      <StyledSpacing />
      <PrimaryButton
        text="Open custom dialog"
        onClick={onOpenDialogBaseClick}
      />
      <Typography variant="body1">{`Result: ${dialogBaseResult}`}</Typography>
      <DialogBase
        isOpen={isDialogBaseOpen}
        headerText="Are you sure?"
        bodyText="Additional text here."
        width="medium"
        onClose={onDialogBaseClose}
        buttons={dialogBaseButtons}
      />
    </>
  );
};

export default StyleguideDialogs;
