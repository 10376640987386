const translations = {
  actions: {
    delete: 'Delete',
    edit: 'Edit',
  },
  buttons: {
    create: 'New organization',
  },
  columns: {
    name: 'Name',
    types: 'Types',
    admins: 'Admins',
    created: 'Created',
  },
  errors: {
    searchFailed: 'Loading organizations failed',
  },
  list: {
    noResults: 'No data available.',
  },
  title: 'Organizations',
  types: {
    AlternativeFuelsConsumer: 'Alternative Fuels Consumer',
    AlternativeFuelsProvider: 'Alternative Fuels Provider',
    LogisticsCompany: 'Logistics Company',
  },
  create: {
    title: 'Create organization',
    success: 'Organization {{organizationName}} successfully created.',
    administrators: {
      header: 'Administrators',
      addNewPlaceholder: '+ Add new administrator',
    },
    general: {
      header: 'General information',
      name: 'Name',
      types: 'Types',
    },
    roles: {
      header: 'Roles',
      addNewPlaceholder: '+ Add new role',
    },
    partnerOrganizations: {
      header: 'Partner organizations',
      addNewPlaceholder: '+ Add new partner organization',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      createFailed: 'Error in creating organization.',
      lookupTypesFailed: 'Error in getting available types.',
      lookupRolesFailed: 'Error in getting available roles.',
      lookupAdminsFailed: 'Error in getting available administrators.',
      lookupPartnerOrganizationsFailed:
        'Error in getting available partner organizations',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      typesRequired: 'At least one type is required',
      conflict: 'Organization with this name already exists',
    },
  },
  update: {
    title: 'Update organization',
    success: 'Organization {{organizationName}} successfully saved.',
    administrators: {
      header: 'Administrators',
      addNewPlaceholder: '+ Add new administrator',
    },
    general: {
      header: 'General information',
      name: 'Name',
      types: 'Types',
    },
    roles: {
      header: 'Roles',
      addNewPlaceholder: '+ Add new role',
    },
    partnerOrganizations: {
      header: 'Partner organizations',
      addNewPlaceholder: '+ Add new partner organization',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      getOrganizationFailed: 'Error in getting organization.',
      lookupTypesFailed: 'Error in getting available types.',
      lookupRolesFailed: 'Error in getting available roles.',
      lookupAdminsFailed: 'Error in getting available administrators.',
      lookupPartnerOrganizationsFailed:
        'Error in getting available partner organizations',
      updateFailed: 'Error in updating organization.',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      notFound: 'The organization was not found in the database',
      typesRequired: 'At least one type is required',
      conflict: 'Organization with this name already exists',
    },
  },
  delete: {
    header: 'Are you sure?',
    success: 'Organization {{organizationName}} successfully deleted.',
    bodyFormat:
      'Organization "{{organizationName}}" and all of its related data (including users) will be deleted. This action cannot be undone.',
    errorUnknown: 'Error in deleting organization {{organizationName}}.',
    errorForbidden:
      'Organization delete failed because of missing permissions.',
  },
  allowedInviteEmailDomains: {
    header: 'Allowed invite email domains',
    add: 'Add',
    domainHeader: 'Domain',
    errorRequired: 'Domain is required',
    errorInvalidDomain: 'Domain is not valid',
    errorNoDuplicates: 'Duplicate domains are not valid',
  },
};
export default translations;
