import React, {useState, useMemo} from 'react';
import {useTheme} from '@material-ui/core';
import {useErrorNotification, useSearchDebounce} from '../../Hooks/Hooks';
import {
  ApiRoleSortColumn,
  ApiPaginatedRoleQueryDto,
  ApiRoleSearchOutputDto,
  ApiRoleOutputDto,
  ApiPagedResponse,
} from '../../../types/api';
import {mapSortDirectionToOrderByType} from '../../../types/typeMapping';
import {
  TablePageLayout,
  Column,
  Sort,
  RowAction,
  ConfirmDialog,
  errorNotification,
  successNotification,
  TimestampInfo,
} from '@valmet-iop/ui-common';
import {Row} from '@valmet-iop/ui-common/dist/components/ValmetTable';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import CreateSidebar from './CreateSidebar';
import UpdateSidebar from './UpdateSidebar';
import {
  apiDeleteRequest,
  apiPostRequest,
} from '../../../services/api/apiService';
import {useQuery} from 'react-query';

const PageSizes = [10, 20, 30];

const Columns: readonly (Column & {sortColumn?: ApiRoleSortColumn})[] = [
  {
    translationKey: 'roles:columns.name',
    dataKey: 'displayName',
    isSortable: true,
    sortColumn: 'Name',
    defaultWidthPixels: 130,
  },
  {
    translationKey: 'roles:columns.description',
    dataKey: 'description',
    isSortable: true,
    sortColumn: 'Description',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'roles:columns.organizations',
    dataKey: 'organizations',
    dataType: 'stringArray',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'roles:columns.users',
    dataKey: 'users',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'roles:columns.permissionGroups',
    dataKey: 'permissionGroups',
    dataType: 'stringArray',
    defaultWidthPixels: 240,
  },
  {
    translationKey: 'roles:columns.permissions',
    dataKey: 'permissions',
    defaultWidthPixels: 180,
  },
  {
    translationKey: 'roles:columns.created',
    dataKey: 'creationInfo',
    dataType: 'timestampInfo',
    isSortable: true,
    sortColumn: 'CreatedAt',
    defaultWidthPixels: 180,
  },
];

type RowItem = Row & {
  id: string;
  displayName: string;
  description: string;
  organizations: string[];
  users: string;
  permissionGroups: string[];
  permissions: string;
  creationInfo: TimestampInfo;
};

const deleteRole = (roleId: string) => {
  return apiDeleteRequest(`api/roles/${roleId}`);
};

const List = () => {
  const theme = useTheme();
  const {t} = useTranslation(['translation', 'roles']);

  const initialSort: Sort = {
    columnIndex: 0,
    direction: 'ascending',
  };
  const [searchParams, setSearchParams] = useState<ApiPaginatedRoleQueryDto>({
    orderByType: 'Ascending',
    pageNumber: 1,
    pageSize: 10,
    searchWord: '',
    sortColumnName: 'Name',
  });

  const {data: result, isError, isLoading, refetch: executeSearch} = useQuery(
    ['roles', searchParams],
    ({queryKey}) =>
      apiPostRequest<
        ApiPaginatedRoleQueryDto,
        ApiPagedResponse<ApiRoleSearchOutputDto>
      >('api/roles/search', queryKey[1] as ApiPaginatedRoleQueryDto),
  );

  useErrorNotification(isError, 'roles:errors.searchFailed');

  const [searchQuery, setSearchQuery] = useState('');
  useSearchDebounce(searchQuery, query =>
    setSearchParams({...searchParams, searchWord: query, pageNumber: 1}),
  );

  const [isCreatePanelOpen, setCreatePanelOpen] = useState(false);
  const [isUpdatePanelOpen, setUpdatePanelOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [deleteState, setDeleteState] = useState({
    id: '',
    displayName: '',
    dialogVisible: false,
  });

  const data: readonly RowItem[] = useMemo(
    () =>
      result?.data.map((o: ApiRoleSearchOutputDto) => ({
        id: o.roleId,
        displayName: o.name,
        description: o.description,
        organizations: o.belongsToAllOrganizations
          ? [t('roles:cells.allOrganizations')]
          : o.organizations.map(org => org),
        users: o.users.length.toString(),
        permissionGroups: o.hasAllPermissionGroups
          ? [t('roles:cells.allPermissionGroups')]
          : o.permissionGroups.map(org => org),
        permissions: o.permissions,
        creationInfo: {
          timestamp: moment(o.createdAt),
          userName: o.createdBy || t('unknownUser'),
        },
      })) ?? [],
    [result, t],
  );

  const actions: RowAction<RowItem>[] = [
    {
      icon: 'edit',
      translationKey: 'roles:actions.edit',
      onClick: rowId => {
        setSelectedRoleId(rowId);
        setUpdatePanelOpen(true);
      },
    },
    {
      icon: 'delete',
      translationKey: 'roles:actions.delete',
      color: theme.palette.error.main,
      onClick: (_, row) => setDeleteState({...row, dialogVisible: true}),
    },
  ];

  const onFilterTextChange = (filterText: string) => {
    setSearchQuery(filterText);
  };

  const onPageChange = (page: number) => {
    setSearchParams({
      ...searchParams,
      pageNumber: page + 1,
    });
  };
  const onPageSizeChange = (pageSize: number) => {
    setSearchParams({
      ...searchParams,
      pageSize,
      pageNumber: 1,
    });
  };

  const onSortChange = ({columnIndex, direction}: Sort) => {
    // eslint-disable-next-line security/detect-object-injection
    const sortColumnName = Columns[columnIndex].sortColumn;
    if (sortColumnName === undefined) {
      return;
    }

    setSearchParams({
      ...searchParams,
      orderByType: mapSortDirectionToOrderByType(direction),
      pageNumber: 1,
      sortColumnName,
    });
  };

  const onDeleteConfirmResult = (confirmed: boolean) => {
    const executeDelete = async (roleId: string, roleName: string) => {
      try {
        await deleteRole(roleId);
        successNotification(t('roles:delete.success', {roleName}));
        executeSearch();
      } catch (e) {
        errorNotification(t('roles:delete.error', {roleName}));
      }
    };

    if (confirmed) {
      executeDelete(deleteState.id, deleteState.displayName);
    }

    setDeleteState({...deleteState, dialogVisible: false});
  };

  return (
    <>
      <TablePageLayout
        titleTranslationKey="roles:title"
        createButtonTextTranslationKey="roles:buttons.create"
        filter={{
          filterText: searchQuery,
          onFilterTextChange,
        }}
        pagination={{
          page: searchParams.pageNumber - 1,
          pageSize: searchParams.pageSize,
          pageSizes: PageSizes,
          totalResults: result?.resultCount ?? 0,
          onPageChange,
          onPageSizeChange,
        }}
        table={{
          columns: Columns,
          actions,
          data,
          initialSort,
          actionsMenuNoItemsTooltip: t(
            'table.tooltips.rowActionsNoPermissions',
          ),
          onSortChange,
          isLoading: isLoading,
          noResultsMessageTranslationKey: t('roles:list.noResults'),
        }}
        onCreateClick={() => setCreatePanelOpen(true)}
      />
      <CreateSidebar
        isOpen={isCreatePanelOpen}
        onClose={() => setCreatePanelOpen(false)}
        onCreate={(model: ApiRoleOutputDto) => {
          setCreatePanelOpen(false);
          successNotification(
            t('roles:create.success', {roleName: model.name}),
          );
          executeSearch();
        }}
      />
      <UpdateSidebar
        isOpen={isUpdatePanelOpen}
        roleId={selectedRoleId}
        onClose={() => setUpdatePanelOpen(false)}
        onUpdate={(result: ApiRoleOutputDto) => {
          setUpdatePanelOpen(false);
          successNotification(
            t('roles:update.success', {roleName: result.name}),
          );
          executeSearch();
        }}
      />
      <ConfirmDialog
        headerText={t('roles:delete.header')}
        isOpen={deleteState.dialogVisible}
        onResult={onDeleteConfirmResult}
        width="small"
        bodyText={t('roles:delete.bodyFormat', {
          roleName: deleteState.displayName,
        })}
      />
    </>
  );
};
export default List;
