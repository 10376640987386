import React, {useMemo, useState} from 'react';
import {
  FieldArray,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from 'formik';
import {useTranslation} from 'react-i18next';
import {
  ValmetSelect,
  TextButton,
  ValmetBasicTableCell,
  ValmetBasicTableCellWithRemoveButton,
  Filter,
  ValmetBasicTable,
} from '@valmet-iop/ui-common';
import ScopeValmetMultiSelect from './ScopeValmetMultiSelect';
import {Option} from '@valmet-iop/ui-common/dist/components/Inputs/ValmetSelect';
import {makeStyles, TableRow, Theme} from '@material-ui/core';
import {RoleWithScopes} from './FormDataTypes';
import {nanoid} from 'nanoid';

const useRoleScopeEditorStyles = makeStyles<Theme, {}>(theme => ({
  root: {},
  rows: {},
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export interface FormData {
  rolesWithScopes: readonly RoleWithScopes[];
}

const UserRoleEditor = (props: {
  userOrganizationId: string;
  roleOptions: Option[];
  multiSelectMenuContainerEl: Element | null;
  loadAllComplete: () => void;
}) => {
  const classes = useRoleScopeEditorStyles({});
  const {values, errors, touched, setFieldTouched} = useFormikContext<
    FormData
  >();
  const {t} = useTranslation(['translation', 'users']);
  const [filterText, setFilterText] = useState('');
  const roles = values.rolesWithScopes;
  const visibleRows = useMemo(() => {
    const rowsWithOriginalIndices = roles.map((r, i) => ({
      row: r,
      originalIndex: i,
    }));
    if (!filterText) {
      return rowsWithOriginalIndices;
    }

    const upperCaseFilter = filterText.toUpperCase();
    const caseInsensitiveContainsFilter = (x: string) => {
      return x.toUpperCase().includes(upperCaseFilter);
    };
    return rowsWithOriginalIndices.filter(
      r =>
        r.row.role === null ||
        caseInsensitiveContainsFilter(r.row.role.text) ||
        r.row.scopes.some(s => caseInsensitiveContainsFilter(s.text)),
    );
  }, [roles, filterText]);

  const roleErrors = errors.rolesWithScopes ?? [];
  const roleTouched = touched.rolesWithScopes ?? [];
  const unselectedRoleOptions = props.roleOptions.filter(
    o => !roles.some(r => r.role === o),
  );
  const userOrganizationId = props.userOrganizationId;

  const getRoleOptions = (currentData: RoleWithScopes) => {
    if (currentData.role === null) {
      return unselectedRoleOptions;
    }

    return unselectedRoleOptions.concat(currentData.role);
  };
  const onFilterTextChange = (text: string) => {
    setFilterText(text);
  };

  let loadingRoles = roles.map(o => o.role?.value);
  const handleRoleLoadComplete = (roleId: string) => {
    loadingRoles = loadingRoles.filter(r => r !== roleId);
    if (loadingRoles.length === 0) {
      props.loadAllComplete();
    }
  };

  return (
    <FieldArray name="rolesWithScopes">
      {({remove, replace, insert}) => (
        <>
          <Filter
            filterText={filterText}
            onFilterTextChange={onFilterTextChange}
          />
          <TextButton
            className={classes.addButton}
            text={t('users:update.roles.addButton')}
            icon="add"
            isDisabled={false}
            onClick={() => {
              insert(0, {role: null, scopes: [], immutableRowId: nanoid()});
            }}
          />
          <ValmetBasicTable
            columns={[
              {
                translationKey: 'users:update.roles.role',
                width: '40%',
              },
              {
                translationKey: 'users:update.roles.scopes',
                width: '60%',
              },
            ]}
          >
            {visibleRows.map(r => {
              const {row, originalIndex} = r;
              // eslint-disable-next-line security/detect-object-injection
              const rowErrors = roleErrors[originalIndex];
              // eslint-disable-next-line security/detect-object-injection
              const rowTouched = roleTouched[originalIndex];
              return (
                <EditorRow
                  key={row.immutableRowId}
                  index={originalIndex}
                  userOrganizationId={userOrganizationId}
                  roleOptions={getRoleOptions(row)}
                  remove={remove}
                  replace={replace}
                  value={row}
                  setRoleTouched={index =>
                    setFieldTouched(`rolesWithScopes.${index}.role`)
                  }
                  setScopesTouched={index =>
                    setFieldTouched(`rolesWithScopes.${index}.scopes`)
                  }
                  errors={rowErrors}
                  touched={rowTouched}
                  multiSelectMenuContainerEl={props.multiSelectMenuContainerEl}
                  loadComplete={handleRoleLoadComplete}
                />
              );
            })}
          </ValmetBasicTable>
        </>
      )}
    </FieldArray>
  );
};

export default UserRoleEditor;

interface EditorProps {
  errors: FormikErrors<RoleWithScopes> | undefined;
  index: number;
  multiSelectMenuContainerEl: Element | null;
  userOrganizationId: string | undefined;
  roleOptions: Option[];
  touched: FormikTouched<RoleWithScopes> | undefined;
  value: RoleWithScopes;
  remove: (index: number) => void;
  replace: (index: number, value: RoleWithScopes) => void;
  setRoleTouched: (index: number) => void;
  setScopesTouched: (index: number) => void;
  loadComplete: (roleId: string) => void;
}

const EditorRow = ({
  errors,
  index,
  multiSelectMenuContainerEl,
  userOrganizationId,
  roleOptions,
  touched,
  value,
  remove,
  replace,
  setRoleTouched,
  setScopesTouched,
  loadComplete,
}: EditorProps) => {
  const handleRoleChange = (option: Option | null) => {
    replace(index, {...value, role: option, scopes: []});
    setRoleTouched(index);
  };
  const handleRoleScopesChange = (options: Option[]) => {
    replace(index, {...value, scopes: options});
    setScopesTouched(index);
  };
  const handleRemoveClick = () => {
    remove(index);
  };

  // TS thinks the error type will be an array (error per scope)
  // but this is not the case, it is a string
  const scopesError = (errors?.scopes as unknown) as string | undefined;

  return (
    <TableRow>
      <ValmetBasicTableCell>
        <ValmetSelect
          id={`role-${index}`}
          onChange={handleRoleChange}
          options={roleOptions}
          placeholderTranslationKey="users:update.roles.selectRole"
          errorMessageTranslationKey={touched?.role ? errors?.role : undefined}
          value={value.role}
          width="full"
        />
      </ValmetBasicTableCell>
      <ValmetBasicTableCellWithRemoveButton onRemoveClick={handleRemoveClick}>
        <ScopeValmetMultiSelect
          index={index}
          errorMessageTranslationKey={touched?.scopes ? scopesError : undefined}
          userOrganizationId={userOrganizationId}
          roleId={value.role?.value}
          onChange={handleRoleScopesChange}
          onLoadComplete={loadComplete}
          scopes={value.scopes}
          multiSelectMenuContainerEl={multiSelectMenuContainerEl}
        />
      </ValmetBasicTableCellWithRemoveButton>
    </TableRow>
  );
};
