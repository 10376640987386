import React, {useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {AppContext} from '../Layout';

const Home = () => {
  // We can also render a loading screen here while we wait to find that out
  const appContext = useContext(AppContext);

  if (appContext.user === null) {
    return null;
  }

  return (
    <>
      {appContext.user.serviceRole === 'ServiceAdmin' && (
        <Redirect to="/organizations" />
      )}
      {appContext.user.serviceRole === 'DeliveryProjectLeadEngineer' && (
        <Redirect to="/organizations" />
      )}
      {appContext.user.serviceRole !== 'ServiceAdmin' && (
        <Redirect to="/users" />
      )}
    </>
  );
};

export default Home;
