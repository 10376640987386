import {Redirect, Route} from 'react-router-dom';
import {
  getIsAppInitialized,
  getUserIsAuthenticated,
  getUserIsLoggingOut,
} from '../../redux/reducers';
import React, {FC, useContext} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {IState} from '../../types/types';
import {AppContext} from '../Layout';
import {LoadingScreen} from '@valmet-iop/ui-common';
import UserNotFound from '../Pages/UserNotFound';

/*
 * Used inside <Router>
 */
const PrivateRoute: FC<{
  comp: FC;
  path: string;
  exact?: boolean;
}> = ({comp: Comp, path, exact, ...rest}) => {
  const isAuthenticated = useSelector<IState, boolean>(
    getUserIsAuthenticated,
    shallowEqual,
  );
  const userIsLoggingOut = useSelector<IState, boolean>(
    getUserIsLoggingOut,
    shallowEqual,
  );
  const applicationReady = useSelector<IState, boolean>(
    getIsAppInitialized,
    shallowEqual,
  );
  const {userInfoLoadStatus} = useContext(AppContext);
  if (!applicationReady || userIsLoggingOut) {
    return (
      <Route path={path} exact={exact}>
        <LoadingScreen />
      </Route>
    );
  }

  if (!isAuthenticated) {
    // This component re-renders after the redirect to login once still
    // At that point the pathname is "/login",
    // which causes us to replace the URL in session storage
    // where the user wanted to get to.
    // Ref bug #935
    if (!window.location.pathname.startsWith('/login')) {
      window.sessionStorage.setItem('local-url', window.location.pathname);
    }

    return <Redirect to="/login" />;
  }

  if (
    userInfoLoadStatus === 'NOT_STARTED' ||
    userInfoLoadStatus === 'LOADING'
  ) {
    return (
      <Route path={path} exact={exact}>
        <LoadingScreen />
      </Route>
    );
  }

  if (userInfoLoadStatus === 'LOAD_FAILED') {
    return (
      <Route path={path} exact={exact}>
        <UserNotFound />
      </Route>
    );
  }

  return (
    <Route path={path} exact={exact}>
      <Comp {...rest} />
    </Route>
  );
};

export default PrivateRoute;
