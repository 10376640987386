import React, {useEffect, useState} from 'react';
import {
  ApiProblemDetails,
  ApiPermissionBasicOutputDto,
  ApiPermissionGroupOutputDto,
  ApiUpdatePermissionGroupInputDto,
} from '../../../types/api';
import {
  ContextPanel,
  ContextPanelContentSection,
  FormikValmetTextInput,
  FormCancelConfirmDialog,
  FormikValmetComplexMultiSelect,
  errorNotification,
  LoadingScreen,
} from '@valmet-iop/ui-common';
import {Formik} from 'formik';
import {apiGetRequest, apiPutRequest} from '../../../services/api/apiService';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import deepEqual from 'deep-equal';

const updatePermissionGroup = async (
  permissionGroupId: string,
  model: ApiUpdatePermissionGroupInputDto,
) => {
  const result = await apiPutRequest<
    ApiUpdatePermissionGroupInputDto,
    ApiPermissionGroupOutputDto
  >(`api/permissionGroups/${permissionGroupId}`, model);
  return result;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('permissionGroups:update.errors.nameRequired')
    .max(128, 'permissionGroups:update.errors.nameMaxLength'),
  description: Yup.string().max(
    256,
    'permissionGroups:update.errors.descriptionMaxLength',
  ),
});

interface FormData {
  name: string;
  description: string;
  selectedPermissions: {
    text: string;
    value: string;
  }[];
}

const emptyOptions: {
  text: string;
  value: string;
}[] = [];

const getRemovedItems = (initialItems: string[], updatedItems: string[]) =>
  initialItems.filter(x => !updatedItems.includes(x));

const getAddedItems = (initialItems: string[], updatedItems: string[]) =>
  updatedItems.filter(x => !initialItems.includes(x));

const createUpdatePayload = (initialData: FormData, updatedData: FormData) => {
  const removedPermissionIds = getRemovedItems(
    initialData.selectedPermissions.map(x => x.value),
    updatedData.selectedPermissions.map(x => x.value),
  );
  const addedPermissionIds = getAddedItems(
    initialData.selectedPermissions.map(x => x.value),
    updatedData.selectedPermissions.map(x => x.value),
  );
  return {
    name: updatedData.name,
    description: updatedData.description,
    addPermissions: addedPermissionIds,
    removePermissions: removedPermissionIds,
  };
};

const UpdateSidebar = (props: {
  isOpen: boolean;
  permissionGroupId: string;
  onClose: () => void;
  onUpdate: (result: ApiPermissionGroupOutputDto) => void;
}) => {
  const {t} = useTranslation(['translation', 'permissionGroups']);
  const [isCancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
    false,
  );

  const [initialValues, setInitialValues] = useState<FormData>({
    name: '',
    description: '',
    selectedPermissions: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const {permissionGroupId: selectedPermissionGroupId, isOpen} = props;
  useEffect(() => {
    if (isOpen && !!selectedPermissionGroupId) {
      const getSelectedPermissionGroup = async (permissionGroupId: string) => {
        try {
          const result = await apiGetRequest<ApiPermissionGroupOutputDto>(
            `api/permissionGroups/${permissionGroupId}`,
          );
          setInitialValues({
            name: result.groupName,
            description: result.description,
            selectedPermissions: result.permissions.map(dto => ({
              text: dto.permissionDisplayName,
              value: dto.permissionId,
            })),
          });
        } catch {
          errorNotification(
            t('permissionGroups:update.errors.getPermissionGroupFailed'),
          );
        } finally {
          setIsLoading(false);
        }
      };
      clearValues();
      getSelectedPermissionGroup(selectedPermissionGroupId);
    }
  }, [selectedPermissionGroupId, isOpen, t]);

  const [permissionOptions, setPermissionOptions] = useState(emptyOptions);

  const clearValues = () => {
    setInitialValues({
      name: '',
      description: '',
      selectedPermissions: [],
    });
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const getPermissions = async () => {
        try {
          const result = await apiGetRequest<ApiPermissionBasicOutputDto[]>(
            'api/permissions',
          );
          setPermissionOptions(
            result.map(dto => ({
              text: dto.displayName,
              value: dto.permissionId,
            })),
          );
        } catch {
          errorNotification(
            t('permissionGroups:update.errors.lookupPermissionsFailed'),
          );
        }
      };

      getPermissions();
    }
  }, [isOpen, t]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, {setSubmitting, setFieldError, resetForm}) => {
        setSubmitting(true);
        try {
          const payload = createUpdatePayload(initialValues, values);
          const result = await updatePermissionGroup(
            selectedPermissionGroupId,
            {
              groupName: payload.name,
              description: payload.description,
              addPermissions: payload.addPermissions,
              removePermissions: payload.removePermissions,
            },
          );
          props.onUpdate(result);
          resetForm();
        } catch (e) {
          errorNotification(t('permissionGroups:update.errors.updateFailed'));
          if ((e as ApiProblemDetails)?.status === 404) {
            setFieldError('name', 'permissionGroups:update.errors.notFound');
          } else if ((e as ApiProblemDetails)?.status === 409) {
            setFieldError('name', 'permissionGroups:update.errors.conflict');
          }
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={validationSchema}
    >
      {({isSubmitting, values, errors, dirty, handleSubmit, resetForm}) => {
        const onCloseClick = () => {
          if (dirty && !isSubmitting) {
            setCancelConfirmDialogOpen(true);
            return;
          }

          resetForm();
          props.onClose();
        };
        const onCancelConfirmDialogResult = (confirmed: boolean) => {
          setCancelConfirmDialogOpen(false);
          if (confirmed) {
            resetForm();
            props.onClose();
          }
        };
        return (
          <>
            <ContextPanel
              open={props.isOpen}
              size="small"
              titleTranslationKey="permissionGroups:update.title"
              titleSuffix={initialValues.name ?? ''}
              bottomActions={[
                {
                  translationKey: 'permissionGroups:update.buttons.save',
                  style: 'primary',
                  isDisabled:
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    deepEqual(values, initialValues),
                  onClick: handleSubmit,
                },
                {
                  translationKey: 'permissionGroups:update.buttons.cancel',
                  style: 'ghost',
                  isDisabled: isSubmitting,
                  onClick: onCloseClick,
                },
              ]}
              onCloseClick={onCloseClick}
            >
              {isSubmitting || isLoading ? (
                <LoadingScreen />
              ) : (
                <>
                  <ContextPanelContentSection headerTranslationKey="permissionGroups:update.general.header">
                    <FormikValmetTextInput
                      formProperty="name"
                      id="name"
                      labelTranslationKey="permissionGroups:update.general.name"
                      isRequired
                    />
                    <FormikValmetTextInput
                      formProperty="description"
                      id="description"
                      labelTranslationKey="permissionGroups:update.general.description"
                    />
                  </ContextPanelContentSection>
                  <ContextPanelContentSection headerTranslationKey="permissionGroups:update.permissions.header">
                    <FormikValmetComplexMultiSelect
                      formProperty="selectedPermissions"
                      options={permissionOptions}
                      selectPlaceholderTranslationKey="permissionGroups:update.permissions.addNewPlaceholder"
                    />
                  </ContextPanelContentSection>
                </>
              )}
            </ContextPanel>
            <FormCancelConfirmDialog
              isOpen={isCancelConfirmDialogOpen}
              onResult={onCancelConfirmDialogResult}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default UpdateSidebar;
