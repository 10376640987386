import React, {useContext} from 'react';
import {FormikValmetRadioButtons} from '@valmet-iop/ui-common';
import {AppContext} from '../../Layout';
import {useOrganizationAndServiceRoleOptions} from './utils';

interface Props {
  formProperty: string;
  labelTranslationKey: string;
  isUserInAdminOrganization: boolean;
  isDisabled?: boolean;
}

const RoleRadioButtons = (props: Props) => {
  const appContext = useContext(AppContext);
  const userIsServiceAdmin = appContext.user?.serviceRole === 'ServiceAdmin';
  const options = useOrganizationAndServiceRoleOptions(
    userIsServiceAdmin && props.isUserInAdminOrganization,
  );

  return (
    <FormikValmetRadioButtons
      formProperty={props.formProperty}
      labelTranslationKey={props.labelTranslationKey}
      options={options}
      isDisabled={props.isDisabled}
    />
  );
};

export default RoleRadioButtons;
