const translations = {
  organizationRoles: {
    user: 'User',
    admin: 'Admin',
  },
  serviceRoles: {
    user: 'User',
    serviceAdmin: 'Valmet admin',
    deliveryProjectLeadEngineer: 'Delivery project lead engineer',
  },
  combinedRoles: {
    user: 'User',
    organizationAdmin: 'Organization Admin',
    serviceAdmin: 'Valmet admin',
    deliveryProjectLeadEngineer: 'Delivery project lead engineer',
  },
  actions: {
    delete: 'Delete',
    edit: 'Edit',
  },
  buttons: {
    create: 'Invite user',
  },
  columns: {
    organizationName: 'Organization',
    name: 'Name',
    email: 'Email',
    type: 'Type',
    roles: 'Roles',
    created: 'Created',
  },
  errors: {
    searchFailed: 'Loading users failed',
  },
  list: {
    noResults: 'No data available.',
  },
  redirectToApplication: {
    azureIop: 'Azure IOP',
    awsIop: 'AWS IOP',
  },
  create: {
    title: 'Invite user',
    success: 'User {{displayName}} successfully invited.',
    general: {
      email: 'Email',
      header: 'General information',
      invitationMessage: 'Invitation message',
      name: 'Name',
      selectedOrganization: 'Organization',
      role: 'Type',
      redirectToApplication: 'Redirect to application',
    },
    rolesAndPermissions: {
      header: 'Roles and permissions',
    },
    buttons: {
      save: 'Invite',
      cancel: 'Cancel',
    },
    errors: {
      createFailed: 'Error in inviting user.',
      userWithEmailAlreadyExists:
        'User with given email address already exists.',
      emailSendFailed: 'Failed to send invitation email.',
      lookupRolesFailed: 'Error in getting available roles.',
      lookupScopesFailed: 'Error in getting available scopes.',
      conflict: 'This user has already been invited',
      emailRequired: 'Email is required',
      emailMaxLength: "Email can't be longer than 128 characters",
      invalidEmail: 'Email must be a valid email address',
      notAllowedDomain:
        'Email address domain not allowed for this organization',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      organizationRequired: 'Organization is required',
      invitationMessageMaxLength:
        "Message can't be longer than 1000 characters",
      roleRequired: 'Select role',
    },
  },
  delete: {
    header: 'Are you sure?',
    bodyFormat:
      'User "{{displayName}}" will be deleted. This action cannot be undone.',
    error: 'Error in deleting user {{displayName}}',
    success: 'User {{displayName}} successfully deleted.',
  },
  title: 'Users',
  types: {
    OrganizationAdmin: 'Admin',
    User: 'User',
  },
  update: {
    title: 'Edit user:',
    success: 'User {{displayName}} successfully saved.',
    general: {
      header: 'General information',
      name: 'Name',
      role: 'Type',
    },
    roles: {
      header: 'Roles and permissions',
      addButton: 'Add new role',
      role: 'Role',
      scopes: 'Scopes',
      selectRole: 'Select role',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    errors: {
      getUserFailed: 'Error in getting user.',
      lookupRolesFailed: 'Error in getting available roles.',
      lookupUserScopesFailed: 'User scopes could not be loaded',
      updateFailed: 'Error in updating user.',
      nameRequired: 'Name is required',
      nameMaxLength: "Name can't be longer than 128 characters",
      roleRequired: 'Select role',
    },
  },
};

export default translations;
