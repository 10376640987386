import {useEffect, useRef} from 'react';
import {errorNotification} from '@valmet-iop/ui-common';
import {useTranslation} from 'react-i18next';

type Callback = () => void;
export function useInterval(callback: Callback, delay: number) {
  const savedCallback = useRef<Callback | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useSearchDebounce(
  query: string,
  setSearchQuery: (query: string) => void,
  delay = 500,
) {
  useEffect(() => {
    const handle = setTimeout(() => {
      setSearchQuery(query);
    }, delay);

    return () => {
      clearTimeout(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, delay]);
}

/**
 * Shows an error notification when isError changes to true.
 */
export function useErrorNotification(
  isError: boolean,
  errorTranslationKey: string,
) {
  const {t} = useTranslation();
  useEffect(() => {
    if (isError) {
      errorNotification(t(errorTranslationKey));
    }
  }, [isError, errorTranslationKey, t]);
}
