import {Card, CardContent, Divider, Typography} from '@material-ui/core';
import React from 'react';
import StyleguideButtons from './StyleguideButtons';
import {StyledSpacingLarge, StyledSpacing} from '@valmet-iop/ui-common';
import StyleguideTypography from './StyleguideTypography';
import StyleguideIcons from './StyleguideIcons';
import StyleguideTables from './StyleguideTables';
import StyleguideContextPanel from './StyleguideContextPanel';
import StyleguideInputs from './StyleguideInputs';
import StyleguidePagination from './StyleguidePagination';
import StyleguideFilter from './StyleguideFilter';
import StyleguideTooltips from './StyleguideTooltips';
import StyleguideDialogs from './StyleguideDialogs';
import StyleguideChips from './StyleguideChips';
import StyleguideNotifications from './StyleguideNotifications';
import {Link} from 'react-router-dom';

const StyleGuide = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          Buttons
        </Typography>
        <Divider light />
        <StyleguideButtons />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Typography
        </Typography>
        <Divider light />
        <StyleguideTypography />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Icons
        </Typography>
        <Divider light />
        <StyleguideIcons />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Tables
        </Typography>
        <Divider light />
        <StyleguideTables />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Filter
        </Typography>
        <Divider light />
        <StyleguideFilter />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Context Panel
        </Typography>
        <Divider light />
        <StyleguideContextPanel />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Inputs
        </Typography>
        <Divider light />
        <StyleguideInputs />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Pagination
        </Typography>
        <Divider light />
        <StyleguidePagination />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Table page layout
        </Typography>
        <Divider light />
        <Typography variant="body1">
          TablePageLayout combines a filter, table and pagination to allow easy
          creation of pages containing a table.
        </Typography>
        <StyledSpacing />
        <Link to="/styleguide/tablelayout">Go to example page</Link>
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Tooltips
        </Typography>
        <Divider light />
        <StyleguideTooltips />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Chips
        </Typography>
        <Divider light />
        <StyleguideChips />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Dialogs
        </Typography>
        <Divider light />
        <StyleguideDialogs />
        <StyledSpacingLarge />

        <Typography variant="h2" gutterBottom>
          Notifications
        </Typography>
        <Divider light />
        <StyleguideNotifications />
        <StyledSpacingLarge />
      </CardContent>
    </Card>
  );
};

export default StyleGuide;
