import {
  StyledSpacing,
  PrimaryButton,
  GhostButton,
  DefaultButton,
  ActionButton,
  IconButton,
  TextButton,
} from '@valmet-iop/ui-common';
import {Typography} from '@material-ui/core';
import React from 'react';

interface IComponentOwnProps {}

type ComponentProps = IComponentOwnProps;

const StyleguideButtons = () => {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Primary
      </Typography>
      <PrimaryButton text="Button" onClick={() => undefined} />{' '}
      <ActionButton
        type="primary"
        text="Disabled button"
        isDisabled
        onClick={() => undefined}
      />{' '}
      <PrimaryButton text="Large" size="large" onClick={() => undefined} />{' '}
      <PrimaryButton text="Small" size="small" onClick={() => undefined} />{' '}
      <PrimaryButton text="With icon" onClick={() => undefined} icon="add" />
      <StyledSpacing />
      <Typography variant="h3" gutterBottom>
        Ghost
      </Typography>
      <GhostButton text="Button" onClick={() => undefined} />{' '}
      <GhostButton
        text="Disabled button"
        isDisabled
        onClick={() => undefined}
      />{' '}
      <GhostButton text="Large" size="large" onClick={() => undefined} />{' '}
      <GhostButton text="Small" size="small" onClick={() => undefined} />{' '}
      <GhostButton text="With icon" onClick={() => undefined} icon="add" />
      <StyledSpacing />
      <Typography variant="h3" gutterBottom>
        Default
      </Typography>
      <DefaultButton text="Button" onClick={() => undefined} />{' '}
      <DefaultButton
        text="Disabled button"
        isDisabled
        onClick={() => undefined}
      />{' '}
      <DefaultButton text="Large" size="large" onClick={() => undefined} />{' '}
      <DefaultButton text="Small" size="small" onClick={() => undefined} />{' '}
      <DefaultButton text="With icon" onClick={() => undefined} icon="add" />
      <StyledSpacing />
      <Typography variant="h3" gutterBottom>
        Icon
      </Typography>
      <IconButton icon="users" onClick={() => undefined} />{' '}
      <IconButton icon="users" disabled onClick={() => undefined} />
      <StyledSpacing />
      <Typography variant="h3" gutterBottom>
        Text
      </Typography>
      <TextButton text="Button" onClick={() => undefined} />{' '}
      <TextButton text="Disabled button" isDisabled onClick={() => undefined} />{' '}
      <TextButton
        text="Disabled button"
        isDisabled
        icon="add-circle"
        onClick={() => undefined}
      />{' '}
      <TextButton text="Button" icon="add-circle" onClick={() => undefined} />
    </>
  );
};

export default StyleguideButtons;
